import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { BsPencil } from 'react-icons/bs'

import Nav from '../../../components/Nav'
import ActionButton from '../../../components/ActionButton';
import FieldWorkView from '../../../components/FieldWorkView';

function FieldWorkList(props) {
    const pencilIcon = <BsPencil size="14"/>
    const [allFieldWorks, setAllFieldWorks] = useState({v:[]})
    const [fieldWorks, setFieldWorks] = useState({status: 0, v:[], error:null})
    const [workKinds, setWorkKinds] = useState({status: 0, v:[], error:null})
    const [selectedFw, setSelectedFw] = useState(null)
    const [filterWk, setFilterWk] = useState("any")

    useEffect(() => {
        const onListResponse = (data, error) => {
            if (error) {
                setFieldWorks({status: 1, v:[], error: error})
            } else {
                setAllFieldWorks({v:data.field_works ? data.field_works : []})
                setFieldWorks({status: 1, v:data.field_works ? data.field_works : [], error: null})
            }
        }
        const onAttributeListResponse = (data, error) => {
            if (!error) {
                setWorkKinds({status: 1, v:data.work_kinds ? data.work_kinds : [], error:null})
            }
        }
        props.backend.GetAttributes(onAttributeListResponse, {attributes: ["work_kinds"]})
        props.backend.FieldWorkList(onListResponse)
    }, [props.backend])
    
    const printDateTime = (d) => {
        const nd = new Date(d)
        return nd.toLocaleDateString(props.T("LOCALE"), { hour: '2-digit', minute: '2-digit', hour12: false, month: 'short', day: 'numeric' })
    }

    const showFw = (fw) => {
        setSelectedFw(fw)
    }

    const clearSelectedFw = () => {
        setSelectedFw(null)
    }

    const printRowRange = (minrow, maxrow) => {
        if (minrow === maxrow) return "("+minrow+")"
        return "("+minrow + " - " + maxrow+")"
    }

    const filterChange = (ev) => {
        ev.preventDefault()        
        const filterID = [].filter.call(ev.target.options, opt => opt.selected)[0].value
        setFilterWk(filterID)
        if (filterID === "any") {
            setFieldWorks({status: 1, v:allFieldWorks.v, error:null})
        } else {
            setFieldWorks({status:1, v:allFieldWorks.v.filter(fw => fw.work_type.id === filterID), error:null})
        }
    }

    return (
        <>
        <Nav isMain={false} withLink="/activities" subtitle="Activity" {...props} />
        <FieldWorkView {...props} onClose={clearSelectedFw} fw={selectedFw} />
        
        {(fieldWorks.status === 0 || workKinds.status === 0) && <div className="loadingOverlay"></div>}
        {!selectedFw && fieldWorks.status === 1 && workKinds.status === 1 && 
            <>
            <div className="filterMenu">
                <select className="filterSelect" name="filter" defaultValue={filterWk} onChange={filterChange}>
                    <option value="any" id="any">{props.T("FilterAllWorkTypes")}</option>
                    {workKinds.v.map(wk => {return (
                        <option key={wk.id} value={wk.id}>{props.T("wk_"+wk.name)}</option>
                    )})}
                </select>
                <Link to="/activities/fieldwork/new">
                    <ActionButton T={props.T} title="New" icon={pencilIcon} />
                </Link>
            </div>
            <div className="content">
                <p className="contentSubtitle">{props.T("FieldWorkHistory")}</p><br />
                <div className="hscrollcontainer sticky">
                <table id="fieldworkList">
                    <thead>
                    <tr>
                        <th align="center" >{props.T("EnteredBy")}</th>
                        <th align="center" >{props.T("FieldWorkType")}</th>
                        <th align="center" >{props.T("NumFields")}</th>
                        <th align="center" >{props.T("NumPlants")}</th>
                        <th align="center" >{props.T("NumWorkers")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {fieldWorks.v.length === 0 &&
                        <tr><td colSpan="5">{props.T("NoRecords")}</td></tr>
                    }
                    {fieldWorks.v.length > 0 &&
                        fieldWorks.v.filter(fw => fw.rows && fw.rows.length > 0 && fw.rows[0].start_row)
                            .map(fw => {
                            let minRow = fw.rows[0].start_row.id
                            let maxRow = minRow
                            const no = {numeric:true}
                            fw.rows.forEach(r => {
                                if (r.start_row.id.localeCompare(minRow, undefined, no) < 0) {
                                    minRow = r.start_row.id
                                }
                                if (r.start_row.id.localeCompare(maxRow, undefined, no) > 0) {
                                    maxRow = r.start_row.id
                                }
                                if (!r.is_partial) {
                                    if (r.end_row.id.localeCompare(minRow, undefined, no) < 0) {
                                        minRow = r.end_row.id
                                    } else if (r.end_row.id.localeCompare(maxRow, undefined, no) > 0) {
                                        maxRow = r.end_row.id
                                    }
                                }
                            })
                            
                            return (
                            <tr key={fw.id} onClick={() => showFw(fw)}>
                            <td align="center" width="100px">{fw.user.first_name}<br />{printDateTime(fw.created_at)}</td>
                            <td align="left" width="130px">{props.T("wk_"+fw.work_type.name)} {printRowRange(minRow, maxRow)}</td>
                            <td align="center" width="80px">{fw.num_fields}</td>
                            <td align="center" width="80px">{fw.num_plants}</td>
                            <td align="center" width="80px">{fw.num_workers}</td>
                            </tr>
                        )})
                    }
                    </tbody>
                </table>
                </div>
            </div>
            </>
            }
        </>
    )
}

export default FieldWorkList
