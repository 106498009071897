import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { BsPencil } from 'react-icons/bs'

import Nav from '../../../components/Nav'
import ActionButton from '../../../components/ActionButton';

function TankRefillList(props) {
    const pencilIcon = <BsPencil size="14"/>
    const [refills, setRefills] = useState({status: 0, v:[], error:null})

    useEffect(() => {
        const onListResponse = (data, error) => {
            if (error) {
                setRefills({status: 1, v:[], error: error})
            } else {
                setRefills({status: 1, v:data.tank_refills ? data.tank_refills : [], error: null})
            }
        }
        props.backend.TankRefillList(onListResponse)
    }, [props.backend])
    
    const printDateTime = (d) => {
        const nd = new Date(d)
        return nd.toLocaleDateString(props.T("LOCALE"), { hour: '2-digit', minute: '2-digit', hour12: false, month: 'short', day: 'numeric' })
    }
    return (
        <>
        <Nav isMain={false} withLink="/activities" subtitle="Activity" {...props} />
        <div className="filterMenu">
            <Link to="/activities/tankrefill/new">
                <ActionButton T={props.T} title="New" icon={pencilIcon} />
            </Link>
        </div>
        {refills.status === 0 && <div className="loadingOverlay"></div>}
        {refills.status === 1 && 
            <div className="content">
                <p className="contentSubtitle">{props.T("TankRefillHistory")}</p><br />
                    <div className="hscrollcontainer sticky">
                    {refills.v.map(tr => {return(
                        <div key={tr.id} id={tr.id}>
                            <p>{props.T("RefilledAt")} <b>{printDateTime(tr.created_at)}</b>, {props.T("by")}{tr.user.first_name} {tr.user.last_name}</p>
                            <p>{props.T("DosingTank")}: {tr.dosing_channel.name}</p>
                            <p>{props.T("Formula")}: {tr.formula.name}</p>
                            <p>{props.T("Note")}: {tr.note}</p>
                            <table key={"refillList"+tr.id} id={"refillList"+tr.id}>
                                <thead>
                                <tr>
                                    <th align="center" >{props.T("Product")}</th>
                                    <th align="center" >{props.T("Quantity")}</th>
                                </tr> 
                                </thead>
                                <tbody>
                                {tr.inventory_cl.map(cl =>{return (
                                    <tr key={cl.id}>
                                        <td align="left">{cl.inventory.product.name}</td>
                                        <td align="center" width="80px">{cl.quantity_change} {cl.inventory.product.package_type.unit}</td>
                                    </tr>
                                )})}
                                </tbody>
                            </table>
                            <br /><br />
                        </div>
                    )})}
                    </div>
            </div>
            }
        </>
    )
}

export default TankRefillList
