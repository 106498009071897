import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { BsPencil } from 'react-icons/bs'

import Nav from '../../../components/Nav'
import IrrigationMenu from '../../../components/irrigation/IrrigationMenu';
import ProgramsFilterMenu from '../../../components/irrigation/ProgramsFilterMenu'
import ActionButton from '../../../components/ActionButton';
import CollapsableProgramTitle from '../../../components/irrigation/CollapsableProgramTitle'

const IrrigationProgramList = (props) => {
    const pencilIcon = <BsPencil size="14"/>
    const [programs, setPrograms] = useState({status: 0, v:[], error:null})

    useEffect(() => {
        const onListResponse = (data, error) => {
            if (error) {
                setPrograms({status:1, v:[], error:error})
            } else {
                setPrograms({status:1, v:data.irrigation_programs ? data.irrigation_programs : [], error:null})
            }
        }
        props.backend.IrrigationProgramList(onListResponse)
    }, [props.backend])

    return (
        <>
        <Nav isMain={false} subtitle="Irrigation" {...props} />
        <IrrigationMenu {...props} section="programs" />
        <div className="filterMenu">
            <ProgramsFilterMenu {...props} filter="irrigation" />
            {props.user.integration_name !== "netafim_cloud" &&
                <Link to="/irrigation/programs/irrigation/new">
                    <ActionButton T={props.T} title="New" icon={pencilIcon} />
                </Link>
            }
        </div>
        {programs.status === 0 && <div className="loadingOverlay"></div>}
        {programs.status === 1 && 
            <div className="content">
            <p className="contentSubtitle">{props.T("IrrigationPrograms")}</p><br />
            <div className="programList">
            {programs.v.map((p) => {
                const headID = "head" + p.id
                return (
                        <div key={headID} style={{ marginBottom: "20px"}} className="hscrollcontainer programListItem">
                        <CollapsableProgramTitle title={p.name} expandID={headID} />
                        <div id={headID} className="nodisplay">
                        <table  style={{width:"100%"}}>
                            <thead>
                            <tr>
                                <td align="center" width="20px">#</td> 
                                <td align="center" width="60px">{props.T("Valve")}</td>
                                <td align="center" width="150px">{props.T("RuntimeProgram")}</td>
                                <td align="center" width="150px">{props.T("DosingProgram")}</td>
                            </tr>
                            </thead>
                            <tbody>
                            {p.stages.length > 0 &&
                                p.stages.map(s => {return (
                                    <tr key={s.stage_index}>
                                    <td align="center">{s.stage_index+1}</td>
                                    <td align="center">{s.valve.name}</td>
                                    <td align="center">{s.runtime_program.name}</td>
                                    <td align="center">{s.dosing_program ? s.dosing_program.name : "---"}</td>
                                    </tr>
                                )})
                            }
                            
                            {props.user.integration_name !== "netafim_cloud" &&
                                <>
                                <tr><td colSpan="4">&nbsp;</td></tr>
                                <tr>
                                    <td colSpan="4" align="center">
                                    <Link to={"/irrigation/programs/irrigation/new/"+p.id}>
                                        <ActionButton T={props.T} title={props.T("Edit")} icon={pencilIcon} />
                                    </Link>
                                    </td>
                                </tr>
                                </>
                            }
                            </tbody>
                        </table></div>
                        </div>
                )
            })}
            </div>
            </div>
        }
        </>
    )
}

export default IrrigationProgramList
