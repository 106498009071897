import React, { useState, useEffect } from 'react'
import Nav from '../../../components/Nav'
import FormHeader from '../../../components/FormHeader';
import { Redirect } from 'react-router-dom';

const LayoutRowEdit = (props) => {
    const [resp, setResp] = useState({
        status: 2,
        error: null,
    })

    const [row, setRow] = useState({status: 0, v:null, error:null})

    useEffect(() => {
        const onRowGetResponse = (data, error) => {
            if (error) {
                window.location = "/config/layoutrows"
            } else {
                setRow({status: 1, v:data, error:null})
            }
        }
        props.backend.LayoutRowGet(onRowGetResponse, props.match.params.rid)
    }, [props.backend, props.match.params.rid])

    const onSubmit = (ev) => {
        ev.preventDefault()
        let fd = new FormData(ev.target)
        const row_id = fd.get("row_id")
        if (row_id === "") {
            ev.target.row_id.focus()
            return
        }

        const num_fields = Number(fd.get("num_fields"))
        if (isNaN(num_fields)) {
            ev.target.num_fields.focus()
            return
        }
        const num_plants = Number(fd.get("num_plants"))
        if (isNaN(num_plants)) {
            ev.target.num_plants.focus()
            return
        }
        let active = fd.get("active") === "yes" ? true : false
        setResp({status: 0, error: null})
        props.backend.LayoutRowUpdate(onResponse, props.match.params.rid, {
            id: row_id,
            num_fields: num_fields,
            num_plants: num_plants,
            active: active,
        })
    }

    const onResponse = (_, error) => {
        if (error) {
            setResp({status: 1, error: error})
        } else {
            setResp({status: 1, error: null})
        }
    }

    const renderForm = (d) => {
        return (
            <div className="inputForm">
            <FormHeader {...props} title={props.T("EditLayoutRow")} />
            <form method='POST' onSubmit={onSubmit}>
                <label htmlFor="row_id">{props.T("ID")}:
                <input type="text" className="textinput" name="row_id" defaultValue={d.id} placeholder="Enter row name"/>
                </label>
                <label htmlFor="num_fields">{props.T("NumFields")}:
                <input type="text" className="textinput" name="num_fields" defaultValue={d.num_fields} placeholder="Enter number of fields"/>
                </label>
                <label htmlFor="num_plants">{props.T("NumPlants")}:
                <input type="text" className="textinput" name="num_plants" defaultValue={d.num_plants} placeholder="Enter number of plants"/>
                </label>
                <label htmlFor="active">{props.T("Active")}:
                <select name="active" defaultValue={d.active ? "yes" : "no"}>
                    <option name="yes" value="yes">{props.T("YES")}</option>
                    <option name="no" value="no">{props.T("NO")}</option>
                </select>
                </label>
                <div className="submitLine error">
                {resp.status === 1 && resp.error ? "ERROR: " + resp.error : ""}
                </div>
                <div className="submitLine">
                <input type="submit" className="button" name="submitForm" value={props.T("Save")} />
                </div>
            </form>
            </div>
        )
    }
    return (
        <>
        <Nav isMain={false} subtitle="Config" {...props} />
        {resp.status === 0 || row.status === 0 ? <div className="loadingOverlay"></div> : ""}
        {resp.status === 1 && !resp.error && <Redirect to="/config/layoutrows" />}
        {row.status === 1 && row.error && <Redirect to="/config/layoutrows" />}
        {row.status === 1 && renderForm(row.v)}
        
        </>
    )
}

export default LayoutRowEdit
