import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { BsPencil } from 'react-icons/bs'

import Nav from '../../../components/Nav'
import ActionButton from '../../../components/ActionButton';

function LawnCutList(props) {
    const pencilIcon = <BsPencil size="14"/>
    const [lawnCuts, setLawnCuts] = useState({status: 0, v:[], error:null})

    useEffect(() => {
        const onListResponse = (data, error) => {
            if (error) {
                setLawnCuts({status: 1, v:[], error: error})
            } else {
                setLawnCuts({status: 1, v:data.lawn_cuts ? data.lawn_cuts : [], error: null})
            }
        }
        props.backend.LawnCutList(onListResponse)
    }, [props.backend])
    
    const printDateTime = (d) => {
        const nd = new Date(d)
        return nd.toLocaleDateString(props.T("LOCALE"), { hour: '2-digit', minute: '2-digit', hour12: false, month: 'short', day: 'numeric' })
    }

    return (
        <>
        <Nav isMain={false} withLink="/activities" subtitle="Activity" {...props} />
        <div className="filterMenu">
            <Link to="/activities/lawncut/new">
                <ActionButton T={props.T} title="New" icon={pencilIcon} />
            </Link>
        </div>
        {lawnCuts.status === 0 && <div className="loadingOverlay"></div>}
        {lawnCuts.status === 1 && 
            <div className="content">
                <p className="contentSubtitle">{props.T("LawnCutHistory")}</p><br />
                <div className="hscrollcontainer sticky">
                <table id="lawncutList">
                    <thead>
                    <tr>
                        <th align="center" >{props.T("Date")}</th>
                        <th align="center" >{props.T("EnteredBy")}</th>
                        <th align="center" >{props.T("StartRow")}</th>
                        <th align="center" >{props.T("EndRow")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {lawnCuts.v.length === 0 &&
                        <tr><td colSpan="4">{props.T("NoRecords")}</td></tr>
                    }
                    {lawnCuts.v.length > 0 &&
                        lawnCuts.v.map(lc => {return (
                            <tr key={lc.id}>
                            <td align="Left" width="150px">{printDateTime(lc.created_at)}</td>
                            <td align="center">{lc.user.first_name}</td>
                            <td align="center" width="80px">{lc.start_row}</td>
                            <td align="center" width="80px">{lc.end_row}</td>
                            </tr>
                        )})
                    }
                    </tbody>
                </table>
                </div>
            </div>
            }
        </>
    )
}

export default LawnCutList
