import React from 'react'
import { useState, useEffect } from 'react'
import Nav from '../../../components/Nav'
import FormHeader from '../../../components/FormHeader';
import { Redirect } from 'react-router-dom';
import { BsPlusCircle, BsDashCircle } from 'react-icons/bs';
import LayoutRowItemInput from '../../../components/LayoutRowItemInput';

const FieldWorkNew = (props) => {
    const [errorMsg, setErrorMsg] = useState({status: 1, error:null})
    const [workKinds, setWorkKinds] = useState({status: 0, v:[], error:null})
    const [layoutRows, setLayoutRows] = useState({status: 0, v:[], error:null})
    const [rowSpecs, setRowSpecs] = useState({v:[]})
    
    useEffect(() => {
        const onAttributeListResponse = (data, error) => {
            if (error) {
                setErrorMsg({status:2, error:error})
            } else {
                setWorkKinds({status: 1, v:data.work_kinds ? data.work_kinds : [], error:null})
            }
        }
        const onRowListResponse = (data, error) => {
            if (error) {
                setErrorMsg({status:2, error:error})
            } else {
                setLayoutRows({status: 1, v:data.layout_rows ? data.layout_rows : [], error:null})
            }
        }
        props.backend.GetAttributes(onAttributeListResponse, {attributes: ["work_kinds"]})
        props.backend.LayoutRowList(onRowListResponse)
    }, [props.backend])

    const onSubmit = (ev) => {
        ev.preventDefault()
        var fd = new FormData(ev.target)
        const num_workers = Number(fd.get("num_workers").replace(/,/g, '.'))
        if (isNaN(num_workers)) {
            document.getElementById("num_workers").focus()
            return
        }
        if (rowSpecs.v.length === 0) {
            setErrorMsg({status: 1, error:props.T("RowListEmpty")})
            return
        }

        const data = {
            work_type: {id: fd.get("work_kind")},
            num_workers: Math.trunc(num_workers),
            worker_names: fd.get("worker_names"),
            rows: rowSpecs.v,
            note: fd.get("note"),
        }

        setErrorMsg({status: 0, error:null})
        props.backend.FieldWorkNew(onSaveResponse, data)
    }

    const onSaveResponse = (data, error) => {
        if (error) {
            setErrorMsg({status: 1, error:error})
        } else {
            setErrorMsg({status: 2, error:null})
        }
    }

    const switchLayoutRowMainInput = (ev) => {
        ev.preventDefault()
        document.getElementById("mainInput").classList.toggle("nodisplay")
        document.getElementById("LayoutRowItemInput").classList.toggle("nodisplay")
    }

    const addLayoutRowItems = (data) => {
        var items = rowSpecs.v
        items.push(data)
        setRowSpecs({v:items})
    }

    const removeLayoutRowItem = (spec) => {
        var items = rowSpecs.v.filter(rs => rs !== spec)
        setRowSpecs({v:items})
    }

    return (
        <>
        <Nav isMain={false} withLink="/activities" subtitle="Activity" {...props} />
        {(errorMsg.status === 0 || workKinds.status === 0 || layoutRows.status === 0) && <div className="loadingOverlay"></div>}
        {(errorMsg.status === 2 || workKinds.status === 2 || layoutRows.status === 2) && <Redirect to="/activities/fieldwork" />}
        {errorMsg.status === 1 && workKinds.status === 1 && layoutRows.status === 1 &&
            <>
            <LayoutRowItemInput T={props.T} layoutRows={layoutRows.v} appendLayoutRowItem={addLayoutRowItems} switchLayoutRowMainInput={switchLayoutRowMainInput} />
            <div id="mainInput" className="inputForm">
            <FormHeader {...props} title={props.T("NewFieldWork")}/>
            <form method="POST" onSubmit={onSubmit}>
                <label htmlFor="work_kind">{props.T("FieldWorkType")}:
                <select name="work_kind" id="work_kind">
                    {workKinds.v.map(wk => {return (
                        <option key={wk.id} id={wk.id} value={wk.id}>{props.T("wk_"+wk.name)}</option>
                    )})}
                </select>
                </label>
                <label htmlFor="num_workers">{props.T("NumberOfWorkers")}:
                <input id="num_workers" type="number" step="1" className="textinput" name="num_workers" defaultValue="1" placeholder="Enter number of workers" />
                </label>
                <label htmlFor="worker_names">{props.T("WorkerNames")}:
                <input id="worker_names" type="text" className="textinput" name="worker_names" placeholder="Enter worker names" />
                </label>
                <label htmlFor="note">{props.T("Note")}:
                <input id="note" type="text" className="textinput" name="note" placeholder="Enter note" />
                </label>
                <br />
                <div style={{marginTop: "20px"}} id="articlesView">
                    <ul className="header">
                        <li style={{cursor: "pointer"}} onClick={switchLayoutRowMainInput}><BsPlusCircle size="20"/> &nbsp; {props.T("AddLayoutRowSpec")}</li>
                    </ul>
                </div>
                <div className="hscrollcontainer">
                <table style={{width:"100%"}} id="inputRowSpecItem">
                    <thead>
                        <tr>
                            <td align="center" width="90px">{props.T("Row")}</td>
                            <td align="center">{props.T("NumFields")}</td>
                            <td align="center">{props.T("NumPlants")}</td>
                            <td align="center" width="25px">&nbsp;</td>
                        </tr>
                    </thead>
                    <tbody>
                    {rowSpecs.v.map((rs, idx) => {
                        let nf = 0
                        let np = 0
                        if (rs.is_partial) {
                            let plantsPerField = rs.start_row.num_plants / rs.start_row.num_fields
                            if (rs.is_field_including) {
                                nf = rs.num_fields;
                                np = rs.num_fields * plantsPerField
                            } else {
                                nf = rs.start_row.num_fields - rs.num_fields
                                np = rs.start_row.num_plants - (rs.num_fields * plantsPerField)
                            } 
                        } else {
                            const no = {numeric:true}
                            let items = layoutRows.v.filter(r => (
                                (r.id.localeCompare(rs.start_row.id, undefined, no) >= 0 && r.id.localeCompare(rs.end_row.id, undefined, no) <= 0) || 
                                (r.id.localeCompare(rs.start_row.id, undefined, no) <= 0 && r.id.localeCompare(rs.end_row.id, undefined, no) >= 0))
                            )
                            items.forEach(r => {
                                nf += r.num_fields
                                np += r.num_plants
                            })
                        }
                        return (
                        <tr key={idx}>
                            <td align="left">{rs.is_partial ? rs.start_row.id : rs.start_row.id + " - " + rs.end_row.id}</td>
                            <td align="center">{nf}</td>
                            <td align="center">{np}</td>
                            <td align="center" onClick={(ev) => {removeLayoutRowItem(rs)}}><BsDashCircle size="16" color="darkred"/></td>
                        </tr>
                    )})}
                    </tbody>
                </table>
                </div>
                {errorMsg.status === 1 && errorMsg.error && <div className="submitLine error">{errorMsg.error}</div>}
                <div className="submitLine">
                <input type="submit" className="button" name="submitForm" value={props.T("Save")} />
                </div>
            </form>
            </div>
            </>
        }
        </>
    )
}

export default FieldWorkNew
