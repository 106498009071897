import React, {useState} from 'react'
import Nav from '../../components/Nav'
import FormHeader from '../../components/FormHeader'

const AccountSettings = (props) => {
    const [user, setUser] = useState({
        status: 1,
        error: null
    })

    const onResponse = (data, error) => {
        console.log(data)
        if (error) {
            setUser({status: 1, error: error})
        } else {
            setUser({status: 1, error: null})
            props.reloadProfile()
        }
    }

    const onSubmit = (ev) => {
        ev.preventDefault()
        const fd = new FormData(ev.target)
        const p1 = fd.get("password")
        const p2 = fd.get("password2")
        if (p1 !== p2) {
            document.getElementById("errorNotEqual").classList.remove("nodisplay")
            return
        }
        const lang = fd.get("lang")
        let defaultFarmID = fd.get("defaultFarm")
        if (!defaultFarmID || defaultFarmID === "") {
            defaultFarmID = props.user.farm_id
        }
        setUser({status: 0, error:null})
        props.backend.UserUpdate(onResponse, {password: p1, lang: lang, default_farm_id: defaultFarmID})
    }

    const onInputChange = (ev) => {
        document.getElementById("errorNotEqual").classList.add("nodisplay")
        const btn = document.getElementById("submitButton")
        btn.textContent = props.T("Save")
        btn.disabled = false
    }

    return (
        <>
        <Nav isMain={false} subtitle="Account" {...props} />
        <div className="inputForm">
            <FormHeader {...props} title={props.T("AccountSettingsCap")} />
            <form method='POST' onSubmit={onSubmit}>
                <label htmlFor="email">
                <input type="text" className="textinput" name="email" value={props.user.email} readOnly/>
                </label>
                <label htmlFor="password">{props.T("NewPassword")}:
                <input type="password" className="textinput" name="password" placeholder="Enter new password" onChange={onInputChange}/>
                </label>
                <label htmlFor="password2">{props.T("NewPassword2")}:
                <input type="password" className="textinput" name="password2" placeholder="Enter new password again" onChange={onInputChange} />
                </label>
                {props.user.other_farms && props.user.other_farms.length > 0 &&
                    <label htmlFor="defaultFarm">{props.T("DefaultFarm")}:
                    <select name="defaultFarm" id="defaultFarm" defaultValue={props.user.default_farm_id} onChange={onInputChange}>
                        <option value={props.user.farm_id}>{props.user.farm_name}</option>
                        {props.user.other_farms.map(of => {return (
                            <option key={of[0]} value={of[0]}>{of[1]}</option>
                        )})}
                    </select>
                    </label>
                }
                <label htmlFor="lang">{props.T("Language")}:
                <select name="lang" id="lang" defaultValue={props.user.lang ? props.user.lang : "en"} onChange={onInputChange}>
                    <option id="en" value="en">English</option>
                    <option id="sr_cyr" value="sr_cyr">Српски (Ћирилица)</option>
                    <option id="sr_lat" value="sr_lat">Srpski (Latinica)</option>
                </select>
                </label>
                <div id="errorNotEqual" className="error nodisplay">Passwords must be equal</div>
                <br />
                <div className="submitLine">
                <button id="submitButton" 
                        type="submit" 
                        className="button" 
                        disabled={user.status === 1} 
                        name="submitButton" >{props.T("Save")}</button>
                </div>
                
            </form>
        </div>
        </>
    )
}

export default AccountSettings
