import React, { useState, useEffect } from 'react'

import Nav from '../../../components/Nav'
import FormHeader from '../../../components/FormHeader';
import { Redirect } from 'react-router-dom';

const ValveCycleNew = (props) => {
    const [programs, setPrograms] = useState({status: 0, v:[], error:null})
    useEffect(() => {
        const onListResponse = (data, error) => {
            if (error) {
                setPrograms({status: 1, v:[], error: error})
            } else {
                setPrograms({status: 1, v:data.irrigation_programs ? data.irrigation_programs : [], error: null})
            }
        }
        props.backend.IrrigationProgramList(onListResponse)
    }, [props.backend])

    const onSubmit = (ev) => {
        ev.preventDefault()
        let fd = new FormData(ev.target)
        props.backend.ValveCycleNew(onSaveResponse, {
            id: fd.get("program")
        })
    }

    const onSaveResponse = (_, error) => {
        if (error) {
            setPrograms({status: 1, v:programs.v, error:error})
        } else {
            setPrograms({status: 2, v:programs.v, error:null})
        }
    }

    return (
        <>
        <Nav isMain={false} subtitle="Irrigation" {...props} />
        {programs.status === 0 && <div className="loadingOverlay"></div>}
        {programs.status === 2 && <Redirect to="/irrigation/overview/valvecycle" />}
        {programs.status === 1 &&
        <div className="inputForm">
            <FormHeader {...props} title={props.T("NewIrrigationCycle")} />
            <form method='POST' onSubmit={onSubmit}>
                <label htmlFor="program">{props.T("IrrigationProgram")}:
                <select name="program" id="program">
                {programs.v.map(p => <option key={p.id} value={p.id}>{p.name}</option> )}
                </select>
                </label>
                <div className="submitLine">
                <input type="submit" className="button" name="submitForm" value={props.T("Save")} />
                </div>
            </form>
        </div>
        }
        </>
    )
}

export default ValveCycleNew
