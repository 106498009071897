import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { BsPencil } from 'react-icons/bs'

import Nav from '../../../components/Nav'
import IrrigationMenu from '../../../components/irrigation/IrrigationMenu';
import OverviewFilterMenu from '../../../components/irrigation/OverviewFilterMenu'
import ActionButton from '../../../components/ActionButton';
import { FaAngleDown } from 'react-icons/fa';

function DripMeasureList(props) {
    const pencilIcon = <BsPencil size="14"/>
    const [measurements, setMeasurements] = useState({status: 0, v:[], error:null})

    const hourMin = (date) => {
        var d = new Date(date)
        var options = { hour: '2-digit', minute: '2-digit', hour12: false};
        return d.toLocaleTimeString("en-US", options)
    }

    const dateHead = (date, locale) => {
        var d = new Date(date)
        var options = { weekday: 'short', day: 'numeric', month: 'numeric', year: 'numeric'};
        return d.toLocaleDateString(locale, options)
    }

    const collapseTable = (ev, id) => {
        const target = document.getElementById(id)
        target.classList.toggle("nodisplay")
    }

    useEffect(() => {
        const onListResponse = (data, error) => {
            if (error) {
                setMeasurements({status: 1, v:[], error: error})
            } else {
                setMeasurements({status: 1, v:data.measurements.filter(gm => gm.measurements.length > 0), error: null})
            }
        }
        props.backend.DrainMeasurementsList(onListResponse)
    }, [props.backend])

    
    return (
        <>
        <Nav isMain={false} subtitle="Irrigation" {...props} />
        <IrrigationMenu {...props} section="overview" />
        <div className="filterMenu">
            <OverviewFilterMenu {...props} filter="drip" />
            <Link to="/irrigation/overview/drip/new">
                <ActionButton T={props.T} title="New" icon={pencilIcon} />
            </Link>
        </div>
        {measurements.status === 0 && <div className="loadingOverlay"></div>}
        {measurements.status === 1 && 
        <div className="content">
            <p className="contentSubtitle">{props.T("DailyECPHMeasurements")}</p><br />
            <div className="hscrollcontainer sticky">
            {measurements.v.map(gm => { return (
                <div key={gm.group_date} style={{ marginBottom: "20px"}}>
                <p onClick={(ev) => collapseTable(ev, gm.group_date)}><FaAngleDown />&nbsp;{props.T("MeasurementsFrom")} <b>{dateHead(gm.group_date, props.T("LOCALE"))}</b>&nbsp;<FaAngleDown /></p>
                <table id={gm.group_date}>
                    <thead>
                    <tr>
                        <th align="center" >{props.T("EnteredBy")}</th>
                        <th align="center" >{props.T("Time")}</th>
                        <th align="center" >{props.T("Loc")}<br />( {props.T("VRP")} )</th>
                        <th align="center" >{props.T("Qty")}<br />( ml )</th>
                        <th align="center" >EC</th>
                        <th align="center" >PH</th>
                        <th align="center" >{props.T("DrainFrom")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {gm.measurements.length === 0 &&
                        <tr><td colSpan="7">{props.T("NoMeasurementsFound")}</td></tr>
                    }
                    {gm.measurements.length > 0 &&
                        gm.measurements.map(m => {return (
                            <tr key={m.id}>
                            <td align="left">{m.user.first_name}</td>
                            <td align="center">{hourMin(m.created_at)}</td>
                            <td align="center">{m.drain.valve.name+"-"+m.drain.row+"-"+m.drain.loc}</td>
                            <td align="right">{m.quantity}</td>
                            <td align="right">{m.ec}</td>
                            <td align="right">{m.ph}</td>
                            <td align="center">{m.source === "plantdrain" ? props.T("Drain") : props.T("Dripper")}</td>
                            </tr>
                        )})
                    }
                    </tbody>
                </table>
                </div>    
            )})}
            </div>
            </div>
            }
        </>
    )
}

export default DripMeasureList
