import React from 'react'
import { useState, useEffect} from 'react'
import Nav from '../../../components/Nav'
import FormHeader from '../../../components/FormHeader';
import { Redirect } from 'react-router-dom';
import { BsPlusCircle, BsDashCircle } from 'react-icons/bs';

const DeliveryNew = (props) => {
    const [errorMsg, setErrorMsg] = useState({status: 1, error:null})
    const [suppliers, setSuppliers] = useState({status: 0, v:[], error:null})
    const [products, setProducts] = useState({status:0, v:[], error:null})
    const [articles, setArticles] = useState({v:[]})
    const [docID, setDocID] = useState({status: 0, v:"", error:null})
    
    useEffect(() => {
        const onSupplierListResponse = (data, error) => {
            if (error) {
                setSuppliers({status: 2, v:null, error:error})
            } else {
                setSuppliers({status: 1, v:data.suppliers ? data.suppliers : [], error:null})
            }
        }
        const onProductListResponse = (data, error) => {
            if (error) {
                setProducts({status: 2, v:null, error:error})
            } else {
                setProducts({status: 1, v:data.products ? data.products : [], error:null})
            }
        }
        props.backend.SupplierList(onSupplierListResponse)
        props.backend.ProductList(onProductListResponse)
    }, [props.backend])
    
    const onSubmit = (ev) => {
        ev.preventDefault()
        var fd = new FormData(ev.target)
        const name = fd.get("name")
        if (!name || name === "") {
            document.getElementById("name").focus()
            return
        }
        const delivery_date = fd.get("delivery_date")
        if (!delivery_date || delivery_date === "") {
            document.getElementById("delivery_date").focus()
            return
        }
        var documentID = docID.v
        if (!documentID) {
            setErrorMsg({status: 1, error:"Document not uploaded"})
            document.getElementById("document").focus()
            return
        }
        if (articles.v.length === 0) {
            setErrorMsg({status: 1, error:"Article list is empty"})
            return
        }
        const data = {
            name: name,
            supplier: {id: fd.get("supplier")},
            document: {id: documentID},
            delivery_date: (new Date(delivery_date)).toJSON(),
            items: articles.v,
        }
        setErrorMsg({status: 0, error:null})
        props.backend.DeliveryNew(onSaveResponse, data)
    }

    const onSaveResponse = (data, error) => {
        if (error) {
            setErrorMsg({status: 1, error:error})
        } else {
            setSuppliers({status: 2, v:suppliers.v, error:null})
        }
    }

    const onDocUploaded = (data, error) => {
        if (error) {
            setErrorMsg({status: 1, error:error})
        } else {
            setDocID({status: 1, v:data.id, error:null})
        }
    }

    const onInputFileChange = (ev) => {
        var fd = new FormData();
        fd.append("file", ev.target.files[0])
        props.backend.DocumentUpload(onDocUploaded, fd)
    }

    const switchArticleMainInput = (ev) => {
        ev.preventDefault()
        document.getElementById("mainInput").classList.toggle("nodisplay")
        document.getElementById("articleInput").classList.toggle("nodisplay")
    }

    const addArticle = (data) => {
        var items = articles.v
        var isDuplicate = false
        for (var i=0; i<items.length; i++) {
            var item = items[i]
            if (data.product.id === item.product.id && data.quantity === item.quantity) {
                isDuplicate = true
                items[i].quantity = data.quantity
            }
        }
        if (!isDuplicate) {
            items.push(data)
        }
        setArticles({v:items})
    }

    const removeArticle = (art) => {
        var items = articles.v.filter(a => a !== art)
        setArticles({v:items})
    }

    const ArticleInput = ({appendArticle}) => {
        const [selectedProduct, setSelectedProduct] = useState(products.v[0])
        const updateSelectedProduct = (ev) => {
            ev.preventDefault()
            const sb = ev.target;
            const productID = [].filter.call(sb.options, opt => opt.selected)[0].value
            const p = products.v.filter(p => p.id === productID)[0]
            setSelectedProduct(p)
        }

        const add = (ev) => {
            ev.preventDefault()
            const p = selectedProduct
            const psize = Number(document.getElementById("package_size").value.replace(/,/g, '.'))
            if (!psize || isNaN(psize)) {
                document.getElementById("package_size").focus()
                return
            }
            const pcount = Number(document.getElementById("package_count").value.replace(/,/g, '.'))
            if (!pcount || isNaN(pcount)) {
                document.getElementById("package_count").focus()
                return
            }
            appendArticle({
                product: p,
                quantity: Number(psize).toFixed(2),
                package_count: pcount,
            })
            switchArticleMainInput(ev)
        }
        return (
            <div id="articleInput" className="inputForm nodisplay">
                <FormHeader {...props} title={props.T("NewArticle")} linkTo={switchArticleMainInput} />
                <label htmlFor="product">{props.T("Product")}:
                <select name="product" id="product" onChange={updateSelectedProduct}>
                    {products.v.map(p => {return (
                        <option key={p.id} id={p.id} value={p.id}>{p.name} - {props.T("pt_"+p.package_type.name)}</option>
                    )})}
                </select>
                </label>
                <div className="mergedLine">
                    <label htmlFor="package_size">{props.T("PackageSize")} ({selectedProduct ? selectedProduct.package_type.unit : products.v[0].package_type.unit}):
                    <input id="package_size" type="number" defaultValue="0" className="textinput" name="package_size" placeholder="Enter package size name" />
                    </label>
                    <label htmlFor="package_count">{props.T("PackageCount")}:
                    <input id="package_count" type="number" defaultValue="0" className="textinput" name="package_count" placeholder="Enter package count" />
                    </label>
                </div>
                <div className="submitLine">
                <button onClick={add}>{props.T("Add")}</button>
                </div>
            </div>
        )
    }
    const dateInputToday = () => {
        return (new Date()).toISOString().split("T")[0]
    }

    return (
        <>
        <Nav isMain={false} subtitle="Inventory" {...props} />
        {(suppliers.status === 0 || products.status === 0 || errorMsg.status === 0) && <div className="loadingOverlay"></div>}
        {(suppliers.status === 2 || products.status === 2) && <Redirect to="/inventory/deliveries" />}
        {suppliers.status === 1 && products.status === 1 &&
            <>
            <ArticleInput appendArticle={addArticle} />
            <div id="mainInput" className="inputForm">
            <FormHeader {...props} title={props.T("NewDelivery")} />
            <form method="POST" onSubmit={onSubmit}>
                <label htmlFor="name">{props.T("Name")}:
                <input id="name" type="text" className="textinput" name="name" placeholder="Enter name" />
                </label>
                <label htmlFor="method">{props.T("Supplier")}:
                <select name="supplier" id="supplier">
                    {suppliers.v.map(s => {return (
                        <option key={s.id} id={s.name} value={s.id}>{s.name}</option>
                    )})}
                </select>
                </label>
                <label htmlFor="delivery_date">{props.T("DeliveryDate")}:
                <input id="delivery_date" type="date" className="textinput" name="delivery_date" defaultValue={dateInputToday()} min="2021-01-01" max="2031-12-31" />
                </label>
                <label htmlFor="document">{props.T("Document")}:
                <input type="file" id="document" name="document" onChange={onInputFileChange} />
                </label>

                <br />
                <div style={{marginTop: "20px"}} id="articlesView">
                    <ul className="header">
                        <li style={{cursor: "pointer"}} onClick={switchArticleMainInput}><BsPlusCircle size="20"/> &nbsp; {props.T("AddArticle")}</li>
                    </ul>
                </div>
                <div className="hscrollcontainer">
                <table style={{width:"100%"}} id="inputArticles">
                    <thead>
                        <tr>
                            <td align="center">{props.T("Product")}</td>
                            <td align="center" width="60px">{props.T("Size")}</td>
                            <td align="center" width="60px">{props.T("Count")}</td>
                            <td align="center" width="25px">&nbsp;</td>
                        </tr>
                    </thead>
                    <tbody>
                    {articles.v.map(art => {return (
                        <tr key={"" + art.product.id + "_" + art.package_size}>
                            <td align="left">{art.product.name}</td>
                            <td align="center">{art.quantity}{art.product.package_type.unit}</td>
                            <td align="center">{art.package_count}</td>
                            <td align="center" onClick={(ev) => {removeArticle(art)}}><BsDashCircle size="16" color="darkred"/></td>
                        </tr>
                    )})}
                    </tbody>
                </table>
                </div>
                {errorMsg.status === 1 && errorMsg.error && <div className="submitLine error">{errorMsg.error}</div>}
                <div className="submitLine">
                <input type="submit" className="button" name="submitForm" value={props.T("Save")} />
                </div>
            </form>
            </div>
            </>
        }
        </>
    )
}

export default DeliveryNew
