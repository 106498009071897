import React, { useState, useEffect } from 'react'

import Nav from '../../../components/Nav'
import ConfigWizardForm from '../../../components/farmconfig/ConfigWizardForm'
import FarmConfigMenu from '../../../components/farmconfig/FarmConfigMenu';

const IrrigationSystemNew = (props) => {
    const editSystemId = props.match.params.sid

    const [availableOptions, setAvailableOptions] = useState({status: 0, v: null, error: null})
    const [systemInfo, setSystemInfo] = useState({status: editSystemId ? 0 : 1, v: null, error: null})

    useEffect(() => {
        const onAttribResponse = (data, error) => {
            if (error) {
                setAvailableOptions({status: 1, v: null, error: error})
            } else {
                setAvailableOptions({status: 1, v: data, error: null})
            }
        }

        const onSystemInfoResponse = (data, error) => {
            if (error) {
                setSystemInfo({status: 1, v: null, error: error})
            } else {
                const matchingSystems = data.systems.filter(s => s.id === editSystemId)
                const s = matchingSystems.length === 1 ? matchingSystems[0] : null
                setSystemInfo({status: 1, v: s, error: null})
            }
        }
        props.backend.GetAttributes(onAttribResponse, {attributes: ["ir_systems", "ir_integrations"]})
        if (editSystemId) {
            props.backend.IrrigationSystemList(onSystemInfoResponse)
        }
    }, [props.backend, editSystemId])

    const onSubmit = (formData) => {
        props.onSubmit(formData)
    }

    return (
        <>
        <Nav isMain={false} subtitle="Config" {...props} />
        <FarmConfigMenu {...props} section="irrigation" />
        {availableOptions.status === 0 || systemInfo.status === 0
            ? <div className="loadingOverlay"></div> 
            : <ConfigWizardForm {...props} availableOptions={availableOptions.v} system={systemInfo.v} onSubmit={onSubmit} />
        }
        </>
    )
}

export default IrrigationSystemNew
