import React from 'react'
import { useState, useEffect} from 'react'
import Nav from '../../../components/Nav'
import FormHeader from '../../../components/FormHeader';
import { Redirect } from 'react-router-dom';

const ProductNew = (props) => {
    const withPrev = props.match.params.pid ? true : false;
    const [prev, setPrev] = useState({status: withPrev ? 0 : 1, v:null, error:null})
    const [errorMsg, setErrorMsg] = useState({status: 1, error:null})
    const [packageTypes, setPackageTypes] = useState({status: 0, v:[], error:null})
    const [categories, setCategories] = useState({status: 0, v:[], error:null})

    useEffect(() => {
        const onAttributesResponse = (data, error) => {
            if (error) {
                setCategories({status: 1, v:[], error: error})
            } else {
                setCategories({status: 1, v:data.product_categories ? data.product_categories : [], error: null})
                setPackageTypes({status: 1, v:data.product_package_types ? data.product_package_types : [], error:null})
            }
        }
        const onGetPrevResponse = (data, error) => {
            if (error) {
                setPrev({status: 2, v:null, error:error})
            } else {
                setPrev({status: 1, v:data, error:null})
            }
        }
        if (withPrev) {
            props.backend.ProductGet(onGetPrevResponse, props.match.params.pid)
        }
        
        props.backend.GetAttributes(onAttributesResponse, {attributes: ["product_categories", "product_package_types"]})

    }, [props.backend, props.match.params.pid, withPrev])
    
    const onSubmit = (ev) => {
        ev.preventDefault()
        var fd = new FormData(ev.target)
        const name = fd.get("name")
        if (!name || name === "") {
            document.getElementById("name").focus()
            return
        }
        const description = fd.get("description")
        if (!description || description === "") {
            document.getElementById("description").focus()
            return
        }

        const categoryID = fd.get("category")
        const packageTypeID = fd.get("package_type")
        const newID = (withPrev) ? prev.v.id : ""
        const data = {
            id: newID,
            category: {id: categoryID},
            package_type: {id: packageTypeID},
            name: name,
            description: description,
        }
        setErrorMsg({status: 0, error:null})
        if (withPrev) {
            props.backend.ProductUpdate(onSaveResponse, prev.v.id, data)
        } else {
            props.backend.ProductNew(onSaveResponse, data)
        }
    }

    const onSaveResponse = (data, error) => {
        if (error) {
            setErrorMsg({status: 1, error:error})
        } else {
            setCategories({status: 2, v:categories.v, error:null})
        }
    }

    return (
        <>
        <Nav isMain={false} subtitle="Inventory" {...props} />
        {(categories.status === 0 || packageTypes.status === 0 || prev.status === 0 || errorMsg.status === 0) && <div className="loadingOverlay"></div>}
        {(categories.status === 2 || packageTypes.status === 2 || prev.status === 2) && <Redirect to="/inventory/products" />}
        {categories.status === 1 && packageTypes.status === 1 && prev.status === 1 && errorMsg.status === 1 &&
            <div className="inputForm">
            <FormHeader {...props} title={withPrev ? props.T("EditProduct") : props.T("NewProduct")}/>
            <form method="POST" onSubmit={onSubmit}>
                <label htmlFor="name">{props.T("Name")}:
                <input id="name" type="text" className="textinput" name="name" defaultValue={withPrev ? prev.v.name : ""} placeholder="Enter product name" />
                </label>
                <label htmlFor="method">{props.T("Category")}:
                <select name="category" id="category" defaultValue={withPrev && prev.v.category.id ? prev.v.category.id : ""}>
                    {categories.v.map(pc => {return (
                        <option key={pc.id} id={pc.name} value={pc.id}>{pc.display_name}</option>
                    )})}
                </select>
                </label>
                <label htmlFor="package_type">{props.T("Package")}:
                <select name="package_type" id="package_type" defaultValue={withPrev && prev.v.package_type.id ? prev.v.package_type.id : ""}>
                    {packageTypes.v.map(pt => {return (
                        <option key={pt.id} id={pt.name} value={pt.id}>{props.T("pt_"+pt.name)}</option>
                    )})}
                </select>
                </label>
                <label htmlFor="description">{props.T("Description")}:
                <textarea id="description" type="text" rows="5" cols="50" className="textareainput" name="description" defaultValue={withPrev ? prev.v.description : ""} placeholder="Enter short description" />
                </label>
                {errorMsg.status === 1 && errorMsg.error && <div className="submitLine error">{errorMsg.error}</div>}
                <div className="submitLine">
                <input type="submit" className="button" name="submitForm" value={props.T("Save")} />
                </div>
            </form>
            </div>
        }
        </>
    )
}

export default ProductNew
