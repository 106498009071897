import React, { useEffect, useState } from 'react'

import Nav from '../../components/Nav'

function MeasurementsReport(props) {
    const [totals, setTotals] = useState({status: 0, v:[], error:null})
    const [valves, setValves] = useState({status: 0, v:[], error:null})
    const [year, setYear] = useState(new Date().getFullYear())
    const [filterValve, setFilterValve] = useState("any")
    const [aggregate, setAggregate] = useState("day")

    useEffect(() => {
        const onListResponse = (data, error) => {
            if (error) {
                setTotals({status: 1, v:[], error: error})
            } else {
                setTotals({status: 1, v:data.valve_measurement_reports ? data.valve_measurement_reports : [], error: null})
            }
        }
        const onAttributesListResponse = (data, error) => {
            if (error){
                setValves({status: 1, v:[], error: error})
            } else {
                setValves({status: 1, v:data.valves ? data.valves : [], error: null})
            }
        }
        props.backend.ReportsIrrigation(onListResponse, year, aggregate, filterValve)
        props.backend.GetAttributes(onAttributesListResponse, {attributes: ["valves"]})
    }, [props.backend, aggregate, filterValve, year])
    
    const getYearRange = () => {
        let r = []
        const currentYear = new Date().getFullYear()
        for (let i = 2016; i<=currentYear; i++) {
            r.push(i)
        }
        return r
    }

    const shortDate = (date) => {
        var d = new Date(date)
        var options = { year: 'numeric', month: 'short', day: 'numeric' };
        return d.toLocaleDateString(props.T("LOCALE"), options)
    }

    const valveChange = (ev) => {
        ev.preventDefault()        
        const valveIndex = [].filter.call(ev.target.options, opt => opt.selected)[0].value
        setFilterValve(valveIndex)
        setTotals({status: 0, v:[], error:null})
        props.backend.ReportsIrrigation(onListResponse, year, aggregate, valveIndex)
    }
    const aggregateChange = (ev) => {
        ev.preventDefault()        
        const newAggregate = [].filter.call(ev.target.options, opt => opt.selected)[0].value
        setAggregate(newAggregate)
        setTotals({status: 0, v:[], error:null})
        props.backend.ReportsIrrigation(onListResponse, year, newAggregate, filterValve)
    }
    const yearChange = (ev) => {
        ev.preventDefault()        
        const y = [].filter.call(ev.target.options, opt => opt.selected)[0].value
        setYear(y)
        setTotals({status: 0, v:[], error:null})
        props.backend.ReportsIrrigation(onListResponse, y, aggregate, filterValve)
    }

    const onListResponse = (data, error) => {
        if (error) {
            setTotals({status: 1, v:[], error: error})
        } else {
            setTotals({status: 1, v:data.valve_measurement_reports ? data.valve_measurement_reports : [], error: null})
        }
    }

    return (
        <>
        <Nav isMain={false} subtitle="Inventory" {...props} withLink="/reports"/>
        {(totals.status === 0 || valves.status === 0) && <div className="loadingOverlay"></div>}
        {totals.status === 1 && valves.status === 1 && 
            <>
            <div className="filterMenu">
                <select className="filterSelect" name="filter" defaultValue={filterValve} onChange={valveChange}>
                    <option value="any" id="any">{props.T("FilterAllValves")}</option>
                    {valves.v.map(v => {return (
                        <option key={v.id} value={v.valve_index}>{props.T("Valve")} {v.valve_index}</option>
                    )})}
                </select>
                <select className="aggregateSelect" name="aggregate" defaultValue={aggregate} onChange={aggregateChange}>
                    <option value="day" id="day">{props.T("PerDay")}</option>
                    <option value="week" id="week">{props.T("PerWeek")}</option>
                </select>

                <select name="yearFilter" defaultValue={year} onChange={yearChange}>
                    {getYearRange().map(y => {return (
                        <option key={y} value={y}>{y}</option>
                    )})}
                </select>
            </div>
            <div className="content">
                <p className="contentSubtitle">{props.T("ValvesAndDrains")}</p><br />
                <div className="hscrollcontainer sticky">
                <table>
                    <thead>
                    <tr>
                        <th align="center" style={{borderRight: "1px solid purple"}}>{props.T("Date")}</th>
                        <th align="center">{props.T("PerPlantCap")}<br />( ml )</th>
                        <th align="center" style={{borderRight: "1px solid purple"}}>{props.T("InDrain")}<br />( ml )</th>
                        <th align="center">{props.T("Fert")}<br />EC</th>
                        <th align="center">{props.T("Dripper")}<br />EC</th>
                        <th align="center" style={{borderRight: "1px solid purple"}}>{props.T("Drain")}<br />EC</th>
                        <th align="center">{props.T("Fert")}<br />PH</th>
                        <th align="center">{props.T("Dripper")}<br />PH</th>
                        <th align="center">{props.T("Drain")}<br />PH</th>
                    </tr>
                    </thead>
                    <tbody>
                    {totals.v.length === 0 &&
                        <tr><td colSpan="2">{props.T("NoRecords")}</td></tr>
                    }
                    {totals.v.length > 0 &&
                        totals.v.map(item => {return (
                            <tr key={item.date}>
                                <td align="center" style={{borderRight: "1px solid purple"}}>{shortDate(item.date)}</td>
                                <td align="center">{Number(item.measurement.valve_water_per_plant*1000).toFixed(0)}</td>
                                <td align="center" style={{borderRight: "1px solid purple"}}>{item.measurement.drain_qty.toFixed(0)}</td>
                                <td align="center">{item.measurement.fert_ec.toFixed(2)}</td>
                                <td align="center">{item.measurement.dripper_ec.toFixed(2)}</td>
                                <td align="center" style={{borderRight: "1px solid purple"}}>{item.measurement.drain_ec.toFixed(2)}</td>
                                <td align="center">{item.measurement.fert_ph.toFixed(2)}</td>
                                <td align="center">{item.measurement.dripper_ph.toFixed(2)}</td>
                                <td align="center">{item.measurement.drain_ph.toFixed(2)}</td>
                            </tr>
                        )})
                    }
                    </tbody>
                </table>
                </div>
            </div>
            </>
            }
        </>
    )
}

export default MeasurementsReport
