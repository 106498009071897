import React from 'react'
import { useState, useEffect} from 'react'
import Nav from '../../components/Nav'
import FormHeader from '../../components/FormHeader';
import { Redirect } from 'react-router-dom';

const FormulaView = (props) => {
    const [formula, setFormula] = useState({status: 0, v:null, error:null})
    useEffect(() => {
        const onResponse = (data, error) => {
            if (error) {
                setFormula({status: 2, v:null, error:error})
            } else {
                if (data.approved_by !== undefined) {
                    setFormula({status: 1, v:data, error:null})
                } else {
                    setFormula({status: 2, v:null, error:null})
                }
            }
        }
        props.backend.FormulaGet(onResponse, props.match.params.fid)
    }, [props.backend, props.match.params.fid])

    return (
        <>
        <Nav isMain={false} subtitle="Mixtures" {...props} />
        {(formula.status === 0) && <div className="loadingOverlay"></div>}
        {(formula.status === 2) && <Redirect to="/formulas" />}
        {formula.status === 1 &&
            <div className="inputForm">
                <FormHeader {...props} title={formula.v.name}/>
                <p>1. {props.T("RecommendedStartDate")}:</p><pre style={{color:"red"}}>{formula.v.recommended_start_date}</pre><br />
                <p>2. {props.T("ApplicationMethod")}:</p><pre style={{color:"red"}}>{props.T("formula_"+formula.v.method.name)}</pre><br />
                <p>3. {props.T("Description")}:</p><pre style={{color:"red"}}>{formula.v.description}</pre><br />
                <p>4. {props.T("Instructions")}:</p><pre style={{color:"red"}}>{formula.v.instructions}</pre><br />
                <p>5. {props.T("ApprovedBy")}:</p><pre style={{color:"red"}}>{formula.v.approved_by.first_name}</pre><br />
            </div>
        }
        </>
    )
}

export default FormulaView
