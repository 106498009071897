import React from 'react'
import { FaArrowAltCircleLeft } from 'react-icons/fa'

const BackButton = (props) => {
    return (
        <div className="backButton" onClick={props.linkTo ? props.linkTo : props.history.goBack}>
            <FaArrowAltCircleLeft size="24" /> &nbsp;{props.T("Back")}
        </div>
    )
}

export default BackButton
