import React, { useState, useEffect } from 'react'
import Nav from '../../../components/Nav'
import FormHeader from '../../../components/FormHeader';
import { Redirect } from 'react-router-dom';

const DrainsNew = (props) => {
    const [resp, setResp] = useState({
        status: 2,
        error: null,
    })

    const [valves, setValves] = useState({status: 0, v:[], error:null})

    useEffect(() => {
        const onValvesListResponse = (data, error) => {
            if (error) {
                setValves({status: 1, v:[], error: error})
            } else {
                setValves({status: 1, v:data.valves ? data.valves : [], error:null})
            }
        }
        props.backend.GetAttributes(onValvesListResponse, {attributes:["valves"]})
    }, [props.backend])

    const onSubmit = (ev) => {
        ev.preventDefault()
        let fd = new FormData(ev.target)
        const row = fd.get("row")
        if (row === "") {
            ev.target.row.focus()
            return
        }

        const loc = fd.get("loc")
        if (loc === "") {
            ev.target.loc.focus()
            return
        }

        let valve_id = fd.get("valve_id")
        if (valve_id === null) {
            ev.target.valve_id.focus()
            return
        }

        setResp({status: 0, error: null})
        props.backend.FarmDrainsNew(onResponse, {
            valve:{
                id: valve_id,
            },
            row: row,
            loc: loc,
        })
    }

    const onResponse = (_, error) => {
        if (error) {
            setResp({status: 1, error: error})
        } else {
            setResp({status: 1, error: null})
        }
    }

    return (
        <>
        <Nav isMain={false} subtitle="Config" {...props} />
        {resp.status === 0 || valves.status === 0 ? <div className="loadingOverlay"></div> : ""}
        {resp.status === 1 && !resp.error && <Redirect to="/config/drains" />}
        <div className="inputForm">
            <FormHeader {...props} title={props.T("NewDrainLocation")} />
            <form method='POST' onSubmit={onSubmit}>
                <label htmlFor="row">{props.T("Row")}:
                <input type="text" className="textinput" name="row" placeholder="Enter row number"/>
                </label>
                <label htmlFor="loc">{props.T("Location")}:
                <input type="text" className="textinput" name="loc" placeholder="Enter location"/>
                </label>
                <label htmlFor="valve_id">{props.T("Valve")}:
                <select name="valve_id">
                    {valves.v !== null && valves.v.map(valve => {return (
                        <option key={valve.id} name={valve.id} value={valve.id}>{valve.name}</option>
                    )})}
                </select>
                </label>
                <div className="submitLine error">
                {resp.status === 1 && resp.error ? "ERROR: " + resp.error : ""}
                </div>
                <div className="submitLine">
                <input type="submit" className="button" name="submitForm" value={props.T("Save")} />
                </div>
            </form>
        </div>
        </>
    )
}

export default DrainsNew
