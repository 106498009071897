import React, { useEffect, useState } from 'react'

import FormHeader from "./FormHeader"

function ImageView(props) {
    const [doc, setDoc] = useState({status: 0, contentType:null, obj:null, error:null})

    useEffect(() => {
        if(!props.docID) {
            return
        }
        const onDocResponse = (ct, blob, error) => {
            if (error) {
                setDoc({status: 1, ct:null, obj:null, error: error})
            } else {
                setDoc({status: 1, ct:ct, obj:URL.createObjectURL(blob), error:null})
            }
        }
        props.backend.DocumentGet(onDocResponse, props.docID)
    }, [props.backend, props.docID])

    const close = () => {
        setDoc({status: 0, contentType:null, obj:null, error:null})
        props.onClose()
    }

    return (
        <>
        {doc.status === 1 &&
            <div id="imageView" className="inputForm">
                <FormHeader {...props} title={props.T("Preview")} linkTo={close}/>
                <div className="hscrollcontainer">
                    <img id={"imgview_"+props.docID} alt={props.docID} src={doc.obj} width="100%" height="100%"/>
                </div>
            </div>
        }
        </>
    )
}

export default ImageView