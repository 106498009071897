import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { BsPencil } from 'react-icons/bs'

import Nav from '../../../components/Nav'
import ActionButton from '../../../components/ActionButton';

function SprayingList(props) {
    const pencilIcon = <BsPencil size="14"/>
    const [sprayings, setSprayings] = useState({status: 0, v:[], error:null})

    useEffect(() => {
        const onListResponse = (data, error) => {
            if (error) {
                setSprayings({status: 1, v:[], error: error})
            } else {
                setSprayings({status: 1, v:data.sprayings ? data.sprayings : [], error: null})
            }
        }
        props.backend.SprayingList(onListResponse)
    }, [props.backend])
    
    const printDateTime = (d) => {
        const nd = new Date(d)
        return nd.toLocaleDateString(props.T("LOCALE"), { hour: '2-digit', minute: '2-digit', hour12: false, month: 'short', day: 'numeric' })
    }
    return (
        <>
        <Nav isMain={false} withLink="/activities" subtitle="Activities" {...props} />
        <div className="filterMenu">
            <Link to="/activities/spraying/new">
                <ActionButton T={props.T} title="New" icon={pencilIcon} />
            </Link>
        </div>
        {sprayings.status === 0 && <div className="loadingOverlay"></div>}
        {sprayings.status === 1 && 
            <div className="content">
                <p className="contentSubtitle">{props.T("SprayingHistory")}</p><br />
                <div className="hscrollcontainer sticky">
                {sprayings.v.map(s => {return(
                    <div key={s.id} id={s.id}>
                        <p>{props.T("SprayedAt")} <b>{printDateTime(s.created_at)}</b>, {props.T("by")}{s.user.first_name} {s.user.last_name}</p>
                        <p>{props.T("SprayingKind")}: {props.T("sk_"+s.kind.name)}</p>
                        <p>{props.T("Formula")}: {s.formula.name}</p>
                        <p>{props.T("StartRow")}: {s.start_row}</p>
                        <p>{props.T("EndRow")}: {s.end_row}</p>
                        <p>{props.T("Note")}: {s.note}</p>
                        <table key={"spraying_"+s.id} id={"spraying_"+s.id}>
                            <thead>
                            <tr>
                                <th align="center" >{props.T("Product")}</th>
                                <th align="center" >{props.T("Quantity")}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {s.inventory_cl.map(cl =>{return (
                                <tr key={cl.id}>
                                    <td align="left">{cl.inventory.product.name}</td>
                                    <td align="center" width="80px">{cl.quantity_change} {cl.inventory.product.package_type.unit}</td>
                                </tr>
                            )})}
                            </tbody>
                        </table>
                        <br /><br />
                    </div>
                )})}
                </div>
            </div>
            }
        </>
    )
}

export default SprayingList
