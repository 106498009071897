class Lang {
    static en_dict = {
        LOCALE: "en-US",
        AccountSettings: "Account settings",
        AccountSettingsCap: "ACCOUNT SETTINGS",
        TracktorRefill: "Tracktor refill",
        TankRefill: "Tank refill",
        FieldWork: "Field work",
        DailyECPHMeasurements: "Daily EC/pH measurements",
        MeasurementsFrom: "Measurements from",
        DrainFrom: "From",
        VRP: "V-R-P",
        NoMeasurementsFound: "No measurements found",
        NewMeasurement: "NEW MEASUREMENT",
        PlantLocation: "Plant location",
        DailyIrrigationLog: "Daily irrigation log",
        IrrigationCyclesFrom: "Irrigation cycles from",
        bta: "b-t-a",
        RealEC: "Real EC",
        RealPH: "Real pH",
        NewIrrigationCycle: "NEW IRRIGATION CYCLE",
        IrrigationProgram: "Irrigation program",
        IrrigationPrograms: "Irrigation programs",
        DosingProgram: "Dosing program",
        DosingPrograms: "Dosing programs",
        RuntimeProgram: "Runtime program",
        RuntimePrograms: "Runtime programs",
        programsRuntime: "Runtime",
        TargetEC: "Target EC",
        TargetPH: "Target pH",
        NewIrrigationProgram: "NEW IRRIGATION PROGRAM",
        NewRuntimeProgram: "NEW RUNTIME PROGRAM",
        NewDosingProgram: "NEW DOSING PROGRAM",
        EditIrrigationProgram: "EDIT IRRIGATION PROGRAM",
        EditRuntimeProgram: "EDIT RUNTIME PROGRAM",
        EditDosingProgram: "EDIT DOSING PROGRAM",
        approved: "Approved",
        editing: "Editing",
        in_review: "In review",
        formula_leafspray: "Leaf spray",
        formula_system: "System",
        formula_groundspray: "Ground spray",
        NewMixture: "NEW FORMULA",
        EditMixture: "EDIT FORMULA",
        ApplicationMethod: "Application method",
        VisibilityLevel: "Visibility level",
        RecommendedStartDate: "Recommended start date",
        ApprovedBy: "Approved by",
        TracktorRefillHistory: "Tracktor refill history",
        RefilledAt: "Refilled at",
        by: "by ",
        NewTracktorRefill: "NEW TRACKTOR REFILL",
        TakeFromInventory: "TAKE FROM INVENTORY",
        pt_liquid: "Liquid",
        pt_powder: "Powder",
        SelectFromInventory: "SELECT FROM INVENTORY",
        NewTankRefill: "NEW TANK REFILL",
        TankRefillHistory: "Tank refill history",
        DosingTank: "Dosing tank",
        SprayingHistory: "Spraying history",
        SprayedAt: "Sprayed at",
        SprayingKind: "Spraying kind",
        sk_leaf_tracktor: "Leaf spray - Tracktor",
        sk_ground_sprinkler: "Ground spray - Hand sprinkler",
        sk_leaf_sprinkler: "Leaf spray - Hand sprinkler",
        NewSpraying: "NEW SPRAYING",
        StartRow: "Start row",
        EndRow: "End row",
        LawnCutHistory: "Lawn cut history",
        LawnCutNew: "NEW LAWN CUT",
        NoRecords: "No records found",
        FieldWorkHistory: "Field work history",
        FieldWorkType: "Type",
        NumWorkers: "Num workers",
        NumberOfWorkers: "Number of workers",
        WorkerNames: "Worker names",
        NewFieldWork: "NEW FIELD WORK",
        wk_regular_check: "Regular check",
        wk_weeding: "Weeding",
        wk_trimming: "Trimming",
        MaintenanceHistory: "Maintenance history",
        NewMaintenance: "NEW MAINTENANCE",
        mk_tank_filter_cleaning: "Tank filter cleaning",
        mk_main_filter_cleaning: "Main filter cleaning",
        mk_lateral_pipe_flush: "Lateral pipe flush",
        InventoryState: "Inventory state",
        NoArticlesFound: "No articles found",
        PackageSize: "Package size",
        ViewDeliveryDoc: "View delivery document",
        Preview: "PREVIEW",
        NewDelivery: "NEW DELIVERY",
        DeliveryDate: "Delivery date",
        Document: "",
        AddArticle: "ADD ARTICLE",
        PackageCount: "Package count",
        NewArticle: "NEW ARTICLE",
        ProductList: "Product list",
        NoProductsFound: "No products found",
        EditProduct: "EDIT PRODUCT",
        NewProduct: "NEW PRODUCT",
        SuppliersList: "Suppliers list",
        NoSuppliersFound: "No suppliers found",
        NewSupplier: "NEW SUPPLIER",
        EditSupplier: "EDIT SUPPLIER",
        FarmName: "Farm name",
        FarmInfo: "FARM INFO",
        Language: "Language",
        NewPassword: "New password",
        NewPassword2: "New password (again)",
        QtyChange: "Change",
        InventoryHistory: "Inventory history",
        NoDrainsFound: "No drains found",
        NewDrainLocation: "NEW DRAIN LOCATION",
        EditDrainLocation: "EDIT DRAIN LOCATION",
        FirstName: "First Name",
        LastName: "Last Name",
        NoUsers: "No users found",
        NewUser: "NEW USER",
        EditUser: "EDIT USER",
        ConfiguredIrrigationSystem: "Configured irrigation system",
        DOSINGCHANNELS: "DOSING CHANNELS",
        DripperCapacity: "Dripper capacity",
        NameOfValve: "Name of valve",
        NameOfChannel: "Name of channel",
        CloudUsername: "Cloud username",
        NetafimFarm: "Netafim farm",
        NetafimController: "Netafim controller",
        LastSync: "Last synchronization",
        SyncStatus: "Synchronization status",
        AreYouSureToRemove: "Are you sure you want to remove the configured system?",
        NewIrrigationSystem: "NEW IRRIGATION SYSTEM",
        EditIrrigationSystem: "EDIT IRRIGATION SYSTEM",
        SystemType: "System type",
        AddValve: "ADD VALVE",
        AddDosingChannel: "ADD DOSING CHANNEL",
        NetafimCloudURL: "Netafim Cloud URL",
        NetafimCloudUser: "Netafim Cloud username",
        NetafimCloudPass: "Netafim Cloud password",
        TermsOfUse: "Terms of use",
        ReadAndUnderstood: "I have read, understood and accepted the terms of integration",
        AddStage: "ADD STAGE",
        NewStage: "NEW STAGE",
        NumPlants: "Number of plants",
        NumFields: "Number of fields",
        NoLayoutRowsFound: "No rows found",
        NewLayoutRow: "ADD ROW",
        EcPh: "EC/pH",
        AddLayoutRowSpec: "ADD PER-ROW DETAILS",
        SelectLayoutRow: "ROW AND FIELD INPUT",
        RowFieldSelectionType: "Field interpretation method",
        RowFieldIncluding: "Including",
        RowFieldExcluding: "Excluding",
        RowInputPartialTrue: "Partial input of a single row",
        RowInputPartialFalse: "Whole rows",
        RowListEmpty: "Row list is empty",
        EnteredBy: "User",
        FilterAllWorkTypes: "ALL WORK TYPES",
        TotalForValve: "Total for valve",
        PerPlant: "per plant",
        PerPlantCap: "Per plant", 
        DefaultFarm: "Default farm",
        SetupPasswordMsg: "Setup password to complete the account registration",
        PasswordsNotEqual: "Passwords must be equal",
        Password: "Password",
        Password2: "Password (again)",
        ResetPasswordMsg: "Reset your account password",
        DayOverview: "Daily View",
        InventoryTotalSpent: "Total spent",
        ValvesAndDrains: "Valves and drains",
        FilterAllValves: "ALL VALVES",
        InDrain: "In drain",
        PerDay: "Per day",
        PerWeek: "Per week",
    }
    
    static srlat_dict = {
        LOCALE: "sr-latn",
        AccountSettings: "Moj nalog",
        Logout: "Odjava",
        Irrigation: "Navodnjavanje",
        Mixtures: "Formule",
        Activity: "Aktivnosti",
        Inventory: "Inventar",
        Reports: "Izveštaji",
        Config: "Podešavanje",
        TracktorRefill: "Dopuna traktora",
        TankRefill: "Dopuna tanka",
        FieldWork: "Rad u polju",
        Spraying: "Prskanje",
        Lawn: "Košenje",
        Maintenance: "Održavanje",
        Overview: "Pregled",
        Programs: "Programi",
        Cycles: "Ciklusi",
        EcPh: "Ocedi",
        New: "Dodaj",
        DailyECPHMeasurements: "Dnevna EC/pH merenja",
        MeasurementsFrom: "Merenja za",
        User: "Korisnik",
        Time: "Vreme",
        Qty: "Količina",
        DrainFrom: "Izvor",
        Loc: "Gde",
        VRP: "V-R-P",
        Drain: "Oced",
        Dripper: "Kapljač",
        NoMeasurementsFound: "Nema unetih merenja",
        NewMeasurement: "NOVO MERENJE",
        Back: "Nazad",
        PlantLocation: "Pozicija",
        Quantity: "Količina",
        Source: "Izvor",
        Save: "Snimi",
        Valve: "Ventil",
        Valves: "Ventili",
        VALVES: "VENTILI",
        Row: "Red",
        DailyIrrigationLog: "Dnevni ciklusi navodnjavanja",
        IrrigationCyclesFrom: "Ciklusi navodnjavanja za",
        B: "P",
        Т: "U",
        А: "P",
        bta: "p-u-p",
        V: "V",
        Amount: "Vrednost",
        Dosing: "Doziranje",
        DOSINGCHANNELS: "KANALI ZA DOZIRANJE",
        Target: "Ciljano",
        time: "vreme",
        Flow: "Protok",
        RealEC: "Stvarni EC",
        RealPH: "Stvarni pH",
        Chan: "Kanal",
        NewIrrigationCycle: "NOVI CIKLUS NAVODNJAVANJA",
        IrrigationProgram: "Program navodnjavanja",
        IrrigationPrograms: "Programi navodnjavanja",
        DosingProgram: "Program doziranja",
        DosingPrograms: "Programi doziranja",
        RuntimeProgram: "Program za vodu",
        RuntimePrograms: "Programi za vodu",
        programsRuntime: "Voda",
        Edit: "Izmeni",
        TargetEC: "Ciljani EC",
        TargetPH: "Ciljani pH",
        Method: "Metod",
        Before: "Pre",
        Total: "Ukupno",
        After: "Posle",
        NewIrrigationProgram: "NOVI PROGRAM NAVODNJAVANJA",
        NewRuntimeProgram: "NOVI PROGRAM ZA VODU",
        NewDosingProgram: "NOVI PROGRAM DOZIRANJA",
        EditIrrigationProgram: "IZMENI PROGRAM NAVODNJAVANJA",
        EditRuntimeProgram: "IZMENI PROGRAM ZA VODU",
        EditDosingProgram: "IZMENI PROGRAM DOZIRANJA",
        Name: "Naziv",
        minutes: "minuta",
        View: "Pogledaj",
        Status: "Status",
        approved: "Odobren",
        editing: "U izradi",
        in_review: "Čeka odobrenje",
        formula_leafspray: "Prskanje atomizerom",
        formula_system: "Kroz sistem",
        formula_groundspray: "Prskanje tla",
        NewMixture: "NOVA FORMULA",
        EditMixture: "IZMENA FORMULE",
        ApplicationMethod: "Metod primene",
        VisibilityLevel: "Nivo vidljivosti",
        Description: "Opis",
        Instructions: "Uputstvo",
        RecommendedStartDate: "Preporučeni datum početka",
        ApprovedBy: "Odobrio",
        TracktorRefillHistory: "Dopune traktora",
        RefilledAt: "Dopunjeno dana",
        by: " ",
        Product: "Proizvod",
        Note: "Napomena",
        NewTracktorRefill: "NOVA DOPUNA TRAKTORA",
        TakeFromInventory: "UZMI IZ INVENTARA",
        pt_liquid: "Tečnost",
        pt_powder: "Prašak",
        SelectFromInventory: "ODABERI IZ INVENTARA",
        Articles: "Artikli",
        Add: "Dodaj",
        NewTankRefill: "NOVA DOPUNA TANKA",
        TankRefillHistory: "Dopune tankova za prihranu",
        DosingTank: "Tank za doziranje",
        Tank: "Tank",
        Formula: "Formula",
        SprayingHistory: "Pregled prskanja",
        SprayedAt: "Prskano dana",
        SprayingKind: "Vrsta prskanja",
        sk_leaf_tracktor: "Preko lista - Atomizerom",
        sk_ground_sprinkler: "Prskanje tla - Prskalicom",
        sk_leaf_sprinkler: "Preko lista - Prskalicom",
        NewSpraying: "NOVO PRSKANJE",
        StartRow: "Početni red",
        EndRow: "Završni red",
        Kind: "Vrsta",
        LawnCutHistory: "Košenja trave u redovima",
        LawnCutNew: "NOVO KOŠENJE",
        Date: "Datum",
        NoRecords: "Nema unetih zapisa",
        FieldWorkHistory: "Radovi u polju",
        FieldWorkType: "Tip posla",
        Rows: "Redovi",
        NumWorkers: "Br radnika",
        NumberOfWorkers: "Broj radnika",
        WorkerNames: "Imena radnika",
        NewFieldWork: "NOVI RAD U POLJU",
        wk_regular_check: "Redovna provera",
        wk_weeding: "Plevljenje",
        wk_trimming: "Košenje trimerom",
        MaintenanceHistory: "Zapisi o održavanju",
        NewMaintenance: "NOVO ODRŽAVANJE",
        mk_tank_filter_cleaning: "Čišćenje filtera tanka",
        mk_main_filter_cleaning: "Čišćenje glavnog filtera",
        mk_lateral_pipe_flush: "Pražnjenje laterala",
        InventoryState: "Stanje inventara",
        NoArticlesFound: "Nema artikala",
        Remaining: "Preostalo",
        Deliveries: "Isporuke",
        Catalog: "Katalog",
        Suppliers: "Dobavljači",
        Supplier: "Dobavljač",
        PackageSize: "Veličina",
        ViewDeliveryDoc: "Pogledaj otpremnicu",
        Count: "Količina",
        Preview: "PRIKAZ",
        NewDelivery: "NOVA ISPORUKA",
        DeliveryDate: "Datum isporuke",
        Document: "Račun ili otpremnica",
        AddArticle: "DODAJ ARTIKAL",
        PackageCount: "Količina",
        NewArticle: "NOVI ARTIKAL",
        Size: "Veličina",
        Package: "Pakovanje",
        ProductList: "Lista proizvoda",
        NoProductsFound: "Nema unetih proizvoda",
        Category: "Kategorija",
        EditProduct: "IZMENA PROIZVODA",
        NewProduct: "NOVI PROIZVOD",
        SuppliersList: "Lista dobavljača",
        NoSuppliersFound: "Nema unetih dobavljača",
        NewSupplier: "NOVI DOBAVLJAČ",
        EditSupplier: "IZMENA DOBAVLJAČA",
        FarmName: "Naziv farme",
        FarmInfo: "PODACI O FARMI",
        Farm: "Farma",
        Drains: "Ocedi",
        Users: "Korisnici",
        AccountSettingsCap: "MOJ NALOG",
        Language: "Jezik",
        NewPassword: "Nova lozinka",
        NewPassword2: "Nova lozinka (ponovo)",
        Password: "Lozinka",
        Password2: "Lozinka (ponovo)",
        QtyChange: "Izmena",
        QTY: "Kol",
        InventoryHistory: "Pozicije u inventaru",
        NoDrainsFound: "Nema unetih oceda",
        YES: "DA",
        NO: "NE",
        Location: "Pozicija",
        Active: "Aktivan",
        NewDrainLocation: "NOVA POZICIJA OCEDA",
        EditDrainLocation: "IZMENA POZICIJE OCEDA",
        FirstName: "Ime",
        LastName: "Prezime",
        Email: "Email",
        Role: "Rola",
        NoUsers: "Nema unetih korisnika",
        NewUser: "NOVI KORISNIK",
        EditUser: "IZMENA KORISNIKA",
        ConfiguredIrrigationSystem: "Podešen sistem za navodnjavanje",
        DripperCapacity: "Kapacitet kapljača",
        Remove: "Obriši",
        NameOfValve: "Naziv ventila",
        NameOfChannel: "Naziv kanala",
        never: "nikada",
        Integration: "Integracija",
        CloudUsername: "Cloud korisničko ime",
        NetafimFarm: "Netafim farma",
        NetafimController: "Netafim kontroler",
        LastSync: "Poslednja sinhronizacija",
        SyncStatus: "Status sinhronizacije",
        System: "Sistem",
        AreYouSureToRemove: "Da li ste sigurni da želite da obrišete podešen sistem?",
        NewIrrigationSystem: "NOVI SISTEM ZA NAVODNJAVANJE",
        EditIrrigationSystem: "IZMENA SISTEMA ZA NAVODNJAVANJE",
        SystemType: "Tip sistema",
        AddValve: "DODAJ VENTIL",
        AddDosingChannel: "DODAJ KANAL ZA DOZIRANJE",
        NetafimCloudURL: "Netafim Cloud URL",
        NetafimCloudUser: "Netafim Cloud korisničko ime",
        NetafimCloudPass: "Netafim Cloud lozinka",
        TermsOfUse: "Uslovi korišćenja",
        ReadAndUnderstood: "Pročitao sam, razumeo, i prihvatam uslove korišćenja",
        Continue: "Nastavi",
        AddStage: "DODAJ ETAPU",
        NewStage: "NOVA ETAPA",
        Avg: "Prosek",
        Water: "Voda",
        ID: "Naziv",
        NumPlants: "Broj biljaka",
        NumFields: "Broj polja",
        Layout: "Redovi i polja",
        NoLayoutRowsFound: "Nema unetih redova",
        NewLayoutRow: "NOVI RED",
        AddLayoutRowSpec: "DODAJ REDOVE I POLJA",
        SelectLayoutRow: "SELEKCIJA REDOVA I POLJA",
        RowFieldSelectionType: "Metod selekcije",
        RowFieldIncluding: "Uključuje navedeni broj polja",
        RowFieldExcluding: "Sva polja osim navedenog broja polja",
        RowInputPartialTrue: "Polovičan unos jednog reda",
        RowInputPartialFalse: "Celi redovi",
        RowListEmpty: "Morate uneti redove i polja",
        EnteredBy: "Uneo",
        FilterAllWorkTypes: "SVI POSLOVI",
        TotalForValve: "Ukupno za ventil",
        PerPlant: "po biljci",
        PerPlantCap: "Po biljci",
        DefaultFarm: "Podrazumevana farma",
        SetupPasswordMsg: "Postavite lozinku kako bi završili registraciju naloga",
        PasswordsNotEqual: "Lozinke moraju biti iste",
        ResetPasswordMsg: "Postavka nove lozinke",
        DayOverview: "Pregled dana",
        PeriodOverview: "Pregled za period",
        Fertigation: "Prihrana",
        InventoryTotalSpent: "Ukupna potrošnja",
        State: "Stanje",
        ValvesAndDrains: "Ventili i ocedi",
        FilterAllValves: "SVI VENTILI",
        InDrain: "U ocedu",
        PerDay: "Po danu",
        PerWeek: "Nedeljno",
    }
    static srcyr_dict = {
        LOCALE: "sr-cyrl",
        AccountSettings: "Мој Налог",
        Logout: "Одјава",
        Irrigation: "Наводњавање",
        Mixtures: "Формуле",
        Activity: "Активности",
        Inventory: "Инвентар",
        Reports: "Извештаји",
        Config: "Подешавање",
        TracktorRefill: "Допуна трактора",
        TankRefill: "Допуна танка",
        FieldWork: "Рад у пољу",
        Spraying: "Прскање",
        Lawn: "Кошење",
        Maintenance: "Одржавање",
        Overview: "Преглед",
        Programs: "Програми",
        Cycles: "Циклуси",
        EcPh: "Оцеди",
        New: "Додај",
        DailyECPHMeasurements: "Дневна EC/pH мерења",
        MeasurementsFrom: "Мерења за",
        User: "Корисник",
        Time: "Време",
        Qty: "Количина",
        DrainFrom: "Извор",
        Loc: "Где",
        VRP: "В-Р-П",
        Drain: "Оцед",
        Dripper: "Капљач",
        NoMeasurementsFound: "Нема унетих мерења",
        NewMeasurement: "НОВО МЕРЕЊЕ",
        Back: "Назад",
        PlantLocation: "Позиција",
        Quantity: "Количина",
        Source: "Извор",
        Save: "Сними",
        Valve: "Вентил",
        Valves: "Вентили",
        VALVES: "ВЕНТИЛИ",
        Row: "Ред",
        DailyIrrigationLog: "Дневни циклуси наводњавања",
        IrrigationCyclesFrom: "Циклуси наводњавања за",
        B: "П",
        T: "У",
        A: "П",
        bta: "п-у-п",
        V: "В",
        Amount: "Вредност",
        Dosing: "Дозирање",
        DOSINGCHANNELS: "КАНАЛИ ЗА ДОЗИРАЊЕ",
        Target: "Циљано",
        time: "време",
        Flow: "Проток",
        RealEC: "Стварни EC",
        RealPH: "Стварни pH",
        Chan: "Канал",
        NewIrrigationCycle: "НОВИ ЦИКЛУС НАВОДЊАВАЊА",
        IrrigationProgram: "Програм наводњавања",
        IrrigationPrograms: "Програми наводњавања",
        DosingProgram: "Програм дозирања",
        DosingPrograms: "Програми дозирања",
        RuntimeProgram: "Програм за воду",
        RuntimePrograms: "Програми за воду",
        programsRuntime: "Вода",
        Edit: "Измени",
        TargetEC: "Циљани EC",
        TargetPH: "Циљани pH",
        Method: "Метод",
        Before: "Пре",
        Total: "Укупно",
        After: "После",
        NewIrrigationProgram: "НОВИ ПРОГРАМ НАВОДЊАВАЊА",
        NewRuntimeProgram: "НОВИ ПРОГРАМ ЗА ВОДУ",
        NewDosingProgram: "НОВИ ПРОГРАМ ДОЗИРАЊА",
        EditIrrigationProgram: "ИЗМЕНИ ПРОГРАМ НАВОДЊАВАЊА",
        EditRuntimeProgram: "ИЗМЕНИ ПРОГРАМ ЗА ВОДУ",
        EditDosingProgram: "ИЗМЕНИ ПРОГРАМ ДОЗИРАЊА",
        Name: "Назив",
        minutes: "минута",
        View: "Погледај",
        Status: "Статус",
        approved: "Одобрен",
        editing: "У изради",
        in_review: "Чека одобрење",
        formula_leafspray: "Прскање атомизером",
        formula_system: "Кроз систем",
        formula_groundspray: "Прскање тла",
        NewMixture: "НОВА ФОРМУЛА",
        EditMixture: "ИЗМЕНА ФОРМУЛЕ",
        ApplicationMethod: "Метод примене",
        VisibilityLevel: "Ниво видљивости",
        Description: "Опис",
        Instructions: "Упутство",
        RecommendedStartDate: "Препоручени датум почетка",
        ApprovedBy: "Одобрио",
        TracktorRefillHistory: "Допуне трактора",
        RefilledAt: "Допуњено дана",
        by: " ",
        Product: "Производ",
        Note: "Напомена",
        NewTracktorRefill: "НОВА ДОПУНА ТРАКТОРА",
        TakeFromInventory: "УЗМИ ИЗ ИНВЕНТАРА",
        pt_liquid: "Течност",
        pt_powder: "Прашак",
        SelectFromInventory: "ОДАБЕРИ ИЗ ИНВЕНТАРА",
        Articles: "Артикли",
        Add: "Додај",
        NewTankRefill: "НОВА ДОПУНА ТАНКА",
        TankRefillHistory: "Допуне танкова за прихрану",
        DosingTank: "Танк за дозирање",
        Tank: "Танк",
        Formula: "Формула",
        SprayingHistory: "Преглед прскања",
        SprayedAt: "Прскано дана",
        SprayingKind: "Врста прскања",
        sk_leaf_tracktor: "Преко листа - Атомизером",
        sk_ground_sprinkler: "Прскање тла - Прскалицом",
        sk_leaf_sprinkler: "Преко листа - Прскалицом",
        NewSpraying: "НОВО ПРСКАЊЕ",
        StartRow: "Почетни ред",
        EndRow: "Завршни ред",
        Kind: "Врста",
        LawnCutHistory: "Кошења траве у редовима",
        LawnCutNew: "НОВО КОШЕЊЕ",
        Date: "Датум",
        NoRecords: "Нема унетих записа",
        FieldWorkHistory: "Радови у пољу",
        FieldWorkType: "Тип посла",
        Rows: "Редови",
        NumWorkers: "Бр радника",
        NumberOfWorkers: "Број радника",
        WorkerNames: "Имена радника",
        NewFieldWork: "НОВИ РАД У ПОЉУ",
        wk_regular_check: "Редовна провера",
        wk_weeding: "Плевљење",
        wk_trimming: "Кошење тримером",
        MaintenanceHistory: "Записи о одржавању",
        NewMaintenance: "НОВО ОДРЖАВАЊЕ",
        mk_tank_filter_cleaning: "Чишћење филтера танка",
        mk_main_filter_cleaning: "Чишћење главног филтера",
        mk_lateral_pipe_flush: "Пражњење латерала",
        InventoryState: "Стање инвентара",
        NoArticlesFound: "Нема артикала",
        Remaining: "Преостало",
        Deliveries: "Испоруке",
        Catalog: "Каталог",
        Suppliers: "Добављачи",
        Supplier: "Добављач",
        PackageSize: "Величина",
        ViewDeliveryDoc: "Погледај отпремницу",
        Count: "Количина",
        Preview: "ПРИКАЗ",
        NewDelivery: "НОВА ИСПОРУКА",
        DeliveryDate: "Датум испоруке",
        Document: "Рачун/отпремница",
        AddArticle: "ДОДАЈ АРТИКАЛ",
        PackageCount: "Количина",
        NewArticle: "НОВИ АРТИКАЛ",
        Size: "Величина",
        Package: "Паковање",
        ProductList: "Листа производа",
        NoProductsFound: "Нема унетих производа",
        Category: "Категорија",
        EditProduct: "ИЗМЕНА ПРОИЗВОДА",
        NewProduct: "НОВИ ПРОИЗВОД",
        SuppliersList: "Листа добављача",
        NoSuppliersFound: "Нема унетих добављача",
        NewSupplier: "НОВИ ДОБАВЉАЧ",
        EditSupplier: "ИЗМЕНА ДОБАВЉАЧА",
        FarmName: "Назив фарме",
        FarmInfo: "ПОДАЦИ О ФАРМИ",
        Farm: "Фарма",
        Drains: "Оцеди",
        Users: "Корисници",
        AccountSettingsCap: "МОЈ НАЛОГ",
        Language: "Језик",
        NewPassword: "Нова лозинка",
        NewPassword2: "Нова лозинка (поново)",
        QtyChange: "Измена",
        QTY: "Кол",
        InventoryHistory: "Позиције у инвентару",
        NoDrainsFound: "Нема унетих оцеда",
        YES: "ДА",
        NO: "НЕ",
        Location: "Позиција",
        Active: "Активан",
        NewDrainLocation: "НОВА ПОЗИЦИЈА ОЦЕДА",
        EditDrainLocation: "ИЗМЕНА ПОЗИЦИЈЕ ОЦЕДА",
        FirstName: "Име",
        LastName: "Презиме",
        Email: "Имејл",
        Role: "Рола",
        NoUsers: "Нема унетих корисника",
        NewUser: "НОВИ КОРИСНИК",
        EditUser: "ИЗМЕНА КОРИСНИКА",
        ConfiguredIrrigationSystem: "Подешен систем за наводњавање",
        DripperCapacity: "Капацитет капљача",
        Remove: "Обриши",
        NameOfValve: "Назив вентила",
        NameOfChannel: "Назив канала",
        never: "никада",
        Integration: "Интеграција",
        CloudUsername: "Клауд корисничко име",
        NetafimFarm: "Нетафим фарма",
        NetafimController: "Нетафим контролер",
        LastSync: "Последња синхронизација",
        SyncStatus: "Статус синхронизације",
        System: "Систем",
        AreYouSureToRemove: "Да ли сте сигурни да желите да обришете подешен систем?",
        NewIrrigationSystem: "НОВИ СИСТЕМ ЗА НАВОДЊАВАЊЕ",
        EditIrrigationSystem: "ИЗМЕНА СИСТЕМА ЗА НАВОДЊАВАЊЕ",
        SystemType: "Тип система",
        AddValve: "ДОДАЈ ВЕНТИЛ",
        AddDosingChannel: "ДОДАЈ КАНАЛ ЗА ДОЗИРАЊЕ",
        NetafimCloudURL: "Нетафим Клауд УРЛ",
        NetafimCloudUser: "Нетафим Клауд корисничко име",
        NetafimCloudPass: "Нетафим Клауд лозинка",
        TermsOfUse: "Услови коришћења",
        ReadAndUnderstood: "Прочитао сам, разумео, и прихватам услове коришћења",
        Continue: "Настави",
        AddStage: "ДОДАЈ ЕТАПУ",
        NewStage: "НОВА ЕТАПА",
        Avg: "Просек",
        Water: "Вода",
        Pr: "Пр",
        ID: "Назив",
        NumPlants: "Број биљака",
        NumFields: "Број поља",
        Layout: "Редови и поља",
        NoLayoutRowsFound: "Нема унетих редова",
        NewLayoutRow: "НОВИ РЕД",
        AddLayoutRowSpec: "ДОДАЈ РЕДОВЕ И ПОЉА",
        SelectLayoutRow: "СЕЛЕКЦИЈА РЕДОВА И ПОЉА",
        RowFieldSelectionType: "Метод селекције",
        RowFieldIncluding: "Укључује наведени број поља",
        RowFieldExcluding: "Сва поља осим наведеног броја поља",
        RowInputPartialTrue: "Половичан унос једног реда",
        RowInputPartialFalse: "Цели редови",
        RowListEmpty: "Морате унети редове и поља",
        EnteredBy: "Унео",
        FilterAllWorkTypes: "СВИ ПОСЛОВИ",
        TotalForValve: "Укупно за вентил",
        PerPlant: "по биљци",
        PerPlantCap: "По биљци",
        DefaultFarm: "Подразумевана фарма",
        SetupPasswordMsg: "Поставите лозинку како би завршили регистрацију налога",
        PasswordsNotEqual: "Лозинке морају бити исте",
        Password: "Лозинка",
        Password2: "Лозинка (поново)",
        ResetPasswordMsg: "Поставка нове лозинке",
        DayOverview: "Преглед дана",
        PeriodOverview: "Преглед за период",
        Fertigation: "Прихрана",
        InventoryTotalSpent: "Укупна потрошња",
        State: "Стање",
        ValvesAndDrains: "Вентили и оцеди",
        FilterAllValves: "СВИ ВЕНТИЛИ",
        InDrain: "У оцеду",
        Fert: "Ферт",
        PerDay: "По дану",
        PerWeek: "Недељно",
    }

    static T(l) {
        switch(l) {
            case "sr_lat":
                return Lang.T_srlat
            case "sr_cyr":
                return Lang.T_srcyr
            default:
                return Lang.T_en
        }
    }

    static T_en(key) {
        const v = Lang.en_dict[key];
        return v ? v : key;
    }

    static T_srlat(key) {
        const v = Lang.srlat_dict[key];
        return v ? v : key;
    }

    static T_srcyr(key) {
        const v = Lang.srcyr_dict[key];
        return v ? v : key;
    }
}

export default Lang