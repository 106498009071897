import React, { useState, useEffect } from 'react'
import Nav from '../../../components/Nav'
import FormHeader from '../../../components/FormHeader';
import { Redirect } from 'react-router-dom';

const WaterRuntimeProgramNew = (props) => {
    const withPrev = props.match.params.pid ? true : false;
    
    const [methods, setMethods] = useState({status: 0, v:[], error:null})
    const [methodUnit, setMethodUnit] = useState("minutes")
    const [prev, setPrev] = useState({status: withPrev ? 0 : 1, v:null, error:null})
    const [errorMsg, setErrorMsg] = useState({status: 1, error:null})

    useEffect(() => {
        const onMethodsResponse = (data, error) => {
            if (!error) {
                setMethods({status: 1, v:data.runtime_program_methods ? data.runtime_program_methods : [], error:null})
            }
        }
        const onGetPrevResponse = (data, error) => {
            if (error) {
                setPrev({status: 2, v:null, error:error})
            } else {
                setMethodUnit(data.method.name === "qty" ? "liters/m3" : "minutes")
                setPrev({status: 1, v:data, error:null})
            }
        }
        if (withPrev) {
            props.backend.RuntimeProgramGet(onGetPrevResponse, props.match.params.pid)
        }
        props.backend.GetAttributes(onMethodsResponse, {attributes:["runtime_program_methods"]})
    }, [props.backend, props.match.params.pid, withPrev])
    

    const onMethodChange = (ev) => {
        for (let i=0; i<2; i++) {
            if (ev.target[i].selected) {
                const method = methods.v.find(m => m.id === ev.target[i].value)
                setMethodUnit(method.name === "qty" ? "liters/m3" : "minutes")
            }
        }
        let elts = ev.target.parentElement.parentElement.elements
        elts.before.value = "0"
        elts.total.value = "0"
        elts.after.value = "0"
    }

    const asMinutes = (v) => {
        return (methodUnit !== "minutes") ? v : v/60;
    }

    const onSubmit = (ev) => {
        ev.preventDefault()
        var fd = new FormData(ev.target)
        const name = fd.get("name")
        const method_id = fd.get("method")
        if(!name || name === "") {
            document.getElementById("name").focus()
            return
        }
        const method = methods.v.find(m => m.id === method_id)
        if(!method_id || !method || method_id === "") {
            document.getElementById("method").focus()
            return
        }

        const before = Number(fd.get("before").replace(/,/g, "."));
        const total = Number(fd.get("total").replace(/,/g, "."))
        const after = Number(fd.get("after").replace(/,/g, "."))
        if(isNaN(before)) {
            document.getElementById("before").focus()
            return
        }
        if(isNaN(total)) {
            document.getElementById("total").focus()
            return
        }
        if(isNaN(after)) {
            document.getElementById("after").focus()
            return
        }
 
        const data = {
            method: {id: method.id},
            name: name,
            v_before: (method.name !== "qty") ? before*60 : before,
            v_total: (method.name !== "qty") ? total*60 : total,
            v_after: (method.name !== "qty") ? after*60 : after,
        }
        setErrorMsg({status: 0, error:null})
        if (withPrev) {
            props.backend.RuntimeProgramUpdate(onSaveResponse, prev.v.id, data)
        } else {
            props.backend.RuntimeProgramNew(onSaveResponse, data)
        }
    }

    const onSaveResponse = (data, error) => {
        if (error) {
            setErrorMsg({status: 1, error:error})
        } else {
            setMethods({status: 2, v:methods.v, error:null})
        }
    }

    return (
        <>
        <Nav isMain={false} subtitle="Irrigation" {...props} />
        {(methods.status === 0 || prev.status === 0 || errorMsg === 0) && <div className="loadingOverlay"></div>}
        {(methods.status === 2 || prev.status === 2) && <Redirect to="/irrigation/programs/wruntime" />}
        {methods.status === 1 && prev.status === 1 &&
            <div className="inputForm">
            <FormHeader {...props} title={withPrev ? props.T("EditRuntimeProgram") : props.T("NewRuntimeProgram")}/>
            <form method="POST" onSubmit={onSubmit}>
                <label htmlFor="name">{props.T("Name")}:
                <input id="name" type="text" className="textinput" name="name" defaultValue={withPrev ? prev.v.name : ""} placeholder="Enter program name" />
                </label>
                <label htmlFor="method">{props.T("Method")}:
                <select name="method" id="method" defaultValue={withPrev ? prev.v.method.id : methods.v.find(m => m.name === "time").id} onChange={onMethodChange}>
                    {methods.v.map(m => {return (
                        <option key={m.id} id={m.name} value={m.id}>{props.T(m.display_name)}</option>
                    )})}
                </select>
                </label>
                <label htmlFor="before">{props.T("Before")} ({props.T(methodUnit)}):
                <input id="before" type="number" min="0" max="500" step="0.01" className="textinput" name="before" defaultValue={withPrev ? asMinutes(prev.v.v_before) : "0"} placeholder={"Enter before ("+methodUnit+")"} />
                </label>
                <label htmlFor="total">{props.T("Total")} ({props.T(methodUnit)}):
                <input id="total" type="number" min="0" max="500" step="0.01" className="textinput" name="total" defaultValue={withPrev ? asMinutes(prev.v.v_total) : "0"} placeholder={"Enter total ("+methodUnit+")"} />
                </label>
                <label htmlFor="after">{props.T("After")} ({props.T(methodUnit)}):
                <input id="after" type="number" min="0" max="500" step="0.01" className="textinput" name="after" defaultValue={withPrev ? asMinutes(prev.v.v_after) : "0"} placeholder={"Enter after ("+methodUnit+")"} />
                </label>
                {errorMsg.status === 1 && errorMsg.error && <div className="submitLine error">{errorMsg.error}</div>}
                <div className="submitLine">
                <input type="submit" className="button" name="submitForm" value={props.T("Save")} />
                </div>
            </form>
            </div>
        }
        </>
    )
}

export default WaterRuntimeProgramNew
