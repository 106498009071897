import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { BsPencil } from 'react-icons/bs'

import Nav from '../../../components/Nav'
import ActionButton from '../../../components/ActionButton';

function MaintenanceList(props) {
    const pencilIcon = <BsPencil size="14"/>
    const [maintenanceLogs, setMaintenanceLogs] = useState({status: 0, v:[], error:null})

    useEffect(() => {
        const onListResponse = (data, error) => {
            if (error) {
                setMaintenanceLogs({status: 1, v:[], error: error})
            } else {
                setMaintenanceLogs({status: 1, v:data.maintenance ? data.maintenance : [], error: null})
            }
        }
        props.backend.MaintenanceList(onListResponse)
    }, [props.backend])
    
    const printDateTime = (d) => {
        const nd = new Date(d)
        return nd.toLocaleDateString(props.T("LOCALE"), { hour: '2-digit', minute: '2-digit', hour12: false, month: 'short', day: 'numeric' })
    }
    return (
        <>
        <Nav isMain={false} withLink="/activities" subtitle="Activity" {...props} />
        <div className="filterMenu">
            <Link to="/activities/maintenance/new">
                <ActionButton T={props.T} title="New" icon={pencilIcon} />
            </Link>
        </div>
        {maintenanceLogs.status === 0 && <div className="loadingOverlay"></div>}
        {maintenanceLogs.status === 1 && 
            <div className="content">
                <p className="contentSubtitle">{props.T("MaintenanceHistory")}</p><br />
                <div className="hscrollcontainer sticky">
                <table id="maintenanceList">
                    <thead>
                    <tr>
                        <th align="center" >{props.T("Date")}</th>
                        <th align="center" >{props.T("EnteredBy")}</th>
                        <th align="center" >{props.T("Kind")}</th>
                        <th align="center" >{props.T("Note")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {maintenanceLogs.v.length === 0 &&
                        <tr><td colSpan="4">{props.T("NoRecords")}</td></tr>
                    }
                    {maintenanceLogs.v.length > 0 &&
                        maintenanceLogs.v.map(m => {return (
                            <tr key={m.id}>
                            <td align="left" width="100px">{printDateTime(m.created_at)}</td>
                            <td align="center" width="100px">{m.user.first_name}</td>
                            <td align="center" width="100px">{props.T("mk_"+m.kind.name)}</td>
                            <td align="center">{m.note}</td>
                            </tr>
                        )})
                    }
                    </tbody>
                </table>
                </div>
            </div>
            }
        </>
    )
}

export default MaintenanceList
