import React from 'react'
import { useState, useEffect} from 'react'
import Nav from '../../../components/Nav'
import FormHeader from '../../../components/FormHeader';
import { Redirect } from 'react-router-dom';

const ProductView = (props) => {
    const [product, setProduct] = useState({status: 0, v:null, error:null})
    const [inventoryLogs, setInventoryLogs] = useState({status: 0, v:[], error:null})

    useEffect(() => {
        const onResponse = (data, error) => {
            if (error) {
                setProduct({status: 2, v:null, error:error})
            } else {
                setProduct({status: 1, v:data, error:null})
            }
        }
        const onLogsResponse = (data, error) => {
            if (error) {
                setInventoryLogs({status: 2, v:[], error:error})
            } else {
                setInventoryLogs({status: 1, v:data.inventory_logs ? data.inventory_logs : [], error:null})
            }
        }
        props.backend.ProductGet(onResponse, props.match.params.pid)
        props.backend.InventoryProductLogs(onLogsResponse, props.match.params.pid)
    }, [props.backend, props.match.params.pid])

    const shortDateTime = (v) => {
        let d = new Date(v)
        return d.toLocaleTimeString([], {month:'short', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false})
    }
    return (
        <>
        <Nav isMain={false} subtitle="Products" {...props} />
        {(product.status === 0 || inventoryLogs.status === 0) && <div className="loadingOverlay"></div>}
        {(product.status === 2 || inventoryLogs.status === 2) && <Redirect to="/inventory" />}
        {product.status === 1 && inventoryLogs.status === 1 &&
            <>
            <div className="inputForm">
                <FormHeader {...props} title={product.v.name}/>
                <p>1. {props.T("Category")}:</p><pre style={{color:"red"}}>{product.v.category.display_name}</pre><br />
                <p>2. {props.T("Package")}:</p><pre style={{color:"red"}}>{props.T("pt_" + product.v.package_type.name)}</pre><br />
                <p>3. {props.T("Description")}:</p><pre style={{color:"red"}}>{product.v.description}</pre><br />
                <p>4. {props.T("InventoryHistory")}:</p>
            </div>
            <div className="hscrollcontainer">
            <table id="productLogs">
                <thead>
                <tr>
                    <td align="center" width="50px">{props.T("User")}</td>
                    <td align="center" width="100px">{props.T("Activity")}</td>
                    <td align="center" width="60px">{props.T("QTY")}</td>
                    <td align="center" width="60px">{props.T("QtyChange")}</td>
                </tr>
                </thead>
                <tbody>
                {inventoryLogs.v.length === 0 &&
                    <tr><td colSpan="4">{props.T("NoRecords")}</td></tr>
                }
                {inventoryLogs.v.length > 0 &&
                    inventoryLogs.v.map(l => {return (
                        <tr key={l.id}>
                            <td>{l.user.first_name}<br />{shortDateTime(l.created_at)}</td>
                            <td style={l.quantity_change < 0 ? {color: "red"} : {color: "green"}}>{l.meta_info}</td>
                            <td>{l.prev_qty} {l.inventory.product.package_type.unit}</td>
                            <td>{l.quantity_change}</td>
                        </tr>
                    )})
                }
                </tbody>
            </table>
            </div>
            </>
        }
        </>
    )
}

export default ProductView
