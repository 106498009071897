import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { BsPencil } from 'react-icons/bs'

import Nav from '../../components/Nav'
//import IrrigationMenu from '../../../components/irrigation/IrrigationMenu';
//import ProgramsFilterMenu from '../../../components/irrigation/ProgramsFilterMenu'
import ActionButton from '../../components/ActionButton';
//import CollapsableProgramTitle from '../../../components/irrigation/CollapsableProgramTitle'


const FormulaList = (props) => {
    const pencilIcon = <BsPencil size="14"/>
    const [formulas, setFormulas] = useState({status:0, v:[], error:null})

    useEffect(() => {
        const onListResponse = (data, error) => {
            if (error) {
                setFormulas({status:1, v:[], error:error})
            } else {
                setFormulas({status:1, v:data.formulas ? data.formulas : [], error:null})
            }
        }
        props.backend.FormulaList(onListResponse, "")
    }, [props.backend])

    const prettyDate = (date) => {
            var d = new Date(date)
            var options = { year: 'numeric', month: 'short', day: 'numeric' };
            return d.toLocaleDateString(props.T("LOCALE"), options)
    }

    const renderAction = (f) => {
        if (f.state.name === "approved") {
            return (
                <td align="center"><Link to={"/formulas/"+f.id}>{props.T("View")}</Link></td>
            )
        } else {
            return (
                <td align="center">
                    <Link to={"/formulas/edit/"+f.id}>
                        <ActionButton T={props.T} title="Edit" icon={pencilIcon} />
                    </Link>
                </td>
            )
        }
    }

    return (
        <>
        <Nav isMain={false} subtitle="Mixtures" {...props} />
        <div className="filterMenu">
            <Link to="/formulas/new">
                <ActionButton T={props.T} title="New" icon={pencilIcon} />
            </Link>
        </div>
        {formulas.status === 0 && <div className="loadingOverlay"></div>}
        {formulas.status === 1 && 
            <div className="content">
                <div className="hscrollcontainer sticky">
                <table id="formulaList">
                    <thead>
                    <tr>
                        <th align="center" >{props.T("EnteredBy")}</th> 
                        <th align="center" >{props.T("Name")}</th>
                        <th align="center" >{props.T("Method")}</th>
                        <th align="center" >{props.T("Status")}</th>
                        <th align="center" >&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody>
                    {formulas.v.length === 0 &&
                        <tr><td colSpan="7">No formulas found</td></tr>
                    }
                    {formulas.v.length > 0 &&
                        formulas.v.map(f => {return (
                            <tr key={f.id}>
                            <td align="center">{f.created_by.first_name}<br />{prettyDate(f.created_at)}</td>
                            <td align="left">{f.name}</td>
                            <td align="center">{props.T("formula_"+f.method.name)}</td>
                            <td align="center">{props.T(f.state.name)}</td>
                            {renderAction(f)}
                            </tr>
                        )})
                    }
                    </tbody>
                </table>
                </div>
            </div>
        }
        </>
    )
}

export default FormulaList

