import React, { useState } from 'react'
import FormHeader from '../../components/FormHeader'

const FarmEditForm = (props) => {
    const [error, setError] = useState(props.farm.error)
    const handleSubmit = (ev) => {
        ev.preventDefault()
        props.onSubmit(props.farm)
    }

    const handleChange = (ev) => {
        props.farm.error = undefined
        setError(undefined)
        const target = ev.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        props.farm.v[name] = value

        const sb = document.getElementById("submitButton")
        sb.textContent = props.T("Save")
        sb.disabled = false
    }

    return (
        <div className="inputForm">
            <FormHeader {...props} title={props.T("FarmInfo")} withoutBackButton="1"/>
            <form method='PUT' onSubmit={handleSubmit}>
            <label htmlFor="name">{props.T("Name")}:
                <input type="text" className="textinput" name="Name" defaultValue={props.farm.v.name} placeholder="Enter farm name" onChange={handleChange} />
            </label>
            <div id="dynamicelts">
                
            </div>
            <div className="submitLine error">
                {error ? "ERROR: " + error : ""}
            </div>
            <div className="submitLine">
                <button id="submitButton" 
                        type="submit" 
                        className="button" 
                        disabled={props.farm.status === 1} 
                        name="submitButton" >{props.T("Save")}</button>
            </div>
            </form>      
        </div>
    )
}

export default FarmEditForm
