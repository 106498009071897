import React, { useState } from 'react'
import Nav from '../../components/Nav'
import FormHeader from '../../components/FormHeader';
import { Redirect } from 'react-router-dom';

const FarmNew = (props) => {
    const [resp, setResp] = useState({
        status: 2,
        error: null,
    })

    const onSubmit = (ev) => {
        ev.preventDefault()
        setResp({status: 0, error: null})
        props.backend.AdminFarmNew(onResponse, {
            farm: {
                name: ev.target.name.value,
            },
            user: {
                email: ev.target.ownerEmail.value,
                first_name: ev.target.ownerFirstName.value,
                last_name: ev.target.ownerLastName.value,
            }
        })
    }

    const onResponse = (_, error) => {
        if (error) {
            setResp({status: 1, error: error})
        } else {
            setResp({status: 1, error: null})
        }
    }

    return (
        <>
        <Nav isMain={false} subtitle="Farms" {...props} />
        {resp.status === 0 ? <div className="loadingOverlay"></div> : ""}
        {resp.status === 1 && !resp.error ? <Redirect to="/admin/farms" /> : ""}
        <div className="inputForm">
            <FormHeader {...props} title="NEW FARM"/>
            <form method='POST' onSubmit={onSubmit}>
                <label htmlFor="name">Farm name:
                <input type="text" className="textinput" name="name" placeholder="Enter farm name"/>
                </label>
                <label htmlFor="ownerEmail">Owner email:
                <input type="text" className="textinput" name="ownerEmail" placeholder="Enter email"/>
                </label>
                <label htmlFor="ownerFirstName">First name:
                <input type="text" className="textinput" name="ownerFirstName" placeholder="Enter first name"/>
                </label>
                <label htmlFor="ownerLastName">Last name:
                <input type="text" className="textinput" name="ownerLastName"  placeholder="Enter last name"/>
                </label>
                <div className="submitLine error">
                {resp.status === 1 && resp.error ? "ERROR: " + resp.error : ""}
                </div>
                <div className="submitLine">
                <input type="submit" className="button" name="submitForm" value="SUBMIT" />
                </div>
            </form>
        </div>
        </>
    )
}

export default FarmNew
