import React from 'react'

const FilterButton = ({T, title, icon, isSelected}) => {
    if (isSelected !== undefined && isSelected) {
        return (
            <div className={`filterButton filterButton__selected`} >
                {icon}&nbsp; {T(title)}
            </div>
        )
    }
    return (
        <div className="filterButton" >
            {icon}&nbsp; {T(title)}
        </div>
    )
}

export default FilterButton
