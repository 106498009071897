import React from 'react'
import MainListItem from '../components/MainListItem'
import { BsDropletHalf } from 'react-icons/bs'
import { GiWeightLiftingUp } from 'react-icons/gi'
import Science from '@mui/icons-material/ScienceRounded'
import { FaChartLine, FaCogs, FaLayerGroup } from 'react-icons/fa'
import Nav from '../components/Nav'

const MainMenu = (props) => {
    
    const iconDroplet = <BsDropletHalf size="48px" color="#008899"/>
    const iconFlask = <Science sx={{ fontSize: "48px", color:"green" }} />
    const iconReports = <FaChartLine size="48px" color="orange"/>
    const iconInventory = <FaLayerGroup size="48px" color="purple"/>
    const iconConfig = <FaCogs size="48px" color="gray"/>
    const iconWeights = <GiWeightLiftingUp size="48px" color="black" />
    return (
        <>
        <Nav isMain={true} {...props} />
        <div className='mainmenu'>
            <ul>
                <MainListItem user={props.user} allowedRoles={["FarmOwner", "FarmManager", "Agronomist", "IrrigationOperator"]} caption={props.T("Irrigation")} icon={iconDroplet} path="/irrigation" />
                <MainListItem user={props.user} allowedRoles={["FarmOwner", "FarmManager", "Agronomist", "IrrigationOperator"]} caption={props.T("Mixtures")} icon={iconFlask} path="/formulas" />
                <MainListItem user={props.user} allowedRoles={["FarmOwner", "FarmManager", "IrrigationOperator"]} caption={props.T("Activity")} icon={iconWeights} path="/activities" />
                <MainListItem user={props.user} allowedRoles={["FarmOwner", "FarmManager", "Agronomist", "IrrigationOperator"]} caption={props.T("Inventory")} icon={iconInventory} path="/inventory" />
                <MainListItem user={props.user} allowedRoles={["FarmOwner", "FarmManager", "Agronomist", "IrrigationOperator"]} caption={props.T("Reports")} icon={iconReports} path="/reports" />
                <MainListItem user={props.user} allowedRoles={["FarmOwner"]} caption={props.T("Config")} icon={iconConfig} path="/config" />
            </ul>
        </div>
        </>
    )
}

export default MainMenu
