import React, { useState } from 'react'
import FormHeader from '../FormHeader'
import { Redirect } from 'react-router-dom';
import { BsPlusCircle, BsDashCircle } from 'react-icons/bs';
const ConfigWizardForm = (props) => {
    const prev = props.system;
    const emptyHiddenDefaultValues = {
        netafimUrl: prev && prev.ir_integration.netafim_config ? prev.ir_integration.netafim_config.tw_url : "",
        netafimUser: prev && prev.ir_integration.netafim_config ? prev.ir_integration.netafim_config.tw_username : "",
        netafimPass: prev && prev.ir_integration.netafim_config ? prev.ir_integration.netafim_config.tw_password : "",
        netafimFarmName: prev && prev.ir_integration.netafim_config ? prev.ir_integration.netafim_config.tw_farm_name  : "",
        netafimFarmDisplayName: prev && prev.ir_integration.netafim_config ? prev.ir_integration.netafim_config.tw_farm_display_name  : "",
        netafimControllerName: prev && prev.ir_integration.netafim_config ? prev.ir_integration.netafim_config.tw_controller_name  : "",
        netafimControllerDisplayName: prev && prev.ir_integration.netafim_config ? prev.ir_integration.netafim_config.tw_controller_display_name  : "",
        valves: prev && prev.valves ? prev.valves : [],
        dosingChannels: prev && prev.dosing_channels ? prev.dosing_channels : [],
    }
    const [hiddenDefaultValues, setHiddenDefaultValues] = useState(emptyHiddenDefaultValues)
    const [netafimData, setNetafimData] = useState({
        status: 1,
        v: null,
        error: null
    })

    const [manNumValves, setManNumValves] = useState(prev && prev.ir_integration.name === "manual" ? prev.valves.length : 0)
    const [manNumDosings, setManNumDosings] = useState(prev && prev.ir_integration.name === "manual" ? prev.dosing_channels.length : 0)

    const [saveResponse, setSaveResponse] = useState({
        status: 1,
        error: null,
    })

    const onSystemChange = (ev) => {
        const tgt = ev.target
        for(let i = 0; i< tgt.childElementCount; i++) {
            if (tgt[i].value === "unknown" && tgt[i].selected) {
                //const netafimForm = document.getElementById("integrationLabel")
                //netafimForm.classList.add("nodisplay")
                activateSubmitButton(manNumValves, manNumDosings)
                return
            }
            if(tgt[i].value === "netafim_nmc" && tgt[i].selected) {
                const netafimForm = document.getElementById("integrationLabel")
                netafimForm.classList.remove("nodisplay")
                activateSubmitButton(manNumValves, manNumDosings)
            }
        }
    }

    const onIntegrationChange = (ev) => {
        const tgt = ev.target
        const netafimForm = document.getElementById("netafimForm")
        const netafimController = document.getElementById("netafimFarmControllerLabel")

        for(let i = 0; i<tgt.childElementCount; i++) {
            if(tgt[i].value === "manual" && tgt[i].selected) {  
                removeDynamicElts()      
                netafimForm.classList.add("nodisplay")
                if (netafimController) {
                    netafimController.classList.add("nodisplay")
                }
                if (prev && prev.ir_integration.name === "netafim_cloud") {
                    clearManualInputs()
                    document.getElementById("manValves").classList.toggle("nodisplay")
                    document.getElementById("manDosingChannels").classList.toggle("nodisplay")
                    activateSubmitButton(0, 0)
                } else {
                    document.getElementById("manValves").classList.toggle("nodisplay")
                    document.getElementById("manDosingChannels").classList.toggle("nodisplay")
                    activateSubmitButton(manNumValves, manNumDosings)
                }
            }
            if (tgt[i].value === "netafim_cloud" && tgt[i].selected) {
                if (netafimData.v !== null) {
                    netafimController.classList.remove("nodisplay")
                    if (hiddenDefaultValues.netafimControllerName !== "") {
                        const key = hiddenDefaultValues.netafimFarmDisplayName + " - " + hiddenDefaultValues.netafimControllerDisplayName
                        const freshTarget = netafimData.v[key]
                        if (freshTarget) {
                            appendDynamicElts(freshTarget.valves, freshTarget.dosing_channels)
                        }
                    }
                } else {
                    netafimForm.classList.remove("nodisplay")
                }
                document.getElementById("manValves").classList.toggle("nodisplay")
                document.getElementById("manDosingChannels").classList.toggle("nodisplay")
                document.getElementById("systemSubmitLine").classList.add("nodisplay")
            }
        }
    }

    const defaultSystem = (prev)
        ? props.availableOptions.ir_systems.filter(s => s.name === prev.ir_system.name)[0]
        : props.availableOptions.ir_systems.filter(s => s.name === "unknown")[0]
    const defaultIntegration = (prev)
        ? props.availableOptions.ir_integrations.filter(s => s.name === prev.ir_integration.name)[0]
        : props.availableOptions.ir_integrations.filter(s => s.name === "manual")[0]

    const onTermsChanged = (ev) => {
        const button = document.getElementById("nmcThingworxSubmitButton")
        button.disabled = ev.target.checked ? false : true
    }

    const checkNetafim = (ev) => {
        ev.preventDefault()
        setNetafimData({
            status: 0,
            v: null,
            error: null,
        })
        const nmcurl = document.getElementsByName("nmcThingworxUrl")[0].value
        const nmcuser = document.getElementsByName("nmcThingworxUser")[0].value
        const nmcpass = document.getElementsByName("nmcThingworxPassword")[0].value
        setHiddenDefaultValues({
            netafimUrl: nmcurl, 
            netafimUser: nmcuser, 
            netafimPass: nmcpass,
            netafimFarmName: hiddenDefaultValues.netafimFarmName,
            netafimFarmDisplayName: hiddenDefaultValues.netafimFarmDisplayName,
            netafimControllerName: hiddenDefaultValues.netafimControllerName,
            netafimControllerDisplayName: hiddenDefaultValues.netafimControllerDisplayName,
        })
        props.backend.NMCFetchDetails(onNetafimCloudResponse, nmcurl, nmcuser, nmcpass)
    }

    const onNetafimCloudResponse = (data, error) => {
        if (error) {
            setHiddenDefaultValues(emptyHiddenDefaultValues)
            setNetafimData({
                status: 1,
                v: null,
                error: error,
            })
        } else {
            setNetafimData({
                status: 1,
                v: data,
                error: undefined,
            })
            // we don't need the form anymore
            document.getElementById("netafimForm").classList.add("nodisplay")
            // show the save button now
            const submitButton = document.getElementById("systemSubmitLine")
            submitButton.classList.remove("nodisplay")
            submitButton.firstChild.disabled = false

            // see if we have the controller which was previously used
            if (hiddenDefaultValues.netafimControllerName !== "") {
                const freshTarget = data[hiddenDefaultValues.netafimFarmDisplayName + " - " + hiddenDefaultValues.netafimControllerDisplayName]
                if (freshTarget) {
                    appendDynamicElts(freshTarget.valves, freshTarget.dosing_channels)
                }
            }
        }
    }

    const onNetafimControllerChange = (ev) => {
        removeDynamicElts()
        const submitButton = document.getElementById("systemSubmitLine")
        if (ev.target.value === "0") {
            submitButton.firstChild.disabled = true
        } else {
            const controllerInfo = netafimData.v[ev.target.value]
            appendDynamicElts(controllerInfo.valves, controllerInfo.dosing_channels)
            submitButton.classList.remove("nodisplay")
            submitButton.firstChild.disabled = false
        }
    }

    const onSubmit = (ev) => {
        ev.preventDefault()
        let fd = new FormData(ev.target)

        let system = {name: fd.get("system")}
        let integration = {name: fd.get("integration"), netafim_config: {}}
        let valves = []
        let dosing_channels = []
        if (fd.get("integration") === "netafim_cloud") {
            const controllerInfo = netafimData.v[fd.get("netafimFarmController")]
            integration["netafim_config"] = {
                tw_farm_id: controllerInfo.farm_name,
                tw_farm_name: controllerInfo.farm_name,
                tw_farm_display_name: controllerInfo.farm_display_name,
                tw_controller_name: controllerInfo.controller_name,
                tw_controller_display_name: controllerInfo.controller_display_name,
                tw_url: fd.get("netafimUrl"),
                tw_username: fd.get("netafimUser"),
                tw_password: fd.get("netafimPass"),
            }
            const valvesInputs = document.getElementById("valvesInputs");
            const dosingChannelsInputs = document.getElementById("dosingChannelsInputs");
            for (let child = valvesInputs.firstChild; child !== null; child = child.nextSibling) {
                if (child.nodeName !== "DIV") { // skip the form header
                    continue;
                }
                const labelNameItem = child.firstChild
                const labelCapacityItem = labelNameItem.nextSibling
                const nameInput = labelNameItem.lastChild
                const capacityInput = labelCapacityItem.lastChild
                valves.push({valve_index: nameInput.id.split("valve_name_")[1], dripper_capacity: parseFloat(capacityInput.value), name: nameInput.value})
            }
            for (let child = dosingChannelsInputs.firstChild; child !== null; child = child.nextSibling) {
                if (child.nodeName !== "LABEL") { // skip the form header
                    continue;
                }
                const input = child.lastChild;
                dosing_channels.push({channel_index: input.id.split("dosing_")[1], name: input.value})
            }
        } else {
            const manValves = document.getElementById("manValvesInputs")
            const manDosings = document.getElementById("manDosingChannelsInputs")

            for( let child = manValves.firstChild; child !== null; child = child.nextSibling) {
                const labelNameItem = child.firstChild
                const labelCapacityItem = labelNameItem.nextSibling
                const nameInput = labelNameItem.lastChild
                const capacityInput = labelCapacityItem.lastChild
                valves.push({valve_index: nameInput.id.split("mvalve_name_")[1], dripper_capacity: parseFloat(capacityInput.value), name: nameInput.value})
            }
            for (let child = manDosings.firstChild; child !== null; child = child.nextSibling) {
                const input = child.lastChild;
                dosing_channels.push({channel_index: input.id.split("mdosing_channel_")[1], name: input.value})
            }
        }
        setSaveResponse({status: 0, error: null})
        props.backend.IrrigationSystemSave(onSaveResponse, {
            ir_system: system,
            ir_integration: integration,
            valves: valves,
            dosing_channels: dosing_channels,
        })
    }

    const onSaveResponse = (_, error) => {
        if (error) {
            setSaveResponse({
                status: 1,
                error: error,
            })
        } else {
            setSaveResponse({
                status: 2,
                error: null,
            })
        }
    }

    const appendFormHeader = (target, title) => {
        const ul = document.createElement("ul")
        ul.classList.add("header")
        ul.appendChild(document.createElement("li"))

        const titleitem = document.createElement("li")
        titleitem.style.marginTop = "10px";
        titleitem.appendChild(document.createTextNode(title))
        ul.appendChild(titleitem)
        target.appendChild(ul)
    }

    const appendDynamicElts = (valves, dosingChannels) => {
        const dynElts = document.getElementById("dynamicelts");

        let valvesInputs = document.getElementById("valvesInputs")
        if (!valvesInputs) {
            valvesInputs = document.createElement("div")
            valvesInputs.id="valvesInputs"
            dynElts.appendChild(valvesInputs)
        }

        let dosingInputs = document.getElementById("dosingChannelsInputs")
        if (!dosingInputs) {
            dosingInputs = document.createElement("div")
            dosingInputs.id = "dosingChannelsInputs"
            dynElts.appendChild(dosingInputs)
        }
        
        appendFormHeader(valvesInputs, props.T("VALVES"))
        for (let i=0;i<valves.length;i++) {
            const v = valves[i];
            const eltID = v.index;

            let eltName = "valve_name_" + eltID
            let capName = "valve_dcap_" + eltID
            let target = document.createElement("div")
            target.key = "valvesetup_" + eltID
            target.className="mergedLine"

            let nameLabel = document.createElement("label")
            nameLabel.htmlFor = eltName
            nameLabel.appendChild(document.createTextNode(props.T("NameOfValve")+ " " + eltID + ":"))
            let nameInput = document.createElement("input");
            nameInput.type = "text";
            nameInput.classList.add("textinput")
            nameInput.name = eltName;
            nameInput.id = eltName;
            nameInput.placeholder = "Enter valve name"
            nameInput.defaultValue = eltID;
            nameLabel.appendChild(nameInput);

            let capLabel = document.createElement("label")
            capLabel.htmlFor = capName
            capLabel.appendChild(document.createTextNode(props.T("DripperCapacity")+": (l/hour)"))
            let capInput = document.createElement("input");
            capInput.type = "text";
            capInput.classList.add("textinput")
            capInput.name = capName;
            capInput.id = capName;
            capInput.placeholder = "Enter dripper capacity"
            capInput.defaultValue = 4;
            capLabel.appendChild(capInput)

            target.appendChild(nameLabel);
            target.appendChild(capLabel);
            valvesInputs.appendChild(target)
        }
        appendFormHeader(dosingInputs, props.T("DOSINGCHANNELS"))
        for (let i=0;i<dosingChannels.length;i++){
            const dc = dosingChannels[i];
            const eltID = "dosing_" + dc.index;

            let label = document.createElement("label")
            label.htmlFor = eltID

            label.appendChild(document.createTextNode(props.T("NameOfChannel") + " " + dc.index + ":"))

            let input = document.createElement("input");
            input.type = "text";
            input.classList.add("textinput")
            input.name = eltID;
            input.id = eltID;            
            input.defaultValue = dc.display_name;
            input.placeholder = "Enter channel name"

            label.appendChild(input);
            dosingInputs.appendChild(label);
        }
    }

    const removeDynamicElts = () => {
        const target = document.getElementById("dynamicelts")
        while (target.hasChildNodes()) {
            target.removeChild(target.lastChild);
        }
    }

    const generateValveInput = (ev) => {
        ev.preventDefault()
        let eltID = manNumValves
        eltID++
        setManNumValves(eltID)


        let eltName = "mvalve_name_" + eltID
        let capName = "mvalve_dcap_" + eltID
        let mainTarget = document.getElementById("manValvesInputs")
        let target = document.createElement("div")
        target.key = "valvesetup_" + eltID
        target.className="mergedLine"

        let nameLabel = document.createElement("label")
        nameLabel.htmlFor = eltName
        nameLabel.appendChild(document.createTextNode(props.T("NameOfValve")+" " + eltID +":"))
        let nameInput = document.createElement("input");
        nameInput.type = "text";
        nameInput.classList.add("textinput")
        nameInput.name = eltName;
        nameInput.id = eltName;
        nameInput.placeholder = "Enter valve name"
        nameInput.defaultValue = eltID;
        nameLabel.appendChild(nameInput);

        let capLabel = document.createElement("label")
        capLabel.htmlFor = capName
        capLabel.appendChild(document.createTextNode(props.T("DripperCapacity")+": (l/hour)"))
        let capInput = document.createElement("input");
        capInput.type = "text";
        capInput.classList.add("textinput")
        capInput.name = capName;
        capInput.id = capName;
        capInput.placeholder = "Enter dripper capacity"
        capInput.defaultValue = 4;
        capLabel.appendChild(capInput)

        target.appendChild(nameLabel);
        target.appendChild(capLabel);
        mainTarget.appendChild(target)
        activateSubmitButton(eltID, manNumDosings)
    }

    const generateDosingInput = (ev) => {
        ev.preventDefault()
        let eltID = manNumDosings
        eltID++
        setManNumDosings(eltID)

        let eltName = "mdosing_channel_" + eltID
        let target = document.getElementById("manDosingChannelsInputs")
        let label = document.createElement("label")
        label.htmlFor = eltName
        label.appendChild(document.createTextNode(props.T("NameOfChannel")+ " " + eltID +":"))

        let input = document.createElement("input");
        input.type = "text";
        input.classList.add("textinput")
        input.name = eltName;
        input.id = eltName;
        input.defaultValue = "Dosing " + eltID;
        input.placeholder = "Enter channel name"

        label.appendChild(input);
        target.appendChild(label);
        activateSubmitButton(manNumValves, eltID)
    }

    const removeValveInput = (ev) => {
        ev.preventDefault()
        const tgt = document.getElementById("manValvesInputs")
        if (tgt.hasChildNodes()) {
            let n = manNumValves
            n--
            setManNumValves(n)
            tgt.removeChild(tgt.lastChild)
            activateSubmitButton(n, manNumDosings)
        }
    }

    const removeDosingChannelInput = (ev) => {
        ev.preventDefault()
        const tgt = document.getElementById("manDosingChannelsInputs")
        if (tgt.hasChildNodes()) {
            let n = manNumDosings
            n--
            setManNumDosings(n)
            tgt.removeChild(tgt.lastChild)
            activateSubmitButton(manNumValves, n)
        }
    }

    const clearManualInputs = () => {
        let tgt = document.getElementById("manValvesInputs")
        while(tgt.hasChildNodes()) {
            tgt.removeChild(tgt.lastChild)
        }
        tgt = document.getElementById("manDosingChannelsInputs")
        while(tgt.hasChildNodes()) {
            tgt.removeChild(tgt.lastChild)
        }
        setManNumValves(0)
        setManNumDosings(0)
        activateSubmitButton(0, 0)
    }

    const activateSubmitButton =(x, y) => {
        const tgt = document.getElementById("systemSubmitLine")
        if (x > 0 && y > 0) {
            tgt.classList.remove("nodisplay")
            tgt.firstChild.disabled = false
        } else {
            tgt.firstChild.disabled = true
        }
    }

    const onInputChange = (ev) => {
        if (manNumValves > 0 && manNumDosings > 0) {
            activateSubmitButton(1, 1)
        }
    }
    return (
        <>
        { saveResponse.status === 2 && <Redirect to="/config/irrigation" /> }
        {netafimData.status === 0 || saveResponse.status === 0 ? <div className="loadingOverlay"></div> : ""}
        <div className="inputForm">
            <FormHeader  {...props} title={prev ? props.T("EditIrrigationSystem") : props.T("NewIrrigationSystem")} withoutBackButton={prev ? false : true} />
            <form id="systemConfigForm" method='POST' onSubmit={onSubmit}>
            <label htmlFor="system">{props.T("SystemType")}:
                <select name="system" defaultValue={defaultSystem.name} onChange={onSystemChange}>
                    {props.availableOptions.ir_systems.map((typeItem) => { return (
                        <option key={typeItem.id} id={typeItem.id} name={typeItem.name} value={typeItem.name}>{typeItem.display_name}</option>
                    )})}
                </select>
            </label>
            <label id="integrationLabel" htmlFor="integration">{props.T("Integration")}:
                <select name="integration" defaultValue={defaultIntegration.name} onChange={onIntegrationChange} >
                    {props.availableOptions.ir_integrations.map((integrationItem) => { return (
                        <option key={integrationItem.id} id={integrationItem.id} name={integrationItem.name} value={integrationItem.name}>{integrationItem.display_name}</option>
                    )})}
                </select>
            </label>
            
            <input type="hidden" id="netafimUrl" name="netafimUrl" value={hiddenDefaultValues.netafimUrl} />
            <input type="hidden" id="netafimUser" name="netafimUser" value={hiddenDefaultValues.netafimUser} />
            <input type="hidden" id="netafimPass" name="netafimPass" value={hiddenDefaultValues.netafimPass} />
            <input type="hidden" id="netafimFarmName" name="netafimFarmName" value={hiddenDefaultValues.netafimFarmName} />
            <input type="hidden" id="netafimFarmDisplayName" name="netafimFarmDisplayName" value={hiddenDefaultValues.netafimFarmDisplayName} />
            <input type="hidden" id="netafimControllerName" name="netafimControllerName" value={hiddenDefaultValues.netafimControllerName} />
            <input type="hidden" id="netafimControllerDisplayName" name="netafimControllerDisplayName" value={hiddenDefaultValues.netafimControllerDisplayName} />

            {netafimData.status === 1 && netafimData.v !== null && 
                    <label id="netafimFarmControllerLabel" htmlFor="netafimFarmController" >{props.T("NetafimController")}:
                    <select name="netafimFarmController" onChange={onNetafimControllerChange} defaultValue={hiddenDefaultValues.netafimControllerName !== "" ? hiddenDefaultValues.netafimFarmDisplayName + " - " + hiddenDefaultValues.netafimControllerDisplayName : "0"}>
                        <option name="0" value="0" key="0">---</option>
                        {Object.keys(netafimData.v).map(key => { return (
                            <option key={key} id={key} name={key} value={key}>{key}</option>
                        )})}
                    </select>
                    </label>
            }
            <div id="dynamicelts">
                <div id="valvesInputs"></div>
                <div id="dosingChannelsInputs"></div>
            </div>
            <div style={{marginTop: "20px"}} id="manValves" className={prev && prev.ir_integration.name === "netafim_cloud" ? "nodisplay" : ""}>
                <ul className="header">
                    <li style={{cursor: "pointer"}} onClick={generateValveInput}><BsPlusCircle size="20"/> &nbsp; {props.T("AddValve")}</li>
                    <li style={{cursor: "pointer"}} onClick={removeValveInput}><BsDashCircle size="20" color="darkred"/></li>
                </ul>
                <div id="manValvesInputs">
                {prev && prev.valves && prev.valves.map(valve => { return (
                    <div key={"valvesetup_" + valve.valve_index} className="mergedLine">
                        <label htmlFor={"mvalve_name_"+valve.valve_index}>
                            {props.T("NameOfValve")} {valve.valve_index}:
                            <input type="text" className="textinput" id={"mvalve_name_"+valve.valve_index} 
                                defaultValue={valve.name} placeholder="Enter valve name"
                                onChange={onInputChange} />
                        </label>
                        <label htmlFor={"mvalve_dcap_"+valve.valve_index}>
                            {props.T("DripperCapacity")}: (l/hour)
                            <input type="text" className="textinput" id={"mvalve_dcap_"+valve.valve_index} 
                                defaultValue={valve.dripper_capacity} placeholder="Enter dripper capacity"
                                onChange={onInputChange} />
                        </label>
                    </div>
                )})}
                </div>
            </div>
            <div style={{marginTop: "20px"}} id="manDosingChannels" className={prev && prev.ir_integration.name === "netafim_cloud" ? "nodisplay" : ""}>
                <ul className="header">
                    <li style={{cursor: "pointer"}} onClick={generateDosingInput}><BsPlusCircle size="20"/> &nbsp; {props.T("AddDosingChannel")}</li>
                    <li style={{cursor: "pointer"}} onClick={removeDosingChannelInput}><BsDashCircle size="20" color="darkred"/></li>
                </ul>
                <div id="manDosingChannelsInputs">
                {prev && prev.dosing_channels && prev.dosing_channels.map(dc => { return (
                    <label key={"mdosing_channel_"+dc.channel_index} htmlFor={"mdosing_channel_"+dc.channel_index}>
                        {props.T("NameOfChannel")} {dc.channel_index}:
                        <input type="text" className="textinput" id={"mdosing_channel_"+dc.channel_index} 
                                defaultValue={dc.name} placeholder="Enter channel name" 
                                onChange={onInputChange} />
                    </label>
                )})}
                </div>
            </div>
            <div id="systemSubmitLine" className="submitLine nodisplay">
                <button id="systemSubmitButton" disabled={true} className="button" name="submitButton" onClick={onSubmit}>{props.T("Save")}</button>
            </div>
            </form>

            <form id="netafimForm" className={prev && prev.ir_integration.name === "netafim_cloud" ? "" : "nodisplay"} method='POST' onSubmit={checkNetafim}>
            <label id="nmcThingworxUrlLabel" htmlFor="nmcThingworxUrl">{props.T("NetafimCloudURL")}:
                <input type="text" defaultValue="https://netafim-prod.cloud.thingworx.com" className="textinput" name="nmcThingworxUrl" placeholder="Enter NetafimCloud URL"/>
            </label>
            <label id="nmcThingworxUserLabel" htmlFor="nmcThingworxUser">{props.T("NetafimCloudUser")}:
                <input type="text" className="textinput" defaultValue={hiddenDefaultValues.netafimUser} name="nmcThingworxUser" placeholder="Enter Netafim Cloud Username"/>
            </label>
            <label id="nmcThingworxPasswordLabel" htmlFor="nmcThingworxPassword">{props.T("NetafimCloudPass")}:
                <input type="password" className="textinput" defaultValue={hiddenDefaultValues.netafimPass} name="nmcThingworxPassword" placeholder="Enter Netafim Cloud Password"/>
            </label>
            <label id="nmcThingworxTermsAcceptanceLabel" htmlFor="nmcThingworxTermsAcceptance">{props.T("TermsOfUse")}:
                <div className="checkboxWithText">
                <input type="checkbox" name="nmcThingworxTermsAcceptance" value="termsAccepted" onChange={onTermsChanged}/>
                <p>{props.T("ReadAndUnderstood")}</p>
                </div>
            </label>
            <div className="submitLine error">
                {netafimData !== null && netafimData.status === 1 && netafimData.error ? "ERROR: " + netafimData.error : ""}
            </div>
            <div id="nmcThingworxContinueLine" className="submitLine">
                <button id="nmcThingworxSubmitButton" disabled={true} className="button" name="submitButton" onClick={checkNetafim}>{props.T("Continue")}</button>
            </div>
            </form>
        </div>
        </>
    )
}

export default ConfigWizardForm
