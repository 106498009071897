import React from 'react'

const InitLoading = () => {
    return (
        <div className="fullpagecontainerdark">
            <div className="blankcompany">
                <div>
                    <h4>FarmCC</h4>
                </div>
                <div>
                    <h6 align="right">by URIT</h6>
                </div>
            </div>
        </div>
        
    )
}

export default InitLoading
