import React from 'react'
import { useState } from 'react'
import Nav from '../../../components/Nav'
import FormHeader from '../../../components/FormHeader';
import { Redirect } from 'react-router-dom';

const LawnCutNew = (props) => {
    const [errorMsg, setErrorMsg] = useState({status: 1, error:null})
    
    const onSubmit = (ev) => {
        ev.preventDefault()
        var fd = new FormData(ev.target)
        const start_row = fd.get("start_row")
        if (!start_row || start_row === "") {
            document.getElementById("start_row").focus()
            return
        }
        const end_row = fd.get("end_row")
        if (!end_row || end_row === "") {
            document.getElementById("end_row").focus()
            return
        }

        const data = {
            start_row: start_row,
            end_row: end_row,
        }
        setErrorMsg({status: 0, error:null})
        props.backend.LawnCutNew(onSaveResponse, data)
    }

    const onSaveResponse = (data, error) => {
        if (error) {
            setErrorMsg({status: 1, error:error})
        } else {
            setErrorMsg({status: 2, error:null})
        }
    }

    return (
        <>
        <Nav isMain={false} withLink="/activities" subtitle="Activity" {...props} />
        {(errorMsg.status === 0) && <div className="loadingOverlay"></div>}
        {(errorMsg.status === 2) && <Redirect to="/activities/lawncut" />}
        {errorMsg.status === 1 &&
            <div className="inputForm">
            <FormHeader {...props} title={props.T("LawnCutNew")}/>
            <form method="POST" onSubmit={onSubmit}>
                <label htmlFor="start_row">{props.T("StartRow")}:
                <input id="start_row" type="text" className="textinput" name="start_row" placeholder="Enter start row" />
                </label>
                <label htmlFor="end_row">{props.T("EndRow")}:
                <input id="end_row" type="text" className="textinput" name="end_row" placeholder="Enter end row" />
                </label>
                {errorMsg.status === 1 && errorMsg.error && <div className="submitLine error">{errorMsg.error}</div>}
                <div className="submitLine">
                <input type="submit" className="button" name="submitForm" value={props.T("Save")} />
                </div>
            </form>
            </div>
        }
        </>
    )
}

export default LawnCutNew
