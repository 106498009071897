import React from 'react'
import FilterMenu from '../FilterMenu'

const ProgramsFilterMenu = (props) => {
    const menuItems = [
        {
            key: "irrigation", 
            title: "Irrigation",
            selected: (props.filter === "irrigation"),
            path: "/irrigation/programs/irrigation"
        },
        {
            key: "wruntime", 
            title: "programsRuntime",
            selected: (props.filter === "wruntime"),
            path: "/irrigation/programs/wruntime"
        },
        {
            key: "dosing", 
            title: "Dosing",
            selected: (props.filter === "dosing"),
            path: "/irrigation/programs/dosing"
        }
    ];

    return (
        <FilterMenu T={props.T} items={menuItems} />
    )
}

export default ProgramsFilterMenu
