import React from 'react'
import TopMenu from '../TopMenu'

const IrrigationMenu = (props) => {
    const menuItems = [
        {
            key: "overview",
            path: "/irrigation/overview",
            title: "Overview",
            selected: props.section === "overview",
        }
    ]
    const programsAllowedFor = ["FarmOwner", "FarmManager", "Agronomist", "IrrigationOperator"]
    if (programsAllowedFor.includes(props.user.role)) {
        menuItems.push(
            {
                key: "programs",
                path: "/irrigation/programs",
                title: "Programs",
                selected: props.section === "programs",
            }
        )
    }
    return (
        <TopMenu {...props} menuItems={menuItems} />
    )
}

export default IrrigationMenu
