import React, { useState, useEffect } from 'react'
import Nav from '../../../components/Nav'
import FormHeader from '../../../components/FormHeader';
import { Redirect } from 'react-router-dom';

const UserEdit = (props) => {
    const [user, setUser] = useState({status: 0, v:null, error: null})
    const [resp, setResp] = useState({status: 2, error: null})
    const [roles, setRoles] = useState({status: 0, v:[], error:null})

    useEffect(() => {
        const onRolesListResponse = (data, error) => {
            if (error) {
                setRoles({status: 1, v:[], error: error})
            } else {
                setRoles({status: 1, v:data.roles.filter(r => r.name !== "FarmOwner"), error:null})
            }
        }
        const onUserResponse = (data, error) => {
            if (error) {
                setUser({status: 2, v:null, error:null})
            } else {
                setUser({status: 1, v:data, error:null})
            }
        }
        props.backend.GetAttributes(onRolesListResponse, {attributes:["roles"]})
        props.backend.FarmUserGet(onUserResponse, props.match.params.uid)
    }, [props.backend, props.match.params.uid])

    const onSubmit = (ev) => {
        ev.preventDefault()
        let fd = new FormData(ev.target)

        setResp({status: 0, error: null})
        props.backend.FarmUsersNew(onResponse, {
            user: {
                email: user.v.user.email,
            },
            role: {
                 name: fd.get("role")
            },
            active: fd.get("active") === "yes" ? true : false
        })
    }

    const onResponse = (_, error) => {
        if (error) {
            setResp({status: 1, error: error})
        } else {
            setResp({status: 1, error: null})
        }
    }

    const renderForm = () => {
        return (
            <div className="inputForm">
            <FormHeader {...props} title={props.T("EditUser")}/>
            <form method='POST' onSubmit={onSubmit}>
                <label>{user.v.user.first_name} {user.v.user.last_name} {"<"+user.v.user.email+">"}</label>
                <label htmlFor="role">{props.T("Role")}:
                <select name="role" defaultValue={user.v.role.name}>
                    {roles.v !== null && roles.v.map(role => {return (
                        <option key={role.id} name={role.name} value={role.name}>{role.display_name}</option>
                    )})}
                </select>
                </label>
                <label htmlFor="role">{props.T("Active")}:
                <select name="active" defaultValue={user.v.active ? "yes" : "no"}>
                    <option name="yes" value="yes">{props.T("YES")}</option>
                    <option name="no" value="no">{props.T("NO")}</option>
                </select>
                </label>
                <div className="submitLine error">
                {resp.status === 1 && resp.error ? "ERROR: " + resp.error : ""}
                </div>
                <div className="submitLine">
                <input type="submit" className="button" name="submitForm" value={props.T("Save")} />
                </div>
            </form>
            </div>
        )
    }
    return (
        <>
        <Nav isMain={false} subtitle="Config" {...props} />
        {resp.status === 0 || roles.status === 0 || user.status === 0 ? <div className="loadingOverlay"></div> : ""}
        {
            (
                (roles.status === 1 && roles.error) || 
                (user.status === 1 && user.error) || 
                (user.status === 2) ||
                (resp.status === 1 && !resp.error)
            ) && <Redirect to="/config/users" />
        }
        {user.status === 1 && renderForm()}
        </>
    )
}

export default UserEdit
