import PropTypes from 'prop-types';
import Backend from '../Backend'

export default class AuthService {
    constructor(BackendAPI) {
        this.BackendAPI = BackendAPI
    }

    AuthUser(user, pass, resultConsumer) {
        return this.BackendAPI.Authenticate(user, pass, resultConsumer)
    }

    Logout(onComplete) {
        return this.BackendAPI.Logout(onComplete)
    }

}
AuthService.defaultProps = {
    backendAPI: PropTypes.instanceOf(Backend)
}
