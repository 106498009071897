import React from 'react'
import { useState, useEffect} from 'react'
import Nav from '../../../components/Nav'
import FormHeader from '../../../components/FormHeader';
import { Redirect } from 'react-router-dom';
import { BsDashCircle, BsPlusCircle } from 'react-icons/bs';

const IrrigationProgramNew = (props) => {
    const withPrev = props.match.params.pid ? true : false;
    const [prev, setPrev] = useState({status: withPrev ? 0 : 1, v:null, error:null})
    const [stages, setStages] = useState({v:[], changed:false})
    const [errorMsg, setErrorMsg] = useState({status: 1, error:null})
    const [runtimePrograms, setRuntimePrograms] = useState({status: 0, v:[], error:null})
    const [dosingPrograms, setDosingPrograms] = useState({status: 0, v:[], error:null})
    const [valves, setValves] = useState({status: 0, v:[], error:null})

    useEffect(() => {
        const onRuntimeProgramListResponse = (data, error) => {
            if (error) {
                setRuntimePrograms({status: 1, v:[], error: error})
            } else {
                setRuntimePrograms({status: 1, v:data.runtime_programs ? data.runtime_programs : [], error: null})
            }
        }
        const onDosingProgramListResponse = (data, error) => {
            if (error) {
                setDosingPrograms({status: 1, v:[], error: error})
            } else {
                setDosingPrograms({status: 1, v:data.dosing_programs ? data.dosing_programs : [], error: null})
            }
        }
        const onAttributesResponse = (data, error) => {
            if (error) {
                setValves({status: 1, v:[], error: error})
            } else {
                setValves({status: 1, v:data.valves ? data.valves : [], error: null})
            }
        }
        const onGetPrevResponse = (data, error) => {
            if (error) {
                setPrev({status: 2, v:null, error:error})
            } else {
                setStages({v:data.stages ? data.stages : [], changed:false})
                setPrev({status: 1, v:data, error:null})
            }
        }
        if (withPrev) {
            props.backend.IrrigationProgramGet(onGetPrevResponse, props.match.params.pid)
        }
        
        props.backend.RuntimeProgramList(onRuntimeProgramListResponse)
        props.backend.DosingProgramList(onDosingProgramListResponse)
        props.backend.GetAttributes(onAttributesResponse, {attributes: ["valves"]})

    }, [props.backend, props.match.params.pid, withPrev])
    
    const onSubmit = (ev) => {
        ev.preventDefault()
        var fd = new FormData(ev.target)
        const name = fd.get("name")
        if (name === "") {
            document.getElementById("name").focus()
            return
        }
        const data = {
            current_stage_id: (withPrev && !stages.changed ? prev.v.current_stage_id : ""),
            name: name,
            stages: stages.v,            
        }
        setErrorMsg({status: 0, error:null})
        if (withPrev) {
            props.backend.IrrigationProgramUpdate(onSaveResponse, prev.v.id, data)
        } else {
            props.backend.IrrigationProgramNew(onSaveResponse, data)
        }
    }

    const onSaveResponse = (data, error) => {
        if (error) {
            setErrorMsg({status: 1, error:error})
        } else {
            setRuntimePrograms({status: 2, v:runtimePrograms.v, error:null})
        }
    }

    const removeStage = (stage) => {
        var items = stages.v.filter(s => s !== stage)
        setStages({v:items, changed:true})
    }

    const addStage = (data) => {
        var items = stages.v
        items.push(data)
        setStages({v:items, changed:true})
    }

    const switchStageMainInput = (ev) => {
        ev.preventDefault()
        document.getElementById("mainInput").classList.toggle("nodisplay")
        document.getElementById("stageInput").classList.toggle("nodisplay")
    }

    const StageInput = ({appendStage}) => {
        const add = (ev) => {
            ev.preventDefault()
            const valveID = document.getElementById("valve").value
            const runtimeID = document.getElementById("runtime_program").value
            const dosingID = document.getElementById("dosing_program").value
            appendStage({
                valve: valveID === "-" ? {} : valves.v.find(v => v.id === valveID),
                runtime_program: runtimeID === "-" ? null : runtimePrograms.v.find(rp => rp.id === runtimeID),
                dosing_program: dosingID === "-" ? null : dosingPrograms.v.find(dp => dp.id === dosingID),
            })
            switchStageMainInput(ev)
        }
        return (
            <div id="stageInput" className="inputForm nodisplay">
                <FormHeader {...props} title={props.T("NewStage")} linkTo={switchStageMainInput} />
                <label htmlFor="valve">{props.T("Valve")}:
                <select name="valve" id="valve">
                    {valves.v.map(v => {return (
                        <option key={v.id} id={v.id} value={v.id}>{v.valve_index}</option>
                    )})}
                </select>
                </label>
                <label htmlFor="runtime_program">{props.T("RuntimeProgram")}:
                <select name="runtime_program" id="runtime_program">
                    {runtimePrograms.v.map(rp => {return (
                        <option key={rp.id} id={rp.id} value={rp.id}>{rp.name}</option>
                    )})}
                </select>
                </label>
                <label htmlFor="dosing_program">{props.T("DosingProgram")}:
                <select name="dosing_program" id="dosing_program" defaultValue="empty">
                    <option id="empty" name="empty" value="_">---</option>
                    {dosingPrograms.v.map(dp => {return (
                        <option key={dp.id} id={dp.id} value={dp.id}>{dp.name}</option>
                    )})}
                </select>
                </label>
                <div className="submitLine">
                <button onClick={add}>{props.T("Add")}</button>
                </div>
            </div>
        )
    }

    return (
        <>
        <Nav isMain={false} subtitle="Irrigation" {...props} />
        {(runtimePrograms.status === 0 || dosingPrograms.status === 0 || prev.status === 0 || errorMsg === 0) && <div className="loadingOverlay"></div>}
        {(runtimePrograms.status === 2 || dosingPrograms.status === 2 || prev.status === 2) && <Redirect to="/irrigation/programs/irrigation" />}
        {runtimePrograms.status === 1 && dosingPrograms.status === 1 && prev.status === 1 &&
            <>
            <StageInput appendStage={addStage} />
            <div id="mainInput" className="inputForm">
            <FormHeader {...props} title={withPrev ? props.T("EditIrrigationProgram") : props.T("NewIrrigationProgram")} />
            <form method="POST" onSubmit={onSubmit}>
                <label htmlFor="name">{props.T("Name")}:
                <input id="name" type="text" className="textinput" name="name" defaultValue={withPrev ? prev.v.name : ""} placeholder="Enter program name" />
                </label>
                <br />
                <div style={{marginTop: "20px"}} id="stagesView">
                    <ul className="header">
                        <li style={{cursor: "pointer"}} onClick={switchStageMainInput}><BsPlusCircle size="20"/> &nbsp; {props.T("AddStage")}</li>
                    </ul>
                </div>
                <div className="hscrollcontainer">
                <table style={{width:"100%"}} id="inputStages">
                    <thead>
                        <tr>
                            <td align="center" width="60px">#</td>
                            <td align="center" width="60px">{props.T("Valve")}</td>
                            <td align="center" width="120px">{props.T("RuntimeProgram")}</td>
                            <td align="center" width="120px">{props.T("DosingProgram")}</td>
                            <td align="center" width="25px">&nbsp;</td>
                        </tr>
                    </thead>
                    <tbody>
                    {stages.v.map((s, i) => {return (
                        <tr key={i+1 + "" + (s.valve.id) + "_" + (s.runtime_program ? s.runtime_program.id : "_") + "_" + (s.dosing_program ? s.dosing_program.id : "_")}>
                            <td align="center">{i+1}</td>
                            <td align="center">{s.valve ? s.valve.name : "-"}</td>
                            <td align="center">{s.runtime_program ? s.runtime_program.name : "-"}</td>
                            <td align="center">{s.dosing_program ? s.dosing_program.name : "-"}</td>
                            <td align="center" onClick={(ev) => {removeStage(s)}}><BsDashCircle size="16" color="darkred"/></td>
                        </tr>
                    )})}
                    </tbody>
                </table>
                </div>


                {errorMsg.status === 1 && errorMsg.error && <div className="submitLine error">{errorMsg.error}</div>}
                <div className="submitLine">
                <input type="submit" className="button" name="submitForm" value={props.T("Save")} />
                </div>
            </form>
            </div>
            </>
        }
        </>
    )
}

export default IrrigationProgramNew
