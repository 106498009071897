import React, { useState, useEffect } from 'react'
import Nav from '../../../components/Nav'
import FormHeader from '../../../components/FormHeader';
import { Redirect } from 'react-router-dom';

const UserNew = (props) => {
    const [resp, setResp] = useState({status: 2, error: null})
    const [roles, setRoles] = useState({status: 0, v:[], error:null})

    useEffect(() => {
        const onRolesListResponse = (data, error) => {
            if (error) {
                setRoles({status: 1, v:[], error: error})
            } else {
                setRoles({status: 1, v:data.roles.filter(r => r.name !== "FarmOwner"), error:null})
            }
        }
        props.backend.GetAttributes(onRolesListResponse, {attributes:["roles"]})
    }, [props.backend])

    const onSubmit = (ev) => {
        ev.preventDefault()
        let fd = new FormData(ev.target)
        const firstName = fd.get("firstName")
        if (!firstName || firstName === "") {
            ev.target.firstName.focus()
            return
        }

        const lastName = fd.get("lastName")
        if (!lastName || lastName === "") {
            ev.target.lastName.focus()
            return
        }

        const email = fd.get("email")
        if (!email || email === "") {
            ev.target.email.focus()
            return
        }

        setResp({status: 0, error: null})
        props.backend.FarmUsersNew(onResponse, {
            user: {
                email: email,
                first_name: firstName,
                last_name: lastName,
            },
            role: {
                 name: fd.get("role")
            }
        })
    }

    const onResponse = (_, error) => {
        if (error) {
            setResp({status: 1, error: error})
        } else {
            setResp({status: 1, error: null})
        }
    }

    return (
        <>
        <Nav isMain={false} subtitle="Config" {...props} />
        {resp.status === 0 || roles.status === 0 ? <div className="loadingOverlay"></div> : ""}
        {resp.status === 1 && !resp.error && <Redirect to="/config/users" />}
        <div className="inputForm">
            <FormHeader {...props} title={props.T("NewUser")}/>
            <form method='POST' onSubmit={onSubmit}>
                <label htmlFor="firstName">{props.T("FirstName")}:
                <input type="text" className="textinput" name="firstName" placeholder="Enter first name"/>
                </label>
                <label htmlFor="lastName">{props.T("LastName")}:
                <input type="text" className="textinput" name="lastName" placeholder="Enter last name"/>
                </label>
                <label htmlFor="email">{props.T("Email")}:
                <input type="text" className="textinput" name="email" placeholder="Enter email"/>
                </label>
                <label htmlFor="role">{props.T("Role")}:
                <select name="role">
                    {roles.v !== null && roles.v.map(role => {return (
                        <option key={role.id} name={role.id} value={role.name}>{role.display_name}</option>
                    )})}
                </select>
                </label>
                <div className="submitLine error">
                {resp.status === 1 && resp.error ? "ERROR: " + resp.error : ""}
                </div>
                <div className="submitLine">
                <input type="submit" className="button" name="submitForm" value={props.T("Save")} />
                </div>
            </form>
        </div>
        </>
    )
}

export default UserNew
