import React, { useState } from 'react'
import LogoBox from './components/LogoBox';

const Login = (props) => {
    const [errorMsg, setErrorMsg] = useState({status:1, error:null})

    const goHome = (ev) => {
        ev.preventDefault()
        window.location = "/"
    }

    const toggleForgotPassword = (ev) => {
        ev.preventDefault()
        document.getElementById('login').classList.toggle('nodisplay')
        document.getElementById('forgotpassword').classList.toggle('nodisplay')
    }

    const onSubmit = (ev) => {
        ev.preventDefault()
        var fd = new FormData(ev.target)
        const email = fd.get("login_email")
        if (!email || email === "") {
            document.getElementById("login_email").focus()
            return
        }
        const password = fd.get("login_password")
        if (!password || password === "") {
            document.getElementById("login_password").focus()
            return
        }
        setErrorMsg({status:0, error:null})
        props.backend.Authenticate(email, password, onLoginResponse)
    }

    const onLoginResponse = (err) => {
        if (err) {
            setErrorMsg({status:1, error:err})
        } else {
            setErrorMsg({status:1, error:null})
            props.onLoginSuccess()
        }
    }

    const forgotpasswordSubmit = (ev) => {
        ev.preventDefault()
        var fd = new FormData(ev.target)
        const email = fd.get("fp_email")
        if (!email || email === "") {
            document.getElementById("fp_email").focus()
            return
        }
        props.backend.ResetPassword((_) => showResetPasswordMessage(), email)
    }

    const showResetPasswordMessage = () => {
        document.getElementById('forgotpassword').classList.toggle('nodisplay')
        document.getElementById('resetmsg').classList.toggle('nodisplay')
    }

    return (
        <>
        <header>
            <div className="logo" onClick={goHome}>
                <LogoBox />
            </div>
        </header>
        
        {errorMsg.status === 0 && <div className="loadingOverlay"></div>}     
        <div className="loginPageWrapper">
        <div id="login" className="loginform" >
            <form id="form_login" name="form_login" method='POST' onSubmit={onSubmit}>
                <label htmlFor="login_email">Email:
                <input type="text" className="textinput" id="login_email" name="login_email" defaultValue="" placeholder="Enter your email"/>
                </label>
                <label htmlFor="login_password">Password:
                <input type="password" className="textinput" id="login_password" name="login_password" placeholder="Enter your password"/>
                </label>
                {errorMsg.status === 1 && errorMsg.error && <div className="submitLine error">{errorMsg.error}</div>}

                <input type="submit" className="button" name="submitLogin" value="Login" />
            </form>
            <p style={{float:"right", cursor:"pointer", color:"blue"}} onClick={toggleForgotPassword}>Forgot password?</p>
        </div>

        <div id="forgotpassword" className="loginform nodisplay">
            <form id="form_fp" name="form_fp" method="POST" onSubmit={forgotpasswordSubmit}>
            <label htmlFor="fp_email">Email:
                <input type="text" className="textinput" id="fp_email" name="fp_email" placeholder="Enter your email"/>
            </label>
            <input type="submit" className="button" name="submitLogin" value="Request reset" />               
            </form>
            <p style={{float:"right", cursor:"pointer", color:"blue"}} onClick={toggleForgotPassword}>Go back</p>
        </div>

        <div id="resetmsg" className="loginform nodisplay">
            <br /><br />
            <p style={{color:"blue"}}>Password reset instructions have been sent to your email.</p><br />
            <a href="/"> - Go back to login -</a>
        </div>
        </div>
        </>
    )
}

export default Login
