import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { BsPencil } from 'react-icons/bs'

import Nav from '../../../components/Nav'
import ActionButton from '../../../components/ActionButton';
import InventoryMenu from '../../../components/inventory/InventoryMenu';

function ProductList(props) {
    const pencilIcon = <BsPencil size="14"/>
    const [products, setProducts] = useState({status: 0, v:[], error:null})

    useEffect(() => {
        const onListResponse = (data, error) => {
            if (error) {
                setProducts({status: 1, v:[], error: error})
            } else {
                setProducts({status: 1, v:data.products ? data.products : [], error: null})
            }
        }
        props.backend.ProductList(onListResponse)
    }, [props.backend])
    
    return (
        <>
        <Nav isMain={false} subtitle="Inventory" {...props} />
        <InventoryMenu {...props} section="catalog" />
        <div className="filterMenu">
            <Link to="/inventory/products/new">
                <ActionButton T={props.T} title="New" icon={pencilIcon} />
            </Link>
        </div>
        {products.status === 0 && <div className="loadingOverlay"></div>}
        {products.status === 1 && 
            <div className="content">
                <p className="contentSubtitle">{props.T("ProductList")}</p><br />
                <div className="hscrollcontainer">
                <table id="productList">
                    <thead>
                    <tr>
                        <td align="center" >{props.T("Name")}</td>
                        <td align="center" >{props.T("Category")}</td>
                        <td align="center" >{props.T("Package")}</td>
                        <td align="center" >&nbsp;</td>
                    </tr>
                    </thead>
                    <tbody>
                    {products.v.length === 0 &&
                        <tr><td colSpan="4">{props.T("NoProductsFound")}</td></tr>
                    }
                    {products.v.length > 0 &&
                        products.v.map(p => {return (
                            <tr key={p.id}>
                            <td align="left">
                                <Link to={"/inventory/products/"+p.id}>
                                <div style={{width:"100%", height:"100%"}}>{p.name}</div>
                                </Link>
                            </td>
                            <td align="center">{p.category.display_name}</td>
                            <td align="center">{props.T("pt_"+p.package_type.name)}</td>
                            <td align="center">
                                <Link to={"/inventory/products/edit/"+p.id}>
                                    <ActionButton T={props.T} title="Edit" icon={pencilIcon} />
                                </Link>
                            </td>
                            </tr>
                        )})
                    }
                    </tbody>
                </table>
                </div>
            </div>
            }
        </>
    )
}

export default ProductList
