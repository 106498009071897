import React, { useState, useEffect } from 'react'
import Nav from '../../../components/Nav'
import FormHeader from '../../../components/FormHeader';
import { Redirect } from 'react-router-dom';

const DosingProgramNew = (props) => {
    const withPrev = props.match.params.pid ? true : false

    const [channels, setChannels] = useState({status:0, v:[], error:null})
    const [prev, setPrev] = useState({status:props.match.params.pid ? 0 : 1, v:null, error:null})
    const [errorMsg, setErrorMsg] = useState({status:1, error:null})

    useEffect(() => {
        const onAttributeListResponse = (data, error) => {
            if (!error) {
                setChannels({status:1, v:data.dosing_channels, error:null})
            } else {
                setChannels({status:2, v:[], error:error})
            }
        }
        const onLoadPrevResponse = (data, error) => {
            if (error) {
                setPrev({status: 1, v:null, error: error})
            } else {
                setPrev({status: 1, v:data, error:null})
            }
        }
        if (withPrev) {
            props.backend.DosingProgramGet(onLoadPrevResponse, props.match.params.pid)
        }
        props.backend.GetAttributes(onAttributeListResponse, {attributes:["dosing_channels"]})
    }, [props.backend, props.match.params.pid, withPrev])

    const onSubmit = (ev) => {
        ev.preventDefault()
        const fd = new FormData(ev.target)
        const name = fd.get("name")
        const target_ec = Number(fd.get("targetEC").replace(/,/g, '.'))
        const target_ph = Number(fd.get("targetPH").replace(/,/g, '.'))
        if (!name || name === "") {
            document.getElementById("name").focus()
            return
        }
        if (isNaN(target_ec)) {
            document.getElementById("targetEC").focus()
            return
        }
        if(isNaN(target_ph)) {
            document.getElementById("targetPH").focus()
            return
        }
        var data = {
            name: name, target_ec: target_ec, target_ph: target_ph,
            channel_configs: [],
        }
        for(let i=0; i<channels.v.length; i++) {
            const c = channels.v[i];
            const v = Number(fd.get("chan_"+c.id).replace(/,/g, '.'))
            if (isNaN(v)) {
                document.getElementById("chan_"+c.id).focus()
                return
            }
            data.channel_configs.push({dosing_channel: {id: c.id}, quantity: v})
        }
        setErrorMsg({status: 0, error:null})
        if (withPrev) {
            props.backend.DosingProgramUpdate(onSaveResponse, prev.v.id, data)
        } else {
            props.backend.DosingProgramNew(onSaveResponse, data)
        }
    }

    const onSaveResponse = (_, error) => {
        if(error) {
            setErrorMsg({status:1, error: error})
        } else {
            setChannels({status: 2, v:channels.v, error:null})
        }
    }

    const getPrevValue = (ch_id) => {
        const pc = prev.v.channel_configs.find(cfg => cfg.dosing_channel.id === ch_id)
        return pc ? pc.quantity : 0
    }

    return (
        <>
        <Nav isMain={false} subtitle="Irrigation" {...props} />
        {(channels.status === 0 || prev.status === 0 || errorMsg.status === 0) && <div className="loadingOverlay"></div>}
        {(channels.status === 2 || prev.status === 2) && <Redirect to="/irrigation/programs/dosing" />}
        {channels.status === 1 && prev.status === 1 &&
        <div className="inputForm">
        <FormHeader {...props} title={withPrev ? props.T("EditDosingProgram") : props.T("NewDosingProgram")}/>
        <form method='POST' onSubmit={onSubmit}>
            <label htmlFor="name">{props.T("Name")}:
            <input type="text" className="textinput" id="name" name="name" defaultValue={withPrev ? prev.v.name : ""} placeholder="Enter program name" />
            </label>
            <label htmlFor="before">{props.T("TargetEC")}:
            <input type="number" min="0" max="10" step="0.01" className="textinput" id="targetEC" name="targetEC" defaultValue={withPrev ? prev.v.target_ec : "0"} placeholder="Enter Target EC" />
            </label>
            <label htmlFor="total">{props.T("TargetPH")}:
            <input type="number" min="0" max="20" step="0.01" className="textinput" id="targetPH" name="targetPH" defaultValue={withPrev ? prev.v.target_ph : "0"} placeholder="Enter Target PH" />
            </label>
            {channels.v.map((chan) => {
                const id = "chan_"+chan.id
                return (
                    <label key={id} htmlFor={id}>{chan.name}: (liters/m3)
                    <input id={id} type="number" min="0" max="500" step="1" className="textinput" 
                        defaultValue={withPrev ? getPrevValue(chan.id) : "0"}
                        name={id} placeholder="Enter value (liters/m3)" />
                    </label>
                )
            })}
            {errorMsg.status === 1 && errorMsg.error && <div className="submitLine error">{errorMsg.error}</div>}
            <div className="submitLine">
            <input type="submit" className="button" name="submitForm" value={props.T("Save")} />
            </div>
        </form>
        </div>
        }
        </>
    )
}

export default DosingProgramNew
