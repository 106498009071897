import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { BsPencil } from 'react-icons/bs'

import Nav from '../../../components/Nav'
import IrrigationMenu from '../../../components/irrigation/IrrigationMenu';
import OverviewFilterMenu from '../../../components/irrigation/OverviewFilterMenu'
import ActionButton from '../../../components/ActionButton';
import { FaAngleDown } from 'react-icons/fa';

function ValveCycleList(props) {
    const pencilIcon = <BsPencil size="14"/>

    const [cycles, setCycles] = useState({status: 0, v:[], error:null})
    const [dosingChannels, setDosingChannels] = useState({status: 0, v:[]})

    useEffect(() => {
        const onListResponse = (data, error) => {
            if (error) {
                setCycles({status: 1, v:[], error: error})
            } else {
                if (data.valve_cycles && data.valve_cycles.length > 0) {
                    const rec = data.valve_cycles[0]
                    if (rec.valve_cycles && rec.valve_cycles.length > 0) {
                        const vc = rec.valve_cycles[0]
                        const dosing = JSON.parse(vc.dosing_info)
                        setDosingChannels({status: 1, v:dosing})
                    } else {
                        setDosingChannels({status: 1, v:[]})
                    }
                } else {
                    setDosingChannels({status: 1, v:[]})
                }
                setCycles({status: 1, v:data.valve_cycles ? data.valve_cycles : [], error: null})
            }
        }
        props.backend.ValveCycleList(onListResponse)
    }, [props.backend])

    const collapseTable = (ev, id) => {
        const target = document.getElementById(id)
        target.classList.toggle("nodisplay")
    }
    
    const formatDate = (v) => {
        const dateOptions = { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric' };
        const d = new Date(v);
        return d.toLocaleDateString(props.T("LOCALE"), dateOptions)
    }

    const formatTime = (v) => {
        const dateOptions = { hour12: false, hour: '2-digit', minute: '2-digit' };
        const d = new Date(v)
        return d.toLocaleTimeString(props.T("LOCALE"), dateOptions)
    }

    const printAmount = (method, before, total, after) => {
        return (
            <>
                &nbsp;&nbsp;&nbsp;&nbsp;{props.T(method)}: <br />
                {props.T("B")}: {before} <br />
                {props.T("T")}: {total} <br />
                {props.T("A")}: {after}
            </>
        )
    }

    const printQuantity = (dosing) => {
        if (!dosing || dosing.length === 0) {
            return ( "--" )
        }
        return (
            dosing.map(dc => {return (
                <p key={"value_"+dc.channel_index}>
                    {props.T("Chan")}#{dc.channel_index}: {dc.quantity > 0 ? dc.quantity : "0"}
                    <br />
                </p>
            )})
        )
    }

    return (
        <>
        <Nav isMain={false} subtitle="Irrigation" {...props} />
        <IrrigationMenu {...props} section="overview" />
        <div className="filterMenu">
            <OverviewFilterMenu {...props} filter="valvecycle" />
            {props.user.integration_name !== "netafim_cloud" &&
                <Link to="/irrigation/overview/valvecycle/new" >
                <ActionButton T={props.T} title="New" icon={pencilIcon} />
                </Link>
            }
        </div>
        {(cycles.status === 0 || dosingChannels.status === 0) && <div className="loadingOverlay"></div>}
        {cycles.status === 1 && dosingChannels.status === 1 && 
            <div className="content">
            <p className="contentSubtitle">{props.T("DailyIrrigationLog")}</p><br />
            {cycles.v.map(byDate => { return (
                <div key={byDate.date} style={{ marginBottom: "20px"}} className="hscrollcontainer">
                <p onClick={(ev) => collapseTable(ev, byDate.date)}>
                    <FaAngleDown />&nbsp;{props.T("IrrigationCyclesFrom")} <b>{formatDate(byDate.date)}</b>&nbsp;<FaAngleDown /></p>
                <table id={byDate.date}>
                    <thead>
                    <tr>
                        <td align="center" >{props.T("User")}</td> 
                        <td align="center" >{props.T("Time")}</td>
                        <td align="center" >{props.T("V")}</td>
                        <td align="center" >{props.T("Amount")}<br />( {props.T("bta")} )</td>
                        <td align="center" >{props.T("Dosing")}<br />( l/m3 )</td>
                        <td align="center" >{props.T("Target")}</td>
                        {props.user.integration_name === "netafim_cloud" &&
                            <>
                            <td align="center" >{props.T("Flow")}</td>
                            <td align="center" >{props.T("RealEC")}</td>
                            <td align="center" >{props.T("RealPH")}</td>
                            </>
                        }
                    </tr>
                    </thead>
                    <tbody>
                        {byDate.valve_cycles.map(vc => {
                            const dosing = JSON.parse(vc.dosing_info)
                            return (
                                <tr key={vc.id}>
                                    <td align="left">{vc.user.first_name}</td>
                                    <td align="center">{formatTime(vc.created_at)}</td>
                                    <td align="center">{vc.valve.valve_index}</td>
                                    <td align="left">{printAmount(vc.runtime_method, vc.runtime_before, vc.runtime_total, vc.runtime_after)}</td>
                                    <td align="left">{printQuantity(dosing)}</td>
                                    <td padding="5px" align="left">EC: {vc.target_ec}<br />pH: {vc.target_ph}</td>
                                    
                                    {props.user.integration_name === "netafim_cloud" &&
                                    <>
                                    <td align="center">{vc.real_ec}</td>
                                    <td align="center">{vc.real_ph}</td>
                                    <td align="center">{vc.flow}</td>
                                    </>
                                    }
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            )})}
            </div>
        }
        </>
    )
}

export default ValveCycleList

