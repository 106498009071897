import React from 'react'
import { useState, useEffect} from 'react'
import Nav from '../../../components/Nav'
import FormHeader from '../../../components/FormHeader';
import { Redirect } from 'react-router-dom';

const SupplierNew = (props) => {
    const withPrev = props.match.params.sid ? true : false;
    const [prev, setPrev] = useState({status: withPrev ? 0 : 1, v:null, error:null})
    const [errorMsg, setErrorMsg] = useState({status: 1, error:null})

    useEffect(() => {
        const onGetPrevResponse = (data, error) => {
            if (error) {
                setPrev({status: 2, v:null, error:error})
            } else {
                setPrev({status: 1, v:data, error:null})
            }
        }
        if (withPrev) {
            props.backend.SupplierGet(onGetPrevResponse, props.match.params.sid)
        }
    }, [props.backend, props.match.params.sid, withPrev])
    
    const onSubmit = (ev) => {
        ev.preventDefault()
        var fd = new FormData(ev.target)
        const name = fd.get("name")
        if (!name || name === "") {
            document.getElementById("name").focus()
            return
        }
        const data = {
            name: name,
        }
        setErrorMsg({status: 0, error:null})
        if (withPrev) {
            props.backend.SupplierUpdate(onSaveResponse, prev.v.id, data)
        } else {
            props.backend.SupplierNew(onSaveResponse, data)
        }
    }

    const onSaveResponse = (data, error) => {
        if (error) {
            setErrorMsg({status: 1, error:error})
        } else {
            setPrev({status: 2, v:prev.v, error:null})
        }
    }

    return (
        <>
        <Nav isMain={false} subtitle="Inventory" {...props} />
        {(prev.status === 0 || errorMsg.status === 0) && <div className="loadingOverlay"></div>}
        {(prev.status === 2) && <Redirect to="/inventory/suppliers" />}
        {prev.status === 1 && errorMsg.status !== 0 &&
            <div className="inputForm">
            <FormHeader {...props} title={withPrev ? props.T("EditSupplier") : props.T("NewSupplier")}/>
            <form method="POST" onSubmit={onSubmit}>
                <label htmlFor="name">{props.T("Name")}:
                <input id="name" type="text" className="textinput" name="name" defaultValue={withPrev ? prev.v.name : ""} placeholder="Enter supplier name" />
                </label>
                {errorMsg.status === 1 && errorMsg.error && <div className="submitLine error">{errorMsg.error}</div>}
                <div className="submitLine">
                <input type="submit" className="button" name="submitForm" value={props.T("Save")} />
                </div>
            </form>
            </div>
        }
        </>
    )
}

export default SupplierNew
