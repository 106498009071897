import React, { useEffect, useState } from 'react'

import Nav from '../../../components/Nav'
import InventoryMenu from '../../../components/inventory/InventoryMenu';
import { Link } from 'react-router-dom';

function InventoryList(props) {
    const [inventory, setInventory] = useState({status: 0, v:[], error:null})

    useEffect(() => {
        const onListResponse = (data, error) => {
            if (error) {
                setInventory({status: 1, v:[], error: error})
            } else {
                setInventory({status: 1, v:data.inventory ? data.inventory : [], error: null})
            }
        }
        props.backend.InventoryList(onListResponse)
    }, [props.backend])
    
    return (
        <>
        <Nav isMain={false} subtitle="Inventory" {...props} />
        <InventoryMenu {...props} section="overview" />
        {inventory.status === 0 && <div className="loadingOverlay"></div>}
        {inventory.status === 1 && 
            <div className="content">
                <p className="contentSubtitle">{props.T("InventoryState")}</p><br />
                <div className="hscrollcontainer sticky">
                <table id="productList">
                    <thead>
                    <tr>
                        <th align="center" >{props.T("Name")}</th>
                        <th align="center" >{props.T("Remaining")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {inventory.v.length === 0 &&
                        <tr><td colSpan="2">{props.T("NoArticlesFound")}</td></tr>
                    }
                    {inventory.v.length > 0 &&
                        inventory.v.map(ip => {return (
                            <tr key={ip.id}>
                            <td align="left">
                                <Link to={"/inventory/products/"+ip.product.id}>
                                <div style={{width:"100%", height:"100%"}}>{ip.product.name}</div>
                                </Link>
                            </td>
                            <td align="right" width="80px">{Number(ip.remaining_qty).toFixed(2)} ({ip.product.package_type.unit})</td>
                            </tr>
                        )})
                    }
                    </tbody>
                </table>
                </div>
            </div>
            }
        </>
    )
}

export default InventoryList
