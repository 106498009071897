import React from 'react'
import { useState} from 'react'
import FormHeader from './FormHeader';

const InventoryItemInput = (props) => {
    const [selectedProduct, setSelectedProduct] = useState(props.inventory && props.inventory.length > 0 ? props.inventory[0] : null)
    const updateSelectedProduct = (ev) => {
        ev.preventDefault()
        const sb = ev.target;
        const inventoryID = [].filter.call(sb.options, opt => opt.selected)[0].value
        const p = props.inventory.filter(ip => ip.id === inventoryID)[0]
        setSelectedProduct(p)
    }

    const add = (ev) => {
        ev.preventDefault()
        const p = selectedProduct
        const quantityChange = Number(document.getElementById("quantity_change").value.replace(/,/g, '.')).toFixed(2)
        if (isNaN(quantityChange) || quantityChange <= 0) {
            document.getElementById("quantity_change").focus()
            return
        }
        props.appendInventoryItem({
            inventory: {id: p.id},
            quantity_change: quantityChange,
            product: p.product,
        })
        props.switchInventoryMainInput(ev)
    }

    return (
        <div id="inventoryInput" className="inputForm nodisplay">
            <FormHeader {...props} title={props.T("SelectFromInventory")} linkTo={props.switchInventoryMainInput} />
            <label htmlFor="inventory">{props.T("Articles")}:
            <select name="inventory" id="inventory" onChange={updateSelectedProduct}>
                {props.inventory.map(ip => {return (
                    <option key={ip.id} id={ip.id} value={ip.id}>{ip.product.name} - {props.T("pt_"+ip.product.package_type.name)}</option>
                )})}
            </select>
            </label>
            <label htmlFor="quantity_change">{props.T("Quantity")} ({selectedProduct ? selectedProduct.product.package_type.unit : props.inventory[0].product.package_type.unit}):
            <input id="quantity_change" type="number" defaultValue="0" className="textinput" name="quantity_change" placeholder="Enter quantity" />
            </label>
            <div className="submitLine">
            <button onClick={add}>{props.T("Add")}</button>
            </div>
        </div>
    )
}

export default InventoryItemInput;