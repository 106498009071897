import React, { useEffect, useState } from 'react'

import Nav from '../../components/Nav'
import { Link } from 'react-router-dom';

function InventoryReport(props) {
    const [totals, setTotals] = useState({status: 0, v:[], error:null})

    useEffect(() => {
        const onListResponse = (data, error) => {
            if (error) {
                setTotals({status: 1, v:[], error: error})
            } else {
                setTotals({status: 1, v:data ? data : {}, error: null})
            }
        }
        props.backend.InventoryTotalByYear(onListResponse)
    }, [props.backend])
    
    return (
        <>
        <Nav isMain={false} subtitle="Inventory" {...props} withLink="/reports"/>
        {totals.status === 0 && <div className="loadingOverlay"></div>}
        {totals.status === 1 && 
            <div className="content">
                <p className="contentSubtitle">{props.T("InventoryTotalSpent")}</p><br />
                <div className="hscrollcontainer sticky">
                <table id="productList">
                    <thead>
                    <tr>
                        <td align="center" >{props.T("Product")}</td>
                        {totals.v.years.map(year => {return (
                            <td key={"year_column_"+year} align="center" >{year}</td>
                        )})}
                    </tr>
                    </thead>
                    <tbody>
                    {totals.v.per_product_list.length === 0 &&
                        <tr><td colSpan="2">{props.T("NoArticlesFound")}</td></tr>
                    }
                    {totals.v.per_product_list.length > 0 &&
                        totals.v.per_product_list.map(item => {return (
                            <tr key={item.product.id}>
                                <td align="left">
                                    <Link to={"/inventory/products/"+item.product.id}>
                                    <div style={{width:"100%", height:"100%"}}>{item.product.name}</div>
                                    </Link>
                                </td>
                                {item.quantities.map((qty, idx) => {
                                    return (
                                    <td key={"qty_"+item.product.id+"_"+idx} align="right" width="80px">{Number(qty).toFixed(2)} ({item.product.package_type.unit})</td>
                                )})}
                            </tr>
                        )})
                    }
                    </tbody>
                </table>
                </div>
            </div>
            }
        </>
    )
}

export default InventoryReport
