import React, { useState, useEffect } from 'react'
import Nav from '../../components/Nav'
import ActionButton from '../../components/ActionButton'
import FarmListFilterMenu from '../../components/admin/FarmListFilterMenu'
import { Link } from 'react-router-dom'
import { BsPencil } from 'react-icons/bs'

const FarmList = (props) => {
    const pencilIcon = <BsPencil size="14"/>

    const [farms, setFarms] = useState({
        status: 0,
        v: [],
        error: null,
    })

    useEffect(() => {
        const onResponse = (data, error) => {
            if (error) {
                setFarms({status: 1, v:[], error: error})
            } else {
                setFarms({status: 1, v:data.farms ? data.farms : [], error: null})
            }
        }
        props.backend.AdminFarmList(onResponse)
    }, [props.backend])

    const disableFarm = (ev, item) => {
        ev.preventDefault()
        setFarms({status: 0, v: farms.v, error: farms.error})
        props.backend.AdminFarmSave(onDisableResponse, {
            user: item.user, 
            farm:{id: item.farm.id, name: item.farm.name, active: false}
        })
    }

    const onDisableResponse = (data, error) => {
        if (error) {
            setFarms({status: 1, v: farms.v, error: error})
        } else {
            let newFarms = farms.v.filter(f => f.farm.id !== data.farm.id)
            setFarms({status: 1, v: newFarms, error: null})
        }
    }

    return (
        <>
        <Nav isMain={true} subtitle="Admin" {...props} />
        {farms.status === 0 ? <div className="loadingOverlay"></div> : ""}
        <div className="filterMenu">
            <FarmListFilterMenu {...props} filter="active" />
            <Link to="/admin/farms/new">
                <ActionButton T={props.T} title="New" icon={pencilIcon} />
            </Link>
        </div>
        <div className="content">
            <div className="listWrapper">

            <div className="listRow">
                <ul className="listItemInfo">
                    <li style={{ width: "150px", textAlign: "center"}}><b>Farm</b></li>
                    <li style={{ width: "80px", textAlign: "center" }}><b>Status</b></li>
                    <li style={{ width: "100%", textAlign: "center" }}><b>Owner</b></li>
                </ul>
                <ul className="listItemActions">
                    &nbsp;
                </ul>
            </div>
            {farms.status === 1 && farms.v.map(item => {
                return (
                    <div key={item.farm.id} className="listRow">
                        <ul className="listItemInfo">
                        <li style={{ width: "150px"}}>{item.farm.name}</li>
                        <li style={{ width: "80px" }}>{item.farm.active ? "active" : "not active"}</li>
                        <li style={{ width: "100%" }}>
                            {item.user.first_name}&nbsp;{item.user.last_name}<br />
                            {item.user.email} <br />
                            {item.user.invitation_code !== "" && <Link to={"/users/i/" + item.user.invitation_code}>Invite link</Link> }
                        </li>
                        </ul>
                        <ul className="listItemActions">
                        <li>
                        <Link to="/admin/farms/new" aaa="bbb">
                        <ActionButton T={props.T} title="Disable" icon={pencilIcon} onClick={(ev) => disableFarm(ev, item)}/>
                        </Link>
                        </li>
                        </ul>
                    </div>
                )
            })}
            </div>
        </div>
        </>
    )
}

export default FarmList
