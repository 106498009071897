import React, { useState, useEffect } from 'react'
import Nav from '../../../components/Nav'
import { Link } from 'react-router-dom'
import ActionButton from '../../../components/ActionButton'
import { BsPencil } from 'react-icons/bs'
import FarmConfigMenu from '../../../components/farmconfig/FarmConfigMenu'

const DrainsList = (props) => {
    const pencilIcon = <BsPencil size="14"/>

    const [drains, setDrains] = useState({status: 0, v:null, error:null})
    useEffect(() => {
        const onListResponse = (data, error) => {
            if (error) {
                console.log(error)
                setDrains({status:1, v:[], error:error})
            } else {
                setDrains({status:1, v:data.drains ? data.drains : [], error:null})
            }
        }
        props.backend.FarmDrainsList(onListResponse)
    }, [props.backend])

    const renderPage = (dList) => {
        return (
            <div className="hscrollcontainer">
            <table>
                <thead>
                    <tr>
                        <td align="center" >{props.T("Valve")}</td> 
                        <td align="center" >{props.T("Row")}</td>
                        <td align="center" >{props.T("Location")}</td>
                        <td align="center" >{props.T("Active")}</td>
                        <td align="center" >&nbsp;</td>
                    </tr>
                </thead>
                <tbody>
                    {dList.length === 0 && <tr colSpan="6"><td>{props.T("NoDrainsFound")}</td></tr>}
                    {dList.map(d => {return (
                        <tr key={d.id}>
                            <td align="center">{d.valve.name}</td>
                            <td align="center">{d.row}</td>
                            <td align="center">{d.loc}</td>
                            <td align="center">{d.active ? props.T("YES") : props.T("NO")}</td>
                            <td align="center"><Link to={"/config/drains/edit/"+ d.id}>{props.T("Edit")}</Link></td>
                        </tr>
                    )})}
                </tbody>
            </table>
            </div>
        )
    }

    return (
        <>
        <Nav isMain={false} subtitle="Config" {...props} />
        {drains.status === 0 ? <div className="loadingOverlay"></div> : ""}
        <FarmConfigMenu {...props} section="drains" />
        <div className="filterMenu">
            <Link to="/config/drains/new">
                <ActionButton T={props.T} title="New" icon={pencilIcon} />
            </Link>
        </div>
        <div className="content">
            {drains.status === 1 && renderPage(drains.v)}
        </div>
        
        </>
    )
}

export default DrainsList
