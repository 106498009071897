import React from 'react'
import { Link } from 'react-router-dom'
import FilterButton from './FilterButton'

const FilterMenu = ({T, items, actionItem}) => {
    return (
        <ul>
            {items.map((item) => {
                return (
                    <li key={item.key}>
                        <Link to={item.path}>
                        <FilterButton T={T} title={item.title} icon={item.icon} isSelected={item.selected}/>
                        </Link>
                    </li>
                )
            })}
        </ul>
    )
}

export default FilterMenu
