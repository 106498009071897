import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom';

import DripMeasureList from './pages/irrigation/overview/DripMeasureList'
import DripMeasureNew from './pages/irrigation/overview/DripMeasureNew'
import ValveCycleNew from './pages/irrigation/overview/ValveCycleNew'

import FormulaList from './pages/formulas/FormulaList'
import FormulaNew from './pages/formulas/FormulaNew'
import FormulaView from './pages/formulas/FormulaView'

import Reports from './pages/Reports'
import AccountSettings from './pages/users/AccountSettings'
import MainMenu from './pages/MainMenu'
import IrrigationProgramList from './pages/irrigation/programs/IrrigationProgramList'
import IrrigationProgramNew from './pages/irrigation/programs/IrrigationProgramNew'
import WaterRuntimeProgramList from './pages/irrigation/programs/WaterRuntimeProgramList'
import WaterRuntimeProgramNew from './pages/irrigation/programs/WaterRuntimeProgramNew'
import DosingProgramList from './pages/irrigation/programs/DosingProgramList'
import DosingProgramNew from './pages/irrigation/programs/DosingProgramNew';
import ConfigHome from './pages/farmconfig/ConfigHome';
import IrrigationSystemList from './pages/farmconfig/irrigation/IrrigationSystemList';
import IrrigationSystemNew from './pages/farmconfig/irrigation/IrrigationSystemNew';
import FarmList from './pages/admin/FarmList';
import FarmNew from './pages/admin/FarmNew';
import UserList from './pages/farmconfig/users/UserList';
import UserNew from './pages/farmconfig/users/UserNew';
import UserEdit from './pages/farmconfig/users/UserEdit';
import DrainsList from './pages/farmconfig/drains/DrainsList';
import DrainsNew from './pages/farmconfig/drains/DrainsNew';
import DrainsEdit from './pages/farmconfig/drains/DrainsEdit';
import ProductList from './pages/inventory/products/ProductList';
import ProductNew from './pages/inventory/products/ProductNew';
import InventoryList from './pages/inventory/overview/InventoryList';
import SupplierList from './pages/inventory/suppliers/SupplierList';
import SupplierNew from './pages/inventory/suppliers/SupplierNew';
import DeliveryList from './pages/inventory/deliveries/DeliveryList';
import DeliveryNew from './pages/inventory/deliveries/DeliveryNew';
import ActivityMenu from './pages/activities/ActivityMenu';
import TracktorRefillList from './pages/activities/tracktorrefill/TracktorRefillList';
import TracktorRefillNew from './pages/activities/tracktorrefill/TracktorRefillNew';
import TankRefillList from './pages/activities/tankrefill/TankRefillList';
import TankRefillNew from './pages/activities/tankrefill/TankRefillNew';
import SprayingNew from './pages/activities/spraying/SprayingNew';
import SprayingList from './pages/activities/spraying/SprayingList';
import LawnCutList from './pages/activities/lawncuts/LawnCutList';
import LawnCutNew from './pages/activities/lawncuts/LawnCutNew';
import FieldWorkList from './pages/activities/fieldworks/FieldWorkList';
import FieldWorkNew from './pages/activities/fieldworks/FieldWorkNew';
import MaintenanceList from './pages/activities/maintenance/MaintenanceList';
import MaintenanceNew from './pages/activities/maintenance/MaintenanceNew';
import ProductView from './pages/inventory/products/ProductView';
import CycleListRouter from './pages/irrigation/overview/CycleListRouter';
import LayoutRowList from './pages/farmconfig/layoutrows/LayoutRowList';
import LayoutRowNew from './pages/farmconfig/layoutrows/LayoutRowNew';
import LayoutRowEdit from './pages/farmconfig/layoutrows/LayoutRowEdit';
import InventoryReport from './pages/reports/InventoryReport';
import MeasurementsReport from './pages/reports/MeasurementsReport';

const Landing = (props) => {
    const myProps = props;

    const adminRoutes = () => {
        return (
            <Switch>
            <Route exact path="/admin/farms/new" render={(props) => (<FarmNew {...props} {...myProps}/>)} />
            <Route exact path="/admin/farms/:filter" render={(props) => (<FarmList {...props} {...myProps}/>)} />
            <Route exact path="/admin/farms" render={(props) => (<FarmList {...props} {...myProps}/>)} />
            <Route exact path="/accountsettings" render={(props) => (<AccountSettings {...props} {...myProps} />)} />
            <Route path="/"><Redirect to="/admin/farms" /></Route>
            </Switch>
        )
    }

    const userRoutes = () => {
        return (
            <Switch>
            <Route exact path="/irrigation/overview/drip/new" render={(props) => (<DripMeasureNew {...props} {...myProps}/>)} />
            <Route exact path="/irrigation/overview/drip" render={(props) => (<DripMeasureList {...props} {...myProps}/>)} />
            <Route exact path="/irrigation/overview/valvecycle/new" render={(props) => (<ValveCycleNew {...props} {...myProps}/>)} />
            <Route exact path="/irrigation/overview/valvecycle" render={(props) => (<CycleListRouter {...props} {...myProps}/>)} />
            <Route path="/irrigation/overview" render={(props) => (<DripMeasureList {...props} {...myProps}/>)} />
            <Route exact path="/irrigation/programs/irrigation/new/:pid" render={(props) => (<IrrigationProgramNew {...props} {...myProps}/>)} />
            <Route exact path="/irrigation/programs/irrigation/new" render={(props) => (<IrrigationProgramNew {...props} {...myProps}/>)} />
            <Route exact path="/irrigation/programs/irrigation" render={(props) => (<IrrigationProgramList {...props} {...myProps}/>)} />
            <Route exact path="/irrigation/programs/wruntime/new/:pid" render={(props) => (<WaterRuntimeProgramNew {...props} {...myProps}/>)} />
            <Route exact path="/irrigation/programs/wruntime/new" render={(props) => (<WaterRuntimeProgramNew {...props} {...myProps}/>)} />
            <Route exact path="/irrigation/programs/wruntime" render={(props) => (<WaterRuntimeProgramList {...props} {...myProps}/>)} />
            <Route exact path="/irrigation/programs/dosing/new/:pid" render={(props) => (<DosingProgramNew {...props} {...myProps}/>)} />
            <Route exact path="/irrigation/programs/dosing/new" render={(props) => (<DosingProgramNew {...props} {...myProps}/>)} />
            <Route exact path="/irrigation/programs/dosing" render={(props) => (<DosingProgramList {...props} {...myProps}/>)} />
            <Route path="/irrigation/programs" render={(props) => (<IrrigationProgramList {...props} {...myProps}/>)} />
            <Route path="/irrigation" render={(props) => (<DripMeasureList {...props} {...myProps} />)} />
    
            <Route exact path="/config/irrigation/edit/:sid" render={(props) => (<IrrigationSystemNew {...props} {...myProps} />)} />
            <Route exact path="/config/irrigation/new" render={(props) => (<IrrigationSystemNew {...props} {...myProps} />)} />
            <Route exact path="/config/irrigation" render={(props) => (<IrrigationSystemList {...props} {...myProps} />)} />
            <Route exact path="/config/drains/edit/:did" render={(props) => (<DrainsEdit {...props} {...myProps} />)} />
            <Route exact path="/config/drains/new" render={(props) => (<DrainsNew {...props} {...myProps} />)} />
            <Route exact path="/config/drains" render={(props) => (<DrainsList {...props} {...myProps} />)} />
            <Route exact path="/config/users/new" render={(props) => (<UserNew {...props} {...myProps} />)} />
            <Route exact path="/config/users/edit/:uid" render={(props) => (<UserEdit {...props} {...myProps} />)} />
            <Route exact path="/config/users" render={(props) => (<UserList {...props} {...myProps} />)} />
            <Route exact path="/config/layoutrows/edit/:rid" render={(props) => (<LayoutRowEdit {...props} {...myProps} />)} />
            <Route exact path="/config/layoutrows/new" render={(props) => (<LayoutRowNew {...props} {...myProps} />)} />
            <Route exact path="/config/layoutrows" render={(props) => (<LayoutRowList {...props} {...myProps} />)} />
            <Route path="/config" render={(props) => (<ConfigHome {...props} {...myProps} />)} />
            
            <Route exact path="/formulas/edit/:fid" render={(props) => (<FormulaNew {...props} {...myProps}/>)} />
            <Route exact path="/formulas/new" render={(props) => (<FormulaNew {...props} {...myProps}/>)} />
            <Route exact path="/formulas/:fid" render={(props) => (<FormulaView {...props} {...myProps}/>)} />
            <Route path="/formulas" render={(props) => (<FormulaList {...props} {...myProps} />)} />

            <Route exact path="/inventory/products/edit/:pid" render={(props) => (<ProductNew {...props} {...myProps}/>)} />
            <Route exact path="/inventory/products/new" render={(props) => (<ProductNew {...props} {...myProps}/>)} />
            <Route exact path="/inventory/products/:pid" render={(props) => (<ProductView {...props} {...myProps}/>)} />
            <Route exact path="/inventory/products" render={(props) => (<ProductList {...props} {...myProps}/>)} />
            <Route exact path="/inventory/suppliers/edit/:sid" render={(props) => (<SupplierNew {...props} {...myProps}/>)} />
            <Route exact path="/inventory/suppliers/new" render={(props) => (<SupplierNew {...props} {...myProps}/>)} />
            <Route exact path="/inventory/suppliers" render={(props) => (<SupplierList {...props} {...myProps}/>)} />
            <Route exact path="/inventory/deliveries/new" render={(props) => (<DeliveryNew {...props} {...myProps}/>)} />
            <Route exact path="/inventory/deliveries" render={(props) => (<DeliveryList {...props} {...myProps}/>)} />

            <Route exact path="/inventory/overview" render={(props) => (<InventoryList {...props} {...myProps}/>)} />
            <Route path="/inventory" render={(props) => (<InventoryList {...props} {...myProps} />)} />

            <Route exact path="/activities/tracktorrefill/new" render={(props) => (<TracktorRefillNew {...props} {...myProps}/>)} />
            <Route exact path="/activities/tracktorrefill" render={(props) => (<TracktorRefillList {...props} {...myProps}/>)} />
            <Route exact path="/activities/tankrefill/new" render={(props) => (<TankRefillNew {...props} {...myProps}/>)} />
            <Route exact path="/activities/tankrefill" render={(props) => (<TankRefillList {...props} {...myProps}/>)} />
            <Route exact path="/activities/spraying/new" render={(props) => (<SprayingNew {...props} {...myProps}/>)} />
            <Route exact path="/activities/spraying" render={(props) => (<SprayingList {...props} {...myProps}/>)} />
            <Route exact path="/activities/lawncut/new" render={(props) => (<LawnCutNew {...props} {...myProps}/>)} />
            <Route exact path="/activities/lawncut" render={(props) => (<LawnCutList {...props} {...myProps}/>)} />
            <Route exact path="/activities/fieldwork/new" render={(props) => (<FieldWorkNew {...props} {...myProps}/>)} />
            <Route exact path="/activities/fieldwork" render={(props) => (<FieldWorkList {...props} {...myProps}/>)} />
            <Route exact path="/activities/maintenance/new" render={(props) => (<MaintenanceNew {...props} {...myProps}/>)} />
            <Route exact path="/activities/maintenance" render={(props) => (<MaintenanceList {...props} {...myProps}/>)} />
            <Route path="/activities" render={(props) => (<ActivityMenu {...props} {...myProps} />)} />

            <Route exact path="/reports/measurements" render={(props) => (<MeasurementsReport {...props} {...myProps} />)} />
            <Route exact path="/reports/inventory" render={(props) => (<InventoryReport {...props} {...myProps} />)} />
            <Route path="/reports" render={(props) => (<Reports {...props} {...myProps} />)} />
    
            <Route exact path="/accountsettings" render={(props) => (<AccountSettings {...props} {...myProps} />)} />
            <Route path="/home" render={(props) => (<MainMenu {...props} {...myProps} />)} />

            <Route path="/"><Redirect to="/home" /></Route>
            
            </Switch>
        )
    }
    return (
        <>
        {props.user.srole === "user" && userRoutes()}
        {props.user.srole === "admin" && adminRoutes()}
        </>
    )    
}
/*
const Landing = ({user, authService, clearUserProfile}) => {
    return (
        <Switch>
        <Route path="/irrigation/programs"><Irrigation user={user} authService={authService} clearUserProfile={clearUserProfile} section="programs"/></Route>
        <Route path="/irrigation/drain"
        <Route path="/irrigation"><Irrigation user={user} authService={authService} clearUserProfile={clearUserProfile} section="overview"/></Route>
        <Route path="/mixtures"><Mixtures user={user} authService={authService} clearUserProfile={clearUserProfile} /></Route>
        <Route path="/chat"><Chat user={user} authService={authService} clearUserProfile={clearUserProfile} /></Route>
        <Route path="/reports"><Reports user={user} authService={authService} clearUserProfile={clearUserProfile} /></Route>
        <Route path="/inventory"><Inventory user={user} authService={authService} clearUserProfile={clearUserProfile} /></Route>
        <Route path="/config"><FarmConfig user={user} authService={authService} clearUserProfile={clearUserProfile} /></Route>
        <Route path="/accountsettings"><AccountSettings user={user} authService={authService} clearUserProfile={clearUserProfile} /></Route>
        <Route path="/"><MainMenu user={user} authService={authService} clearUserProfile={clearUserProfile} /></Route>
        </Switch>
    )
}
*/

export default Landing
