import React from 'react'

import { BsScissors } from 'react-icons/bs'
import Science from '@mui/icons-material/ScienceRounded'
import { FaCogs, FaGasPump, FaTractor, FaPeopleCarry } from 'react-icons/fa'
import Nav from '../../components/Nav'
import MainListItem from '../../components/MainListItem'

const ActivityMenu = (props) => {
    
    const iconGasPump = <FaGasPump size="48px" color="purple" />
    const iconScissors = <BsScissors size="48px" color="#008899"/> 
    const iconFlask = <Science sx={{ fontSize: "48px", color:"green" }} />
    const iconTractor = <FaTractor size="48px" color="#008899" />
    const iconConfig = <FaCogs size="48px" color="gray"/>
    const iconPeopleCarry = <FaPeopleCarry size="48px" color="orange" />
    return (
        <>
        <Nav isMain={false} subtitle={props.T("Activity")} {...props} />
        <div className='mainmenu'>
            <ul>
                <MainListItem user={props.user} allowedRoles={["FarmOwner", "FarmManager", "IrrigationOperator"]} caption={props.T("TracktorRefill")} icon={iconGasPump} path="/activities/tracktorrefill" />
                <MainListItem user={props.user} allowedRoles={["FarmOwner", "FarmManager", "IrrigationOperator"]} caption={props.T("TankRefill")} icon={iconFlask} path="/activities/tankrefill" />
                <MainListItem user={props.user} allowedRoles={["FarmOwner", "FarmManager", "IrrigationOperator"]} caption={props.T("Spraying")} icon={iconTractor} path="/activities/spraying" />
                <MainListItem user={props.user} allowedRoles={["FarmOwner", "FarmManager", "IrrigationOperator"]} caption={props.T("Lawn")} icon={iconScissors} path="/activities/lawncut" />
                <MainListItem user={props.user} allowedRoles={["FarmOwner", "FarmManager", "IrrigationOperator"]} caption={props.T("FieldWork")} icon={iconPeopleCarry} path="/activities/fieldwork" />
                <MainListItem user={props.user} allowedRoles={["FarmOwner", "FarmManager", "IrrigationOperator"]} caption={props.T("Maintenance")} icon={iconConfig} path="/activities/maintenance" />
            </ul>
        </div>
        </>
    )
}

export default ActivityMenu