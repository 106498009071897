import React from 'react'
import TopMenu from '../TopMenu'

const InventoryMenu = (props) => {
    const menuItems = [
        {
            key: "overview",
            path: "/inventory/overview",
            title: "State",
            selected: props.section === "overview",
        },
    ]
    const deliveriesAllowedFor = ["FarmOwner", "FarmManager", "IrrigationOperator"]
    if (deliveriesAllowedFor.includes(props.user.role)) {
        menuItems.push(
            {
                key: "deliveries",
                path: "/inventory/deliveries",
                title: "Deliveries",
                selected: props.section === "deliveries",
            },
        )
    }
    const catalogAllowedFor = ["FarmOwner", "FarmManager", "IrrigationOperator"]
    if (catalogAllowedFor.includes(props.user.role)) {
        menuItems.push(
            {
                key: "catalog",
                path: "/inventory/products",
                title: "Catalog",
                selected: props.section === "catalog",
            },
        )
    }
    const suppliersAllowedFor = ["FarmOwner", "FarmManager", "IrrigationOperator"]
    if (suppliersAllowedFor.includes(props.user.role)) {
        menuItems.push(
            {
                key: "suppliers",
                path: "/inventory/suppliers",
                title: "Suppliers",
                selected: props.section === "suppliers",
            },
        )
    }
    return (
        <TopMenu {...props} menuItems={menuItems} />
    )
}

export default InventoryMenu