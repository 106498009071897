import React from 'react'
import { useState, useEffect } from 'react'
import Nav from '../../../components/Nav'
import FormHeader from '../../../components/FormHeader';
import { Redirect } from 'react-router-dom';

const MaintenanceNew = (props) => {
    const [errorMsg, setErrorMsg] = useState({status: 1, error:null})
    const [kinds, setKinds] = useState({status: 0, v:[], error:null})
    
    useEffect(() => {
        const onAttributeListResponse = (data, error) => {
            if (error) {
                setErrorMsg({status:2, error:error})
            } else {
                setKinds({status: 1, v:data.maintenance_kinds ? data.maintenance_kinds : [], error:null})
            }
        }
        props.backend.GetAttributes(onAttributeListResponse, {attributes: ["maintenance_kinds"]})
    }, [props.backend])

    const onSubmit = (ev) => {
        ev.preventDefault()
        var fd = new FormData(ev.target)
        const data = {
            kind: {id: fd.get("kind")},
            note: fd.get("note"),
        }
        setErrorMsg({status: 0, error:null})
        props.backend.MaintenanceNew(onSaveResponse, data)
    }

    const onSaveResponse = (data, error) => {
        if (error) {
            setErrorMsg({status: 1, error:error})
        } else {
            setErrorMsg({status: 2, error:null})
        }
    }

    return (
        <>
        <Nav isMain={false} withLink="/activities" subtitle="Activity" {...props} />
        {(errorMsg.status === 0 || kinds.status === 0) && <div className="loadingOverlay"></div>}
        {(errorMsg.status === 2 || kinds.status === 2) && <Redirect to="/activities/maintenance" />}
        {errorMsg.status === 1 && kinds.status === 1 &&
            <div className="inputForm">
            <FormHeader {...props} title={props.T("NewMaintenance")}/>
            <form method="POST" onSubmit={onSubmit}>
                <label htmlFor="kind">{props.T("Kind")}:
                <select name="kind" id="kind">
                    {kinds.v.map(mk => {return (
                        <option key={mk.id} id={mk.id} value={mk.id}>{props.T("mk_"+mk.name)}</option>
                    )})}
                </select>
                </label>
                <label htmlFor="note">{props.T("Note")}:
                <input id="note" type="text" className="textinput" name="note" placeholder="Enter note" />
                </label>
                {errorMsg.status === 1 && errorMsg.error && <div className="submitLine error">{errorMsg.error}</div>}
                <div className="submitLine">
                <input type="submit" className="button" name="submitForm" value={props.T("Save")} />
                </div>
            </form>
            </div>
        }
        </>
    )
}

export default MaintenanceNew
