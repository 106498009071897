import React from 'react'
import NetafimCycleList from './NetafimCycleList';
import ValveCycleList from './ValveCycleList';

function CycleListRouter(props) {
    if (props.user.integration_name === "netafim_cloud") {
        return (
            <NetafimCycleList {...props} />
        )
    } else {
        return (
            <ValveCycleList {...props} />
        )
    }
}

export default CycleListRouter

