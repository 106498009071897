import React, { useState, useEffect } from 'react'

import Nav from '../../../components/Nav'
import IrrigationMenu from '../../../components/irrigation/IrrigationMenu';
import OverviewFilterMenu from '../../../components/irrigation/OverviewFilterMenu'
import { FaAngleDown } from 'react-icons/fa';

function NetafimCycleList(props) {

    const [cycles, setCycles] = useState({status: 0, v:[], error:null})

    useEffect(() => {
        const onListResponse = (data, error) => {
            if (error) {
                setCycles({status: 1, v:[], error: error})
            } else {
                setCycles({status: 1, v:data.netafim_cycles ? data.netafim_cycles : [], error: null})
            }
        }
        props.backend.NetafimCycleList(onListResponse)
    }, [props.backend])

    const collapseTable = (ev, id) => {
        const target = document.getElementById(id)
        target.classList.toggle("nodisplay")
    }
    
    const formatDate = (v) => {
        const dateOptions = { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric' };
        const d = new Date(v);
        return d.toLocaleDateString(props.T("LOCALE"), dateOptions)
    }

    const formatTime = (v) => {
        const dateOptions = { hour12: false, hour: '2-digit', minute: '2-digit' };
        const d = new Date(v)
        return d.toLocaleTimeString(props.T("LOCALE"), dateOptions)
    }

    /*const stripHour = (v) => {
        const arr = v.split(":")
        return arr[1] + ":" + arr[2]
    }*/

    const printDosing = (dl) => {
        let counter = 0
        return (
            dl.map(value => {
                counter++
                return (
                    <p key={"value_"+counter}>
                    #{counter}: &nbsp;{value}&nbsp;
                    </p>
                )
            })
        )
    }

    return (
        <>
        <Nav isMain={false} subtitle="Irrigation" {...props} />
        <IrrigationMenu {...props} section="overview" />
        <div className="filterMenu">
            <OverviewFilterMenu {...props} filter="valvecycle" />
        </div>
        {(cycles.status === 0) && <div className="loadingOverlay"></div>}
        {cycles.status === 1 && 
            <div className="content">
            <p className="contentSubtitle">{props.T("DailyIrrigationLog")}</p><br />
            <div className="hscrollcontainer sticky">
            {cycles.v.map(byDate => { return (
                <div key={byDate.date} style={{ marginBottom: "20px"}}>
                <p onClick={(ev) => collapseTable(ev, byDate.date)}>
                    <FaAngleDown />&nbsp;{props.T("IrrigationCyclesFrom")} <b>{formatDate(byDate.date)}</b>&nbsp;<FaAngleDown /></p>
                <div id={byDate.date}>
                    {byDate.valve_info && byDate.valve_info.map(vi => {return (
                        <p key={vi.valve_index}>
                        {props.T("TotalForValve")} {vi.valve_index} # {props.T("Water")}: {vi.total_water.toFixed(2)} m3, {props.T("PerPlant")}: {(vi.water_per_plant*1000).toFixed(0)} ml
                        </p>
                    )})}
                <div>
                <div >
                <table>
                    <thead>
                    <tr>
                        <th align="center">{props.T("Time")}</th>
                        <th align="center">{props.T("V")}</th>
                        <th align="center">{props.T("Pr")}</th>
                        <th align="center">{props.T("Avg")}</th>
                        <th align="center">{props.T("Flow")}<br />( m3/h )</th>
                        <th align="center">{props.T("Water")}<br />( m3 )</th>
                        <th align="center">{props.T("Dosing")}<br />( l/m3 )</th>
                    </tr>
                    </thead>
                    <tbody>
                        {byDate.valve_cycles.map(vc => {
                            return (
                                <tr key={vc.id}>
                                    <td align="center">{formatTime(vc.created_at)}<br />{vc.total_time}</td>
                                    <td align="center">{vc.valve.valve_index}</td>
                                    <td align="center">{vc.program_index > 0 ? vc.program_index : "-"}</td>
                                    <td padding="5px" align="left">EC: {vc.ec_avg} ({vc.ec_low} - {vc.ec_high})<br />pH: {vc.ph_avg} ({vc.ph_low} - {vc.ph_high})</td>
                                    <td align="center">{vc.flow}</td>
                                    <td align="center">{vc.total_water}</td>
                                    <td align="left">{printDosing(vc.dosing_values)}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                    <tfoot></tfoot>
                </table>
                </div>
                </div>
                </div>
            </div>
            )})}
            </div>
            </div>
        }
        </>
    )
}

export default NetafimCycleList

