import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { BsPencil } from 'react-icons/bs'

import Nav from '../../../components/Nav'
import FarmConfigMenu from '../../../components/farmconfig/FarmConfigMenu';
import ActionButton from '../../../components/ActionButton';

function UserList(props) {
    const pencilIcon = <BsPencil size="14"/>

    const [users, setUsers] = useState({status: 0, v:[], error:null})

    useEffect(() => {
        const onListResponse = (data, error) => {
            if (error) {
                setUsers({status: 1, v: [], error: error})
            } else {
                setUsers({status: 1, v: data.users ? data.users : [], error: null})
            }
        }
        props.backend.FarmUsersList(onListResponse)
    }, [props.backend])

    const renderUsers = (uList) => { return (
        <div className="hscrollcontainer">
        <table>
            <thead>
                <tr>
                    <td align="center" >{props.T("FirstName")}</td> 
                    <td align="center" >{props.T("LastName")}</td>
                    <td align="center" >{props.T("Email")}</td>
                    <td align="center" >{props.T("Role")}</td>
                    <td align="center" >{props.T("Active")}</td>
                    <td align="center" >&nbsp;</td>
                </tr>
            </thead>
            <tbody>
                {uList.length === 0 && <tr colSpan="6"><td>{props.T("NoUsers")}</td></tr>}
                {uList.map(u => {return (
                    <tr key={u.user.id}>
                        <td>{u.user.first_name}</td>
                        <td>{u.user.last_name}</td>
                        <td>{u.user.email}</td>
                        <td>{u.role.display_name}</td>
                        <td align="center">{u.active ? props.T("YES") : props.T("NO")}</td>
                        <td align="center"><Link to={"/config/users/edit/"+ u.user.id}>{props.T("Edit")}</Link></td>
                    </tr>
                )})}
            </tbody>
        </table>
        </div>
    )}
    return (
        <>
        <Nav isMain={false} subtitle="Config" {...props} />
        <FarmConfigMenu {...props} section="users" />
        <div className="filterMenu">
            <Link to="/config/users/new">
                <ActionButton T={props.T} title="New" icon={pencilIcon} />
            </Link>
        </div>
        <div className="content">
            { users.status === 0 ? <div className="loadingOverlay"></div> : "" }
            { users.status === 1 && renderUsers(users.v) }
        </div>
        </>
    )
}

export default UserList
