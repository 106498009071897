import React, { useState, useEffect } from 'react'

import Nav from '../../../components/Nav'
import FarmConfigMenu from '../../../components/farmconfig/FarmConfigMenu';
import IrrigationSystemItems from './IrrigationSystemItems';
import { Redirect } from 'react-router-dom';

function IrrigationSystemList(props) {

    const [systemList, setSystemList] = useState({status: 0, v: [], error: null})
    
    const onResponse = (data, error) => {
        if (error) {
            setSystemList({status: 1, v: [], error: error})
        } else {
            setSystemList({status: 1, v: data.systems ? data.systems : [], error: undefined})
        }
    }
    useEffect(() => {
        props.backend.IrrigationSystemList(onResponse)
    }, [props.backend])

    const onSystemRemoved = () => {
        setSystemList({status: 0, v:[], error:null})
        props.backend.IrrigationSystemList(onResponse)
    }

    return (
        <>
        <Nav isMain={false} subtitle="Config" {...props} />
        <FarmConfigMenu {...props} section="irrigation" />
        {systemList.status === 0 
            ? <div className="loadingOverlay"></div> 
            : systemList.v.length === 0
                ? <Redirect to="/config/irrigation/new" />
                : <IrrigationSystemItems {...props} systems={systemList.v} onRemove={onSystemRemoved} />
        }
        </>
    )
}

export default IrrigationSystemList
