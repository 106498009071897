import React from 'react'
import { Link } from 'react-router-dom'

const TopMenu = (props) => {

    return (
        <div className="topMenu">
            <ul>
                {props.menuItems.map((item) => {
                    return (
                        <li key={item.key} className={item.selected ? "topMenu__active" : ""}>
                        <Link to={item.path}>{props.T(item.title)}</Link>
                        </li>                       
                    )
                })}
            </ul>
        </div>
    )
}

export default TopMenu
