import React from 'react'
import { Link } from 'react-router-dom'

const MainListItem = ({user, allowedRoles, icon, caption, path}) => {
    if (allowedRoles.filter(r => r === user.role).length === 0) {
        return null
    }
    if (path === undefined) {
        path = "#"
    }
    return (
        <li>
            <Link to={path}>

            <div className="mainmenuicon">
                {icon}
                {caption}
            </div>

            </Link>
        </li>
    )
}

export default MainListItem
