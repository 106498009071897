import React from 'react'
import { useState, useEffect} from 'react'
import Nav from '../../../components/Nav'
import FormHeader from '../../../components/FormHeader';
import { Redirect } from 'react-router-dom';
import { BsPlusCircle, BsDashCircle } from 'react-icons/bs';
import InventoryItemInput from '../../../components/InventoryItemInput';

const SprayingNew = (props) => {
    const [errorMsg, setErrorMsg] = useState({status: 1, error:null})
    const [inventory, setInventory] = useState({status: 0, v:[], error:null})
    const [formulas, setFormulas] = useState({status: 0, v:[], error:null})
    const [sprayingKinds, setSprayingKinds] = useState({status: 0, v:[], error:null})
    const [articles, setArticles] = useState({v:[]})
    
    useEffect(() => {
        const onInventoryListResponse = (data, error) => {
            if (error) {
                setInventory({status: 2, v:null, error:error})
            } else {
                setInventory({status: 1, v:data.inventory ? data.inventory : [], error:null})
            }
        }

        const onFormulaListResponse = (data, error) => {
            if (error) {
                setFormulas({status: 2, v:null, error:error})
            } else {
                setFormulas({status: 1, v:data.formulas ? data.formulas : [], error:null})
            }
        }

        const onAttributeListResponse = (data, error) => {
            if (error) {
                setFormulas({status:2, v:[], error:null})
            } else {
                setSprayingKinds({status: 1, v:data.spraying_kinds ? data.spraying_kinds : [], error:null})
            }
        }

        props.backend.FormulaList(onFormulaListResponse, "approved")
        props.backend.InventoryList(onInventoryListResponse)
        props.backend.GetAttributes(onAttributeListResponse, {attributes: ["spraying_kinds"]})
    }, [props.backend])
    
    const onSubmit = (ev) => {
        ev.preventDefault()
        var fd = new FormData(ev.target)
        const note = fd.get("note")
        if (!note || note === "") {
            document.getElementById("note").focus()
            return
        }

        if (articles.v.length === 0) {
            setErrorMsg({status: 1, error:"Inventory list is empty"})
            return
        }
        const data = {
            note: note,
            formula: {id: fd.get("formula")},
            kind: {id: fd.get("spraying_kind")},
            start_row: fd.get("start_row"),
            end_row: fd.get("end_row"),
            inventory_cl: articles.v,
        }
        setErrorMsg({status: 0, error:null})
        props.backend.SprayingNew(onSaveResponse, data)
    }

    const onSaveResponse = (data, error) => {
        if (error) {
            setErrorMsg({status: 1, error:error})
        } else {
            setInventory({status: 2, v:inventory.v, error:null})
        }
    }

    const switchInventoryMainInput = (ev) => {
        ev.preventDefault()
        document.getElementById("mainInput").classList.toggle("nodisplay")
        document.getElementById("inventoryInput").classList.toggle("nodisplay")
    }

    const addInventoryItems = (data) => {
        var items = articles.v
        var isDuplicate = false
        for (var i=0; i<items.length; i++) {
            var item = items[i]
            if (data.product.id === item.product.id && data.quantity === item.quantity) {
                isDuplicate = true
                items[i].quantity = data.quantity
            }
        }
        if (!isDuplicate) {
            items.push(data)
        }
        setArticles({v:items})
    }

    const removeInventoryItem = (item) => {
        var items = articles.v.filter(a => a !== item)
        setArticles({v:items})
    }

    return (
        <>
        <Nav isMain={false} withLink="/activities" subtitle="Activity" {...props} />
        {(inventory.status === 0 || formulas.status === 0 || sprayingKinds.status === 0 || errorMsg.status === 0) && <div className="loadingOverlay"></div>}
        {(inventory.status === 2 || formulas.status === 2 || sprayingKinds.status === 2) && <Redirect to="/activities/spraying" />}
        {inventory.status === 1 && formulas.status === 1 && sprayingKinds.status === 1 && errorMsg.status !== 0 &&
            <>
            <InventoryItemInput T={props.T} inventory={inventory.v} appendInventoryItem={addInventoryItems} switchInventoryMainInput={switchInventoryMainInput} />
            <div id="mainInput" className="inputForm">
            <FormHeader {...props} title={props.T("NewSpraying")} />
            <form method="POST" onSubmit={onSubmit}>
                <label htmlFor="formula">{props.T("Formula")}:
                <select name="formula" id="formula">
                    {formulas.v.map(f => {return (
                        <option key={f.id} id={f.id} value={f.id}>{f.name} - {props.T("formula_"+f.method.name)}</option>
                    )})}
                </select>
                </label>
                <label htmlFor="spraying_kind">{props.T("Kind")}:
                <select name="spraying_kind" id="spraying_kind">
                    {sprayingKinds.v.map(sk => {return (
                        <option key={sk.id} id={sk.id} value={sk.id}>{props.T("sk_"+sk.name)}</option>
                    )})}
                </select>
                </label>
                <label htmlFor="start_row">{props.T("StartRow")}:
                <input id="start_row" type="text" className="textinput" name="start_row" placeholder="Enter start row" />
                </label>
                <label htmlFor="end_row">{props.T("EndRow")}:
                <input id="end_row" type="text" className="textinput" name="end_row" placeholder="Enter end row" />
                </label>
                <label htmlFor="name">{props.T("Note")}:
                <input id="note" type="text" className="textinput" name="note" placeholder="Enter note" />
                </label>

                <br />
                <div style={{marginTop: "20px"}} id="articlesView">
                    <ul className="header">
                        <li style={{cursor: "pointer"}} onClick={switchInventoryMainInput}><BsPlusCircle size="20"/> &nbsp; {props.T("TakeFromInventory")}</li>
                    </ul>
                </div>
                <div className="hscrollcontainer">
                <table style={{width:"100%"}} id="inputInventoryItem">
                    <thead>
                        <tr>
                            <td align="center">{props.T("Product")}</td>
                            <td align="center" width="60px">{props.T("Quantity")}</td>
                            <td align="center" width="25px">&nbsp;</td>
                        </tr>
                    </thead>
                    <tbody>
                    {articles.v.map(art => {return (
                        <tr key={"" + art.product.id + "_" + art.package_size}>
                            <td align="left">{art.product.name} - {props.T("pt_"+art.product.package_type.name)}</td>
                            <td align="center">{art.quantity_change}{art.product.package_type.unit}</td>
                            <td align="center" onClick={(ev) => {removeInventoryItem(art)}}><BsDashCircle size="16" color="darkred"/></td>
                        </tr>
                    )})}
                    </tbody>
                </table>
                </div>
                {errorMsg.status === 1 && errorMsg.error && <div className="submitLine error">{errorMsg.error}</div>}
                <div className="submitLine">
                <input type="submit" className="button" name="submitForm" value="Save" />
                </div>
            </form>
            </div>
            </>
        }
        </>
    )
}

export default SprayingNew
