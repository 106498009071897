import React, { useState, useEffect } from 'react'

import Nav from '../../../components/Nav'
import FormHeader from '../../../components/FormHeader';
import { Redirect } from 'react-router-dom';

const DripMeasureNew = (props) => {
    const [drains, setDrains] = useState({status:0, v:[], error:null})

    useEffect(() => {
        const onAttributesResponse = (data, error) => {
            if (error) {
                setDrains({status:2, v:[], error:error})
            } else {
                setDrains({status:1, v:data.drains ? data.drains : [], error:null})
            }
        }
        props.backend.GetAttributes(onAttributesResponse, {attributes: ["drains"]})
    }, [props.backend])

    const onSubmit = (ev) => {
        ev.preventDefault()
        let fd = new FormData(ev.target)
        const drain_id = fd.get("drain_id")
        const quantity = Number(fd.get("qty").replace(/,/g, '.'))
        const ec = Number(fd.get("ec").replace(/,/g, '.'))
        const ph = Number(fd.get("ph").replace(/,/g, '.'))

        if (!drain_id || drain_id === "") {
            document.getElementById("drain_id").focus()
            return
        }

        if (quantity === "" || isNaN(quantity)) {
            document.getElementById("qty").focus()
            return
        }

        if (ec === "" || isNaN(ec)) {
            document.getElementById("ec").focus()
            return
        }
        if (ph === "" || isNaN(ph)) {
            document.getElementById("ph").focus()
            return
        }

        props.backend.DrainMeasurementsNew(onSaveResponse, {
            drain: {id: drain_id},
            source: fd.get("source"),
            quantity: quantity,
            ec: ec,
            ph: ph,
        })
    }

    const onSaveResponse = (data, error) => {
        if (error) {
            setDrains({status: 1, v:drains.v, error:error})
        } else {
            setDrains({status: 2, v:drains.v, error:null})
        }
    }

    return (
        <>
        <Nav isMain={false} subtitle="Irrigation" {...props} />
        {drains.status === 0 && <div className="loadingOverlay"></div>}
        {drains.status === 2 && <Redirect to="/irrigation/overview/drip" />}
        {drains.status === 1 &&
        <div className="inputForm">
            <FormHeader {...props} title={props.T("NewMeasurement")} />
            <form method='POST' onSubmit={onSubmit}>
                <div className="mergedLine">
                    <label htmlFor="drain_id">{props.T("PlantLocation")}:
                    <select name="drain_id" id="drain_id" >
                    {drains.status === 1 && drains.v.map((d) => 
                            <option key={d.id} value={d.id}>{props.T("Valve")+":"+d.valve.name+", "+props.T("Row")+":"+d.row+"-"+d.loc}</option>
                    )}
                    </select>
                    </label>
                    <label htmlFor="source">{props.T("Source")}:
                    <select name="source" id="source" defaultValue="plantdrain">
                        <option value="plantdrain">{props.T("Drain")}</option>
                        <option value="dripper">{props.T("Dripper")}</option>
                    </select>
                    </label>
                </div>
                <label htmlFor="qty">{props.T("Quantity")} (ml):
                <input type="number" min="0" max="2000" step="1" className="textinput" id="qty" name="qty" defaultValue="" placeholder="Enter quantity in ml"/>
                </label>
                <label htmlFor="ec">EC:
                <input type="number" min="0" max="5" step="0.01" className="textinput" id="ec" name="ec" defaultValue="" placeholder="Enter EC value"/>
                </label>
                <label htmlFor="ph">pH:
                <input type="number" min="0" max="20" step="0.01" className="textinput" id="ph" name="ph" defaultValue="" placeholder="Enter PH value"/>
                </label>
                <div className="submitLine">
                <input type="submit" className="button" name="submitForm" value={props.T("Save")} />
                </div>
            </form>
        </div>
        }
        </>
    )
}

export default DripMeasureNew
