import { useState, useEffect, useMemo } from 'react'
import { Switch, Route } from "react-router-dom";
import AuthService from './services/AuthService'
import Backend from './Backend'
import InitLoading from './pages/InitLoading'
import Login from './Login'
import Landing from './Landing'
import UserRegistration from './pages/users/UserRegistration';
import Lang from './Lang';
import UserPasswordReset from './pages/users/UserPasswordReset';

function App() {
    const backend = useMemo(() => new Backend(), []);
    const authService = useMemo(() => new AuthService(backend), [backend])

    const [userProfile, setUserProfile] = useState(null)
    const clearUserProfile = () => setUserProfile({})

    useEffect(() => {
        const onProfileResponse = (data, error) => {
            if (error) {
                setUserProfile({})
            } else {
                setUserProfile(data)
            }
        }
        backend.GetUserProfile(onProfileResponse)
    }, [backend])

    const reloadProfile = () => {
        backend.GetUserProfile((data, error) => {
            if (error) {
                setUserProfile({})
            } else {
                setUserProfile(data)
            }
        })
    }

    const onLoginSuccess = () => {
        backend.GetUserProfile(setUserProfile)
    }
    const urlParams = new URLSearchParams(window.location.search)
    let lang="en"
    if (urlParams.has("lang")) {
        const ln = urlParams.get("lang")
        if (ln === "sr_lat" || ln === "sr_cyr") {
            lang = ln
        }
    }

    return (
        <Switch>
            <Route exact path='/users/i/:invitation_code' render={(props) => (<UserRegistration {...props} T={Lang.T(lang)} backend={backend}/>)} />
            <Route exact path='/users/r/:reset_code' render={(props) => (<UserPasswordReset {...props} T={Lang.T(lang)} backend={backend}/>)} />
            <Route path='/'>
                {
                    !userProfile
                    ? <InitLoading />
                    : !userProfile.email
                        ? <Login T={Lang.T("en")} backend={backend} onLoginSuccess={onLoginSuccess} />
                        : <Landing T={Lang.T(userProfile.lang)} user={userProfile} authService={authService} clearUserProfile={clearUserProfile} backend={backend} reloadProfile={reloadProfile} />
                }
            </Route>
        </Switch>
    )
}

export default App;