import React, { useState, useEffect } from 'react'
import LogoBox from '../../components/LogoBox'

const UserRegistration = (props) => {
    const [user, setUser] = useState({status: 0, v:{}, error:null})

    const goHome = (ev) => {
        window.location = "/"
    }

    const onSubmit = (ev) => {
        ev.preventDefault()
        const fd = new FormData(ev.target)
        const p1 = fd.get("password")
        const p2 = fd.get("password2")
        if (p1 === "") {
            for(let child = ev.target.firstChild; child !== null; child = child.nextSibling) {
                if (child.nodeName === "LABEL" && child.htmlFor === "password") {
                    child.lastChild.focus()
                    return
                }
            }
            return
        }
        if (p2 === "") {
            for(let child = ev.target.firstChild; child !== null; child = child.nextSibling) {
                if (child.nodeName === "LABEL" && child.htmlFor === "password2") {
                    child.lastChild.focus()
                    return
                }
            }
            return
        }
        if (p1 !== p2) {
            document.getElementById("errorNotEqual").classList.remove("nodisplay")
            return
        }
        setUser({status: 0, v:user.v, error:null})
        props.backend.UserInvitationSubmit(onSubmitResponse, {invitation_code: user.v.invitation_code, password: p1})
    }

    const onSubmitResponse = (data, error) => {
        if (!error) goHome()
    }

    const onInputChange = (ev) => {
        document.getElementById("errorNotEqual").classList.add("nodisplay")
    }

    useEffect(() => {
        const onResponse = (data, error) => {
            if (error) {
                window.location = "/"
            } else {
                setUser({status: 1, v:data, error: null})
            }
        }
        props.backend.UserInvitationGet(onResponse, props.match.params.invitation_code)
    }, [props.backend, props.match.params.invitation_code])

    return (
        <div>
            <header>
                <div className="logo">
                    <LogoBox />
                </div>
            </header>
            {user.status === 0 && <div className="loadingOverlay"></div>}
            {user.status === 1 &&
            <div id="register" className="inputForm">
                <p align="center" style={{marginBottom: "30px"}}>{props.T("SetupPasswordMsg")}</p>
                <form method='POST' onSubmit={onSubmit}>
                    <label htmlFor="email">
                    <input type="text" className="textinput" name="email" value={user.v.email} readOnly/>
                    </label>
                    <label htmlFor="password">{props.T("Password")}:
                    <input type="password" className="textinput" name="password" placeholder="Enter new password" onChange={onInputChange}/>
                    </label>
                    <label htmlFor="password2">{props.T("Password2")}:
                    <input type="password" className="textinput" name="password2" placeholder="Enter new password again" onChange={onInputChange} />
                    </label>
                    <div id="errorNotEqual" className="error nodisplay">{props.T("PasswordsNotEqual")}</div>
                    <input type="submit" className="button" name="submitLogin" value={props.T("Save")} />
                </form>
            </div>
            }
        </div>
    )
}

export default UserRegistration
