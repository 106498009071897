import React, { useState, useEffect } from 'react'
import Nav from '../../../components/Nav'
import { Link } from 'react-router-dom'
import ActionButton from '../../../components/ActionButton'
import { BsPencil } from 'react-icons/bs'
import FarmConfigMenu from '../../../components/farmconfig/FarmConfigMenu'

const LayoutRowList = (props) => {
    const pencilIcon = <BsPencil size="14"/>

    const [rows, setRows] = useState({status: 0, v:null, error:null})
    useEffect(() => {
        const onListResponse = (data, error) => {
            if (error) {
                setRows({status:1, v:[], error:error})
            } else {
                setRows({status:1, v:data.layout_rows ? data.layout_rows : [], error:null})
            }
        }
        props.backend.LayoutRowList(onListResponse)
    }, [props.backend])

    const renderPage = (dList) => {
        return (
            <div className="hscrollcontainer">
            <table>
                <thead>
                    <tr>
                        <td align="center" >{props.T("ID")}</td> 
                        <td align="center" >{props.T("NumFields")}</td>
                        <td align="center" >{props.T("NumPlants")}</td>
                        <td align="center" >{props.T("Active")}</td>
                        <td align="center" >&nbsp;</td>
                    </tr>
                </thead>
                <tbody>
                    {dList.length === 0 && <tr colSpan="6"><td>{props.T("NoLayoutRowsFound")}</td></tr>}
                    {dList.map(d => {return (
                        <tr key={d.id}>
                            <td align="left">{d.id}</td>
                            <td align="center">{d.num_fields}</td>
                            <td align="center">{d.num_plants}</td>
                            <td align="center">{d.active ? props.T("YES") : props.T("NO")}</td>
                            <td align="center"><Link to={"/config/layoutrows/edit/"+ d.id_uuid}>{props.T("Edit")}</Link></td>
                        </tr>
                    )})}
                </tbody>
            </table>
            </div>
        )
    }

    return (
        <>
        <Nav isMain={false} subtitle="Config" {...props} />
        {rows.status === 0 ? <div className="loadingOverlay"></div> : ""}
        <FarmConfigMenu {...props} section="layoutrows" />
        <div className="filterMenu">
            <Link to="/config/layoutrows/new">
                <ActionButton T={props.T} title="New" icon={pencilIcon} />
            </Link>
        </div>
        <div className="content">
            {rows.status === 1 && renderPage(rows.v)}
        </div>
        
        </>
    )
}

export default LayoutRowList
