import React, { useState } from 'react'

import ActionButton from '../../../components/ActionButton'
import FormHeader from '../../../components/FormHeader'

const IrrigationSystemItems = (props) => {
    const s = props.systems[0]

    const [configSaved, setConfigSaved] = useState(true)

    const printValue = (title, v) => {
        return (
            <>
            {title}<b>{v}</b><br />
            </>
        )
    }

    const printNetafim = () => {
        const c = s.ir_integration.netafim_config
        let ls = c.last_synced
        if(ls === "1970-01-01T00:00:00Z") {
            ls = props.T("never")
        }
        
        return (
            <>
                {printValue(props.T("CloudUsername")+": ", c.tw_username)}
                {printValue(props.T("NetafimFarm")+": ", c.tw_farm_display_name)}
                {printValue(props.T("NetafimController")+": ", c.tw_controller_display_name)}
                {printValue(props.T("LastSync")+": ", ls)}
                {printValue(props.T("SyncStatus")+": ", c.sync_status)}
            </>
        )
    }

    const onRemoveClicked = (ev) => {
        ev.preventDefault()
        document.getElementById("removeQuestion").classList.toggle("nodisplay")
        document.getElementById("inputForm").classList.toggle("nodisplay")
        const c = document.getElementById("content")
        if (c.style.display === "none") {
            c.style.display = "block"
        } else {
            c.style.display = "none"
        }
    }

    const onSave = (ev) => {
        ev.preventDefault()
        let fd = new FormData(ev.target)
        let newValves = []
        let newDosingChannels = []
        for(let i = 0; i< s.valves.length; i++) {
            const idx = s.valves[i].valve_index
            let newName = fd.get("valve_name_"+idx)
            if (newName === "") {
                document.getElementsByName("valve_name_"+idx)[0].focus()
                return
            }
            let newCap = fd.get("valve_dcap_"+idx)
            if (newCap === "") {
                document.getElementsByName("valve_dcap_"+idx)[0].focus()
                return
            }
            let newNplants = Number(fd.get("valve_nplants_"+idx))
            if (isNaN(newNplants)) {
                newNplants = 1
            }
            newValves.push({id: s.valves[i].id, name: newName, dripper_capacity: parseFloat(newCap), num_plants: newNplants})
        }
        for (let i = 0; i<s.dosing_channels.length; i++) {
            const idx = s.dosing_channels[i].channel_index
            let newName = fd.get("dosingchan_"+idx)
            if (newName === "") {
                document.getElementsByName("dosingchan_"+idx)[0].focus()
                return
            }
            newDosingChannels.push({id: s.dosing_channels[i].id, name: newName})
        }
        props.backend.IrrigationSystemUpdate(onSaveResponse, {id: s.id, valves: newValves, dosing_channels: newDosingChannels})
    }

    const onSaveResponse = (data, error) => {
        if (error) {
            console.log(error)
        } else {
            setConfigSaved(true)
        }
    }

    const onInputChange = (ev) => {
        setConfigSaved(false)
    }

    const onRemoveConfirmed = () => {
        props.backend.IrrigationSystemRemove(onRemoveCompleted, s.id)
    }

    const onRemoveCompleted = (data, error) => {
        if (error) {

        } else {
            props.onRemove()
        }
    }
    return (
        <>
        <p className="contentSubtitle">{props.T("ConfiguredIrrigationSystem")}</p>
        <div style={{marginLeft: "10px"}} id="removeQuestion" className="nodisplay">
            <p>{props.T("AreYouSureToRemove")} </p><br />
            <ActionButton T={props.T} title="NO" onClick={onRemoveClicked}/>&nbsp; &nbsp;
            <ActionButton T={props.T} title="YES" onClick={onRemoveConfirmed} />
        </div>
        <div id="content" className="content">
            {printValue(props.T("System")+": ", s.ir_system.display_name)}
            {printValue(props.T("Integration")+": ", s.ir_integration.display_name)}
            {s.ir_integration.name === "netafim_cloud" && printNetafim()}
        </div>

        <div id="inputForm" className="inputForm">
            <form onSubmit={onSave} method="POST">
                <FormHeader  {...props} title={props.T("VALVES")} withoutBackButton={true} />
                {s.valves.map(v => { 
                    return (
                        <div key={"valvesetup_"+v.valve_index} className="mergedLine">
                            <label htmlFor={"valve_name_"+v.valve_index}>{props.T("NameOfValve")} {v.valve_index}:
                            <input className="textinput" type="text" name={"valve_name_"+v.valve_index} 
                                defaultValue={v.name} placeholder="Enter valve name" onChange={onInputChange} />
                            </label>
                            <label htmlFor={"valve_dcap_"+v.valve_index}>{props.T("DripperCapacity")}: (l/hour)
                            <input className="textinput" type="text" name={"valve_dcap_"+v.valve_index} 
                                defaultValue={v.dripper_capacity} placeholder="Enter dripper capacity" onChange={onInputChange} />
                            </label>
                            <label htmlFor={"valve_nplants_"+v.valve_index}>{props.T("NumPlants")}:
                            <input className="textinput" type="text" name={"valve_nplants_"+v.valve_index}
                                defaultValue={v.num_plants} placeholder="Enter number of plants" onChange={onInputChange} />
                            </label>
                        </div>
                    )
                })}
                <br />
                <FormHeader  {...props} title={props.T("DOSINGCHANNELS")} withoutBackButton={true} />
                {s.dosing_channels.map(dc => {
                    return (
                        <label key={"dosingsetup_"+dc.channel_index} htmlFor={"dosingchan_"+dc.channel_index}>{props.T("NameOfChannel")} {dc.channel_index}:
                            <input className="textinput" type="text" name={"dosingchan_"+dc.channel_index} 
                                defaultValue={dc.name} placeholder="Enter channel name" onChange={onInputChange} />
                        </label>
                    )
                })}
                <div id="systemSubmitLine" className="submitLine">
                <button id="systemSubmitButton" disabled={configSaved ? true : false} className="button" name="submitButton">
                    {props.T("Save")}
                </button>
                <button id="systemRemoveButton" className="button" name="removeButton" onClick={onRemoveClicked}>{props.T("Remove")}</button>
                </div>
            </form>  
        </div>
        </>
    )
}

export default IrrigationSystemItems
