import React, { useEffect, useState } from 'react'

import FormHeader from "./FormHeader"

function FieldWorkView(props) {
    const [fw, setFw] = useState({status: 0, v:null})

    useEffect(() => {
        if(!props.fw) {
            return
        }
        setFw({status: 1, v:props.fw})
    }, [props.fw])

    const close = () => {
        setFw({status: 0, v:null})
        props.onClose()
    }

    const printRowRange = (startID, endID) => {
        if (startID === endID) {
            return (
                startID
            )
        } else {
            return (
                startID+" - "+endID
            )
        }
    }

    return (
        <>
        {fw.status === 1 && fw.v &&
            <div className="inputForm">
            <FormHeader {...props} title={props.T("FieldWork")} linkTo={close}/>
            <p>1. {props.T("NumFields")}:</p><pre style={{color:"red"}}>{fw.v.num_fields}</pre><br />
            <p>2. {props.T("NumPlants")}:</p><pre style={{color:"red"}}>{fw.v.num_plants}</pre><br />
            <p>3. {props.T("NumWorkers")}:</p><pre style={{color:"red"}}>{fw.v.num_workers}: {fw.v.worker_names}</pre><br />
            <p>4. {props.T("Note")}:</p><pre style={{color:"red"}}>{fw.v.note}</pre><br />
            <p>5. {props.T("Rows")}:</p>
            <pre style={{color:"red"}}>
                {fw.v.rows && fw.v.rows.map((r, idx) => {return (
                    <p key={idx}>-&nbsp;
                        {r.is_partial && r.is_field_including && 
                            props.T("Row")+": "+r.start_row.id +", "+ props.T("RowFieldIncluding")+": "+ r.num_fields + " ("+r.num_plants+")"}
                        {r.is_partial && !r.is_field_including && 
                            props.T("Row")+": "+r.start_row.id +", "+props.T("RowFieldExcluding")+": "+ r.num_fields + " ("+r.num_plants+")"}
                        {!r.is_partial && props.T("RowInputPartialFalse")+": "+printRowRange(r.start_row.id,r.end_row.id)+ " ("+r.num_plants+")"}
                    </p>
                )})}
            </pre><br />
            </div>
        }
        </>
    )
}

export default FieldWorkView