import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { BsPencil } from 'react-icons/bs'

import Nav from '../../../components/Nav'
import ActionButton from '../../../components/ActionButton';
import { FaAngleDown } from 'react-icons/fa';
import InventoryMenu from '../../../components/inventory/InventoryMenu';
import ImageView from '../../../components/ImageView';

function DeliveryList(props) {
    const pencilIcon = <BsPencil size="14"/>
    const [deliveries, setDeliveries] = useState({status: 0, v:[], error:null})
    const [selectedDocument, setSelectedDocument] = useState(null)

    const dateHead = (date) => {
        var d = new Date(date)
        var options = { weekday: 'short', day: 'numeric', month: 'short'};
        return d.toLocaleDateString(props.T("LOCALE"), options)
    }

    const collapseTable = (ev, id) => {
        const target = document.getElementById(id)
        target.classList.toggle("nodisplay")
    }

    useEffect(() => {
        const onListResponse = (data, error) => {
            if (error) {
                setDeliveries({status: 1, v:[], error: error})
            } else {
                setDeliveries({status: 1, v:data.deliveries ? data.deliveries : [], error: null})
            }
        }
        props.backend.DeliveryList(onListResponse)
    }, [props.backend])

    const showDocument = (docID) => {
        setSelectedDocument(docID)
    }

    const clearSelectedDocument = () => {
        setSelectedDocument(null)
    }
    
    return (
        <>
        <Nav isMain={false} subtitle="Inventory" {...props} />
        <InventoryMenu {...props} section="deliveries" />
        <ImageView {...props} onClose={clearSelectedDocument} docID={selectedDocument} />

        {(deliveries.status === 0) && <div className="loadingOverlay"></div>}
        {selectedDocument != null && <div id="imageOverlay" className="imageOverlay"></div>}
        {selectedDocument === null && deliveries.status === 1 &&
        <>
        <div className="filterMenu">
            <Link to="/inventory/deliveries/new">
                <ActionButton T={props.T} title="New" icon={pencilIcon} />
            </Link>
        </div>
        <div className="content">
            <p className="contentSubtitle">{props.T("Deliveries")}</p><br />
            <div className="hscrollcontainer sticky">
            {deliveries.v.map(d => { 
                var fullName = "" + d.name + "_" + d.delivery_date
                return (
                
                <div key={fullName} style={{ marginBottom: "20px"}}>
                <p onClick={(ev) => collapseTable(ev, fullName)}><FaAngleDown />&nbsp;{d.name} <b>{dateHead(d.delivery_date)}</b>&nbsp;<FaAngleDown /></p>
                <div id={fullName}>
                <p align="left">{props.T("EnteredBy")}: {d.user.first_name}, {props.T("Supplier")}: {d.supplier.name}</p>
                <table>
                    <thead>
                    <tr>
                        <th align="center" >{props.T("Product")}</th>
                        <th align="center" >{props.T("PackageSize")}</th>
                        <th align="center" >{props.T("Count")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {d.items.length === 0 &&
                        <tr><td colSpan="5">{props.T("NoArticlesFound")}</td></tr>
                    }
                    {d.items.length > 0 &&
                        d.items.map(di => {return (
                            <tr key={di.id}>
                            <td align="left">{di.product.name+" - "+props.T("pt_"+di.product.package_type.name)}</td>
                            <td align="center">{di.quantity + "" + di.product.package_type.unit}</td>
                            <td align="center">{di.package_count}</td>
                            </tr>
                        )})
                    }
                    <tr><td colSpan="5">&nbsp;</td></tr>
                    <tr>
                        <td colSpan="5" align="center" onClick={(ev) => showDocument(d.document.id)}>
                            {props.T("ViewDeliveryDoc")}
                        </td>
                    </tr>
                    </tbody>
                </table>
                </div>
                <br />
                </div>    
            )})}
            </div>
            </div>
            </>
            }
        </>
    )
}

export default DeliveryList
