import React, { useState, useEffect } from 'react'

import Nav from '../../components/Nav'
import FarmConfigMenu from '../../components/farmconfig/FarmConfigMenu'
import FarmEditForm from '../../components/farmconfig/FarmEditForm'

function ConfigHome(props) {

    const [farm, setFarm] = useState({
        status: 0,
        v: {},
        error: null
    })

    useEffect(() => {
        const onResponse = (data, error) => {
            if (error) {
                setFarm({status: 1, v: {}, error: error})
            } else {
                setFarm({status: 1, v: data, error: null})
            }
        }
        props.backend.FarmGet(onResponse)
    }, [props.backend])

    const onSubmitResponse = (data, error) => {
        if (error) {
            setFarm({status: 1, v: farm.v, error: error})
        } else {
            setFarm({status: 1, v: data, error: null})
            props.reloadProfile()
        }
    }

    const onSubmit = (farm) => {
        resetFarmStatus(farm.v)
        props.backend.FarmSave(onSubmitResponse, farm.v)
    }

    const resetFarmStatus = (val) => {
        setFarm({status: 0, v: val, error: undefined})
    }


    return (
        <>
        <Nav isMain={false} subtitle="Config" {...props} />
        <FarmConfigMenu {...props} section="farm" />
        {farm.status === 0 ? <div className="loadingOverlay"></div> : ""}
        {farm.status === 1 && <FarmEditForm T={props.T} onSubmit={onSubmit} farm={farm} /> }
        </>
    )
}

export default ConfigHome
