import React from 'react'
import { useState} from 'react'
import FormHeader from './FormHeader';

const LayoutRowItemInput = (props) => {
    const [selectedStartRow, setSelectedStartRow] = useState(props.layoutRows && props.layoutRows.length > 0 ? props.layoutRows[0] : null)
    const [selectedEndRow, setSelectedEndRow] = useState(props.layoutRows && props.layoutRows.length > 0 ? props.layoutRows[0] : null)
    const [selectedIsPartial, setSelectedIsPartial] = useState(true)

    const add = (ev) => {
        ev.preventDefault()
        const numFields = Number(document.getElementById("num_fields").value)
        if (isNaN(numFields) || (numFields <= 0 && selectedIsPartial)) {
            document.getElementById("num_fields").focus()
            return
        }

        props.appendLayoutRowItem({
            start_row: selectedStartRow,
            end_row: selectedEndRow,
            is_partial: selectedIsPartial,
            num_fields: numFields,
            is_field_including: document.getElementById("field_selection_type").value === "rf_including",
        })
        props.switchLayoutRowMainInput(ev)
    }

    const onStartRowSelected = (ev) => {
        ev.preventDefault()
        if (props.layoutRows.length === 0) {
            return
        }
        
        const startRowID = [].filter.call(ev.target.options, opt => opt.selected)[0].value
        const startRow = props.layoutRows.find(r => r.id === startRowID)
        setSelectedStartRow(startRow)
        if (selectedIsPartial) {
            setSelectedEndRow(startRow)
        }
    }

    const onEndRowSelected = (ev) => {
        ev.preventDefault()
        if (props.layoutRows.length === 0) {
            return
        }
        const endRowID = [].filter.call(ev.target.options, opt => opt.selected)[0].value
        const endRow = props.layoutRows.find(r => r.id === endRowID)
        setSelectedEndRow(endRow)
    }

    const onPartialSelected = (ev) => {
        ev.preventDefault()
        const isPartialValue = [].filter.call(ev.target.options, opt => opt.selected)[0].value
        const isPartial = isPartialValue === "1" ? true : false
        setSelectedIsPartial(isPartial)
        if (isPartial) {
            document.getElementById("label_end_row").classList.add("nodisplay")
            document.getElementById("label_field_selection_type").classList.remove("nodisplay")
            document.getElementById("label_num_fields").classList.remove("nodisplay")
        } else {
            document.getElementById("label_end_row").classList.remove("nodisplay")
            document.getElementById("label_field_selection_type").classList.add("nodisplay")
            document.getElementById("label_num_fields").classList.add("nodisplay")
        }
    }

    return (
        <div id="LayoutRowItemInput" className="inputForm nodisplay">
            <FormHeader {...props} title={props.T("SelectLayoutRow")} linkTo={props.switchLayoutRowMainInput} />
            <label htmlFor="is_partial">
            <select name="is_partial" id="is_partial" defaultValue="1" onChange={onPartialSelected}>
                <option id="partial_true" value="1">{props.T("RowInputPartialTrue")}</option>
                <option id="partial_true" value="0">{props.T("RowInputPartialFalse")}</option>
            </select>
            </label>
            <label id="label_start_row" htmlFor="start_row">{props.T("StartRow")}
            <select name="start_row" id="start_row" onChange={onStartRowSelected}>
                {props.layoutRows.map(row => {return (
                    <option key={row.id} id={row.id} value={row.id}>{row.id}</option>
                )})}
            </select>
            </label>
            <label id="label_end_row" htmlFor="end_row" className="nodisplay">{props.T("EndRow")}
            <select name="end_row" id="end_row" onChange={onEndRowSelected}>
                {props.layoutRows.map(row => {return (
                    <option key={row.id} id={row.id} value={row.id}>{row.id}</option>
                )})}
            </select>
            </label>
            <label id="label_field_selection_type" htmlFor="field_selection_type">{props.T("RowFieldSelectionType")}:
            <select name="field_selection_type" id="field_selection_type" defaultValue="rf_including">
                <option id="rf_including" name="rf_including" value="rf_including">{props.T("RowFieldIncluding")}</option>
                <option id="rf_excluding" name="rf_excluding" value="rf_excluding">{props.T("RowFieldExcluding")}</option>
            </select>
            </label>

            <label id="label_num_fields" htmlFor="num_fields">{props.T("NumFields")}:
            <input id="num_fields" type="number" className="textinput" name="num_fields" placeholder="Enter number of fields" />
            </label>
            <div className="submitLine">
            <button onClick={add}>{props.T("Add")}</button>
            </div>
        </div>
    )
}

export default LayoutRowItemInput;