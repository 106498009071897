import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { BsPencil } from 'react-icons/bs'

import Nav from '../../../components/Nav'
import IrrigationMenu from '../../../components/irrigation/IrrigationMenu';
import ProgramsFilterMenu from '../../../components/irrigation/ProgramsFilterMenu'
import ActionButton from '../../../components/ActionButton';
import CollapsableProgramTitle from '../../../components/irrigation/CollapsableProgramTitle'


const DosingProgramList = (props) => {
    const pencilIcon = <BsPencil size="14"/>
    const [programs, setPrograms] = useState({status:0, v:[], error:null})

    useEffect(() => {
        const onListResponse = (data, error) => {
            if (error) {
                setPrograms({status:1, v:[], error:error})
            } else {
                setPrograms({status:1, v:data.dosing_programs ? data.dosing_programs : [], error:null})
            }
        }
        props.backend.DosingProgramList(onListResponse)
    }, [props.backend])

    return (
        <>
        <Nav isMain={false} subtitle="Irrigation" {...props} />
        <IrrigationMenu {...props} section="programs" />
        <div className="filterMenu">
            <ProgramsFilterMenu {...props} filter="dosing" />
            {props.user.integration_name !== "netafim_cloud" &&
                <Link to="/irrigation/programs/dosing/new">
                    <ActionButton T={props.T} title="New" icon={pencilIcon} />
                </Link>
            }
        </div>
        {programs.status === 0 && <div className="loadingOverlay"></div>}
        {programs.status === 1 && 
            <div className="content">
                <p className="contentSubtitle">{props.T("DosingPrograms")}</p><br />
                <div className="programList">
                {programs.v.map((p) => {
                    const headID = "head_" + p.id
                    return (
                        <div key={p.id} className="programListItem">
                        <CollapsableProgramTitle title={p.name} expandID={headID} />
                        <div id={headID} className="programBody nodisplay">
                            <div className="container">
                            <div>
                                {props.T("TargetEC")}: {p.target_ec}<br />
                                {props.T("TargetPH")}: {p.target_ph}<br />
                            </div>
                            <div>
                            {p.channel_configs.map(cfg => {return (
                            <p key={""+p.id+"_"+cfg.dosing_channel.id}>
                            <span>{cfg.dosing_channel.name}: {cfg.quantity} l/m3</span><br />
                            </p>
                            )})}
                            </div>
                            </div>
                            {props.user.integration_name !== "netafim_cloud" &&
                                <div className="programBody editLine">
                                    <Link to={"/irrigation/programs/dosing/new/"+p.id}>
                                    <ActionButton T={props.T} title="Edit" icon={pencilIcon} />
                                    </Link>
                                </div>
                            }
                        </div>
                        </div>
                    )
                })}
                </div>
            </div>
        }
        </>
    )
}

export default DosingProgramList

