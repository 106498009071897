import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { BsPencil } from 'react-icons/bs'

import Nav from '../../../components/Nav'
import IrrigationMenu from '../../../components/irrigation/IrrigationMenu';
import ProgramsFilterMenu from '../../../components/irrigation/ProgramsFilterMenu'
import ActionButton from '../../../components/ActionButton';
import CollapsableProgramTitle from '../../../components/irrigation/CollapsableProgramTitle'

const WaterRuntimeProgramList = (props) => {
    const pencilIcon = <BsPencil size="14"/>

    const [programs, setPrograms] = useState({status: 0, v:[], error:null})
    useEffect(() => {
        const onResponse = (data, error) => {
            if (error) {
                setPrograms({status: 1, v:[], error: error})
            } else {
                setPrograms({status: 1, v:data.runtime_programs ? data.runtime_programs : [], error: null})
            }
        }
        props.backend.RuntimeProgramList(onResponse)
    }, [props.backend])

    const asTime = (v) => {
        const hrs = Math.floor(v / 3600)
        v = v % 3600
        const min = Math.floor(v / 60)
        const sec = v % 60
        var hrPrefix = ""
        if (hrs < 10) {
            hrPrefix = "0"
        }
        var minPrefix = ""
        if (min < 10) {
            minPrefix = "0"
        }
        var secPrefix = ""
        if (sec < 10) {
            secPrefix = "0"
        }
        return "" + hrPrefix + hrs +":"+minPrefix + min+":"+secPrefix + sec
    }
    return (
        <>
        <Nav isMain={false} subtitle="Irrigation" {...props} />
        <IrrigationMenu {...props} section="programs" />
        <div className="filterMenu">
            <ProgramsFilterMenu {...props} filter="wruntime" />
            {props.user.integration_name !== "netafim_cloud" &&
                <Link to="/irrigation/programs/wruntime/new">
                    <ActionButton T={props.T} title="New" icon={pencilIcon} />
                </Link>
            }
        </div>
        {programs.status === 0 && <div className="loadingOverlay"></div>}
        {programs.status === 1 &&
            <div className="content">
                <p className="contentSubtitle">{props.T("RuntimePrograms")}</p><br />
                <div className="programList">
                    {programs.v.map((p) => {
                    const headID = "head" + p.id
                    return (
                        <div key={p.id} className="programListItem">
                            <CollapsableProgramTitle title={p.name} expandID={headID} />
                            <div id={headID} className="programBody nodisplay">
                                <div className="container">
                                    <p>{props.T("Method")}: {props.T(p.method.display_name)} <br /></p>
                                    <p>
                                        {props.T("Before")}: {p.method.name==="time" ? asTime(p.v_before) : p.v_before} <br />
                                        {props.T("Total")}: {p.method.name==="time" ? asTime(p.v_total) : p.v_total} <br />
                                        {props.T("After")}: {p.method.name==="time" ? asTime(p.v_after) : p.v_after} <br />
                                    </p>
                                </div>
                                {props.user.integration_name !== "netafim_cloud" &&
                                    <div className="programBody editLine">
                                        <Link to={"/irrigation/programs/wruntime/new/"+p.id}>
                                        <ActionButton T={props.T} title="Edit" icon={pencilIcon} />
                                        </Link>
                                    </div>
                                }
                            </div>
                        </div>
                    )})}
                </div>
            </div>
        }
        </>
    )
}

export default WaterRuntimeProgramList

