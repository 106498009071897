import React from 'react'
import TopMenu from '../TopMenu'

const FarmConfigMenu = (props) => {
    const menuItems = [
        {
            key: "farm",
            path: "/config",
            title: "Farm",
            selected: props.section === "farm",
        },
        {
            key: "irrigation",
            path: "/config/irrigation",
            title: "Irrigation",
            selected: props.section === "irrigation",
        },
        {
            key: "drains",
            path: "/config/drains",
            title: "Drains",
            selected: props.section === "drains",
        },
        {
            key: "users",
            path: "/config/users",
            title: "Users",
            selected: props.section === "users",
        },
        {
            key: "layoutrows",
            path: "/config/layoutrows",
            title: "Layout",
            selected: props.section === "layoutrows",
        }
    ]
    return (
        <TopMenu {...props} menuItems={menuItems} />
    )
}

export default FarmConfigMenu
