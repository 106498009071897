import React from 'react'
import { BsCardChecklist } from 'react-icons/bs'
import { CgColorPicker } from 'react-icons/cg'
import FilterMenu from '../FilterMenu'

const OverviewFilterMenu = (props) => {
    const logIcon = <BsCardChecklist size="14"/>
    const dripIcon = <CgColorPicker size="14"/>

    const menuItems = [
        {
            key: "drip", 
            title: "EcPh",
            icon: dripIcon, 
            selected: (props.filter === "drip"),
            path: "/irrigation/overview/drip"
        },
        {
            key: "valvecycle", 
            title: "Cycles",
            icon: logIcon, 
            selected: (props.filter === "valvecycle"),
            path: "/irrigation/overview/valvecycle"
        }
    ];

    return (
        <FilterMenu T={props.T} items={menuItems} />
    )
}

export default OverviewFilterMenu
