import React from 'react'
import FilterMenu from '../FilterMenu';
import { BsCardChecklist } from 'react-icons/bs';
import { CgColorPicker } from 'react-icons/cg';

const FarmListFilterMenu = (props) => {
    const logIcon = <BsCardChecklist size="14"/>
    const dripIcon = <CgColorPicker size="14"/>

    const menuItems = [
        {
            key: "active", 
            title: "Active",
            icon: dripIcon, 
            selected: (!props.match.params.filter || props.match.params.filter === "active"),
            path: "/admin/farms"
        },
        {
            key: "disabled", 
            title: "Disabled",
            icon: logIcon, 
            selected: (props.match.params.filter === "disabled"),
            path: "/admin/farms/disabled"
        }
    ];

    return (
        <FilterMenu T={props.T} items={menuItems} />
    )
}

export default FarmListFilterMenu
