import React from 'react'
import { useState, useEffect} from 'react'
import Nav from '../../components/Nav'
import FormHeader from '../../components/FormHeader';
import { Redirect } from 'react-router-dom';

const FormulaNew = (props) => {
    const withPrev = props.match.params.fid ? true : false;
    const [prev, setPrev] = useState({status: withPrev ? 0 : 1, v:null, error:null})
    const [errorMsg, setErrorMsg] = useState({status: 1, error:null})
    const [methods, setMethods] = useState({status: 0, v:[], error:null})
    const [states, setStates] = useState({status: 0, v:[], error:null})

    useEffect(() => {
        const onAttributesResponse = (data, error) => {
            if (error) {
                setMethods({status: 1, v:[], error: error})
            } else {
                setMethods({status: 1, v:data.formula_application_methods ? data.formula_application_methods : [], error: null})
                if (withPrev) {
                    setStates({status: 1, v:data.formula_states ? data.formula_states : [], error: null})
                } else {
                    setStates({status: 1, v:data.formula_states ? data.formula_states.filter(s => s.name !== "approved") : [], error: null})
                }
                
            }
        }
        const onGetPrevResponse = (data, error) => {
            if (error) {
                setPrev({status: 2, v:null, error:error})
            } else {
                if (data.state.name === "approved" && props.user.role !== "FarmOwner") {
                    setPrev({status: 2, v:null, error:null})
                } else {
                    setPrev({status: 1, v:data, error:null})
                }
            }
        }
        if (withPrev) {
            props.backend.FormulaGet(onGetPrevResponse, props.match.params.fid)
        }
        
        props.backend.GetAttributes(onAttributesResponse, {attributes: ["formula_application_methods", "formula_states"]})

    }, [props.backend, props.match.params.fid, withPrev, props.user.role])
    
    const onSubmit = (ev) => {
        ev.preventDefault()
        var fd = new FormData(ev.target)
        const name = fd.get("name")
        if (!name || name === "") {
            document.getElementById("name").focus()
            return
        }
        const description = fd.get("description")
        if (!description || description === "") {
            document.getElementById("description").focus()
            return
        }
        const instructions = fd.get("instructions")
        if (!instructions || instructions === "") {
            document.getElementById("instructions").focus()
            return
        }

        const methodID = fd.get("method")
        if (methodID === "empty") {
            document.getElementById("method").focus()
        }

        const newID = (withPrev) ? prev.v.id : ""
        const data = {
            id: newID,
            method: {id: methodID},
            name: name,
            visibility_level: fd.get("visibility_level"),
            description: description,
            instructions: instructions,
            recommended_start_date: fd.get("recommended_start_date"),
            state: {id: fd.get("state")},
        }
        setErrorMsg({status: 0, error:null})
        if (withPrev) {
            props.backend.FormulaUpdate(onSaveResponse, prev.v.id, data)
        } else {
            props.backend.FormulaNew(onSaveResponse, data)
        }
    }

    const onSaveResponse = (data, error) => {
        if (error) {
            setErrorMsg({status: 1, error:error})
        } else {
            setMethods({status: 2, v:methods.v, error:null})
        }
    }

    return (
        <>
        <Nav isMain={false} subtitle="Mixtures" {...props} />
        {(methods.status === 0 || states.status === 0 || prev.status === 0 || errorMsg.status === 0) && <div className="loadingOverlay"></div>}
        {(methods.status === 2 || states.status === 2 || prev.status === 2) && <Redirect to="/formulas" />}
        {methods.status === 1 && states.status === 1 && prev.status === 1 && errorMsg.status !== 0 &&
            <div className="inputForm">
            <FormHeader {...props} title={withPrev ? props.T("EditMixture") : props.T("NewMixture") }/>
            <form method="POST" onSubmit={onSubmit}>
                <label htmlFor="name">{props.T("Name")}:
                <input id="name" type="text" className="textinput" name="name" defaultValue={withPrev ? prev.v.name : ""} placeholder="Enter mixture name" />
                </label>
                <label htmlFor="method">{props.T("ApplicationMethod")}:
                <select name="method" id="method" defaultValue={withPrev && prev.v.method.id ? prev.v.method.id : ""}>
                    {methods.v.map(am => {return (
                        <option key={am.id} id={am.name} value={am.id}>{props.T("formula_"+am.name)}</option>
                    )})}
                </select>
                </label>
                <label htmlFor="method">{props.T("VisibilityLevel")}:
                <select name="visibility_level" id="visibility_level" defaultValue={withPrev && prev.v.visibility_level ? prev.v.visibility_level : ""}>
                    <option id="1" value="1">1</option>
                    <option id="2" value="2">2</option>
                </select>
                </label>
                <label htmlFor="description">{props.T("Description")}:
                <textarea id="description" type="text" rows="5" cols="50" className="textareainput" name="description" defaultValue={withPrev ? prev.v.description : ""} placeholder="Enter short description" />
                </label>
                <label htmlFor="instructions">{props.T("Instructions")}:
                <textarea id="instructions" type="text" rows="15" cols="50" className="textareainput" name="instructions" defaultValue={withPrev ? prev.v.instructions : ""} placeholder="Enter instructions" />
                </label>
                <label htmlFor="name">{props.T("RecommendedStartDate")}:
                <input id="recommended_start_date" type="text" className="textinput" name="recommended_start_date" defaultValue={withPrev ? prev.v.recommended_start_date : ""} placeholder="Enter recommended start date" />
                </label>
                <label htmlFor="state">{props.T("Status")}:
                <select name="state" id="state" defaultValue={withPrev && prev.v.state.id ? prev.v.state.id : states.v.find(s => s.name === "editing").id}>
                    {states.v.map(state => {return (
                        <option key={state.id} id={state.name} value={state.id}>{props.T(state.name)}</option>
                    )})}
                </select>
                </label>
                {errorMsg.status === 1 && errorMsg.error && <div className="submitLine error">{errorMsg.error}</div>}
                <div className="submitLine">
                <input type="submit" className="button" name="submitForm" value={props.T("Save")} />
                </div>
            </form>
            </div>
        }
        </>
    )
}

export default FormulaNew
