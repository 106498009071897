import React, { useState, useEffect } from 'react'
import Nav from '../../../components/Nav'
import FormHeader from '../../../components/FormHeader';
import { Redirect } from 'react-router-dom';

const DrainsEdit = (props) => {
    const [resp, setResp] = useState({
        status: 2,
        error: null,
    })

    const [valves, setValves] = useState({status: 0, v:[], error:null})
    const [drain, setDrain] = useState({status: 0, v:null, error:null})

    useEffect(() => {
        const onDrainGetResponse = (data, error) => {
            if (error) {
                window.location = "/config/drains"
            } else {
                setDrain({status: 1, v:data, error:null})
            }
        }
    
        const onValvesListResponse = (data, error) => {
            if (error) {
                setValves({status: 1, v:[], error: error})
            } else {
                setValves({status: 1, v:data.valves ? data.valves : [], error:null})
            }
        }
        props.backend.GetAttributes(onValvesListResponse, {attributes:["valves"]})
        props.backend.FarmDrainsGet(onDrainGetResponse, props.match.params.did)
    }, [props.backend, props.match.params.did])

    const onSubmit = (ev) => {
        ev.preventDefault()
        let fd = new FormData(ev.target)
        const row = fd.get("row")
        if (!row || row === "") {
            ev.target.row.focus()
            return
        }

        const loc = fd.get("loc")
        if (!loc || loc === "") {
            ev.target.loc.focus()
            return
        }

        let valve_id = fd.get("valve_id")
        if (valve_id === null) {
            ev.target.valve_id.focus()
            return
        }

        console.log(fd.get("active"))
        let active = fd.get("active") === "yes" ? true : false
        console.log(active)
        setResp({status: 0, error: null})
        props.backend.FarmDrainsUpdate(onResponse, {
            valve:{
                id: valve_id,
            },
            id: drain.v.id,
            row: row,
            loc: loc,
            active: active,
        })
    }

    const onResponse = (_, error) => {
        if (error) {
            setResp({status: 1, error: error})
        } else {
            setResp({status: 1, error: null})
        }
    }

    const renderForm = (d) => {
        return (
            <div className="inputForm">
            <FormHeader {...props} title={props.T("EditDrainLocation")} />
            <form method='POST' onSubmit={onSubmit}>
                <label htmlFor="row">{props.T("Row")}:
                <input type="text" className="textinput" name="row" defaultValue={d.row} placeholder="Enter row number"/>
                </label>
                <label htmlFor="loc">{props.T("Location")}:
                <input type="text" className="textinput" name="loc" defaultValue={d.loc} placeholder="Enter location"/>
                </label>
                <label htmlFor="valve_id">{props.T("Valve")}:
                <select name="valve_id" defaultValue={d.valve.id}>
                    {valves.v !== null && valves.v.map(valve => {return (
                        <option key={valve.id} name={valve.id} value={valve.id}>{valve.name}</option>
                    )})}
                </select>
                </label>
                <label htmlFor="active">{props.T("Active")}:
                <select name="active" defaultValue={d.active ? "yes" : "no"}>
                    <option name="yes" value="yes">{props.T("YES")}</option>
                    <option name="no" value="no">{props.T("NO")}</option>
                </select>
                </label>
                <div className="submitLine error">
                {resp.status === 1 && resp.error ? "ERROR: " + resp.error : ""}
                </div>
                <div className="submitLine">
                <input type="submit" className="button" name="submitForm" value={props.T("Save")} />
                </div>
            </form>
            </div>
        )
    }
    return (
        <>
        <Nav isMain={false} subtitle="Config" {...props} />
        {resp.status === 0 || valves.status === 0 || drain.status === 0 ? <div className="loadingOverlay"></div> : ""}
        {resp.status === 1 && !resp.error && <Redirect to="/config/drains" />}
        {drain.status === 1 && drain.error && <Redirect to="/config/drains" />}
        {valves.status === 1 && drain.status === 1 && renderForm(drain.v)}
        
        </>
    )
}

export default DrainsEdit
