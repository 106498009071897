import React, { useState } from 'react'
import { BsChevronDoubleDown, BsChevronDoubleUp } from 'react-icons/bs'

const CollapsableProgramTitle = ({title, expandID}) => {
    const [expanded, setExpanded] = useState(false)
    const onExpand = (ev, id) => {
        const target = document.getElementById(id)
        target.classList.toggle('nodisplay')
        target.classList.toggle('blockdisplaytoggle')
        setExpanded(!expanded)
    }

    return (
        <div className="programHead" onClick={(ev) => onExpand(ev, expandID)}>
            {expanded ? <BsChevronDoubleUp /> : <BsChevronDoubleDown />}
            <p>{title}</p>
            {expanded ? <BsChevronDoubleUp /> : <BsChevronDoubleDown />}
        </div>
    )
}

export default CollapsableProgramTitle
