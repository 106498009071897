import React from 'react'
import Nav from '../components/Nav'
import { FaLayerGroup } from 'react-icons/fa'
import MainListItem from '../components/MainListItem'
import { BsDropletHalf } from 'react-icons/bs'

const Reports = (props) => {
    // const iconDay = <FaCalendarDay size="48px" color="#008899"/>
    // const iconWeek = <FaCalendarWeek size="48px" color="orange"/>
    const iconDroplet = <BsDropletHalf size="48px" color="#008899"/>
    const iconInventory = <FaLayerGroup size="48px" color="purple"/>

    return (
        <>
        <Nav isMain={false} {...props} />
        <p className="contentSubtitle">{props.T("Reports")}</p>
        <div className='mainmenu'>
            <ul>
                <MainListItem user={props.user} allowedRoles={["FarmOwner", "FarmManager", "Agronomist", "IrrigationOperator"]} caption={props.T("ValvesAndDrains")} icon={iconDroplet} path="/reports/measurements" />
                <MainListItem user={props.user} allowedRoles={["FarmOwner", "FarmManager"]} caption={props.T("Inventory")} icon={iconInventory} path="/reports/inventory" />
            </ul>
        </div>
        </>
    )
}

export default Reports
